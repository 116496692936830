import { homepage } from "../components/Homepage/Homepage.lang.en";
import { footer } from "../components/Footer/Footer.lang.en";
import { faq } from "../components/Pages/FAQ/FAQ.lang.en";
import { contact_us } from "../components/Pages/ContactUs/ContactUs.lang.en";

export const en = {
  homepage,
  footer,
  faq,
  contact_us,
  see_more: "See More",
};

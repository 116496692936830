export const contact_us = {

  support:"Unterstützung",
  information:"Information",
  jobs:"Arbeitsplätze",
  
  supportTitle:"Legen Sie eine Karte vor",
  informationTitle:"Allgemeine Anfragen",
  jobsTitle:"Reichen Sie eine Bewerbung ein",

  email: "Email",
  position:"Position",
  subject:"Thema",
  message:"Nachricht",
  attach:"Anfügen",
  submit:"Einreichen",
  cancel:"Stornieren",
};

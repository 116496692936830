export const homepage = {
  section_1_line_1: "A NEW GENERATION OF",
  section_1_line_2: "EMOJIS",
  section_1_line_3: "They Move. They Talk. They're Funny.",
  section_1_line_4: "They're in 3D. They're Alive!",
  section_1_line_5: "THEY'RE THE NEXT LEVEL OF EMOJIS",
  section_2_line_1: "MILLIONS OF PERSONALIZED OPTIONS",
  section_2_line_2: "FOR YOUR OJIIS",
  section_2_line_3: "There is an Ojii for everything.",
  section_2_line_4: "Customize it and express yourself like never before!",
  section_2_line_5: "Unleash your creativity!",
  section_3_line_1: "<p>TAKE IT TO THE</p>NEXT LEVEL WITH",
  section_3_line_2: "AR",
  section_3_line_3: "Bring them into your world with Augmented Reality.",
  section_4_line_1: "FULLY CUSTOMIZABLE",
  section_4_line_2: "STICKERS",
  section_4_line_3: "Pick a frame from your Ojii's animation",
  section_4_line_4: "to make your own sticker!",
  section_5_line_1: "INNOVATIVE",
  section_5_line_2: "STORY CREATOR",
  section_5_line_3:
    "Select your Ojiis and build your own personalized stories.",
  section_6_line_1: "NO",
  section_6_line_2: "ADS",
  section_6_line_3: "Fuel your creativity without interruption!",
  section_7_line_1: "GIVE YOUR OJII",
  section_7_line_2: "A VOICE",
  section_7_line_3: "Add your personal touch!",
  section_7_line_4: "Record your voice, add your music or write on it!",
  section_8_line_1: "TRANSFORM YOUR OJIIS INTO",
  section_8_line_2: "ANIMALS",
  section_8_line_3: "Bring out the animal in you!",
  section_8_line_4: "Transform the Ojiis into your favorite animal.",
  section_9_line_1: "CREATE VIDEOS WITH",
  section_9_line_2: "STYLISH SCENES",
  section_9_line_3: "Make unique videos with cool backgrounds",
  section_9_line_4: "or bring your own into the scene.",
  section_10_line_1: "UNLOCK IT ALL TO",
  section_10_line_2: "<p>CREATE FUNNY</p>AND UNIQUE POSTS",
  section_10_line_3: "Personalize your communication like",
  section_10_line_4: "never before. Don't say it, Ojii It!",
  media_gallery: "MEDIA GALLERY",
};

import { useContext, useEffect } from "react";
import { LANG_ABREVIATION } from "../../../constants/language";
import { translations } from "../../../translations/texts";
import Header from "../../Header/Header";
import "./VideoPolicy.scss";
import { VideoPolicyDE } from "./VideoPolicyDE";
import { VideoPolicyEN } from "./VideoPolicyEN";
import { VideoPolicyFR } from "./VideoPolicyFR";
import { VideoPolicySP } from "./VideoPolicySP";
import { CombinedContext } from "../../context/combinedContext";

export const VideoPolicy = () => {
  const { language } = useContext(CombinedContext)

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getContent = () => {
    switch (language.toUpperCase()) {
      case LANG_ABREVIATION.EN: {
        return <VideoPolicyEN />;
      }

      case LANG_ABREVIATION.FR: {
        return <VideoPolicyFR />;
      }

      case LANG_ABREVIATION.DE: {
        return <VideoPolicyDE />;
      }

      case LANG_ABREVIATION.SP: {
        return <VideoPolicySP />;
      }

      default: {
        return <VideoPolicyEN />;
      }
    }
  };

  return (
    <div className="video-policy">
      <Header nonHome />
      <section>
        <h1>
          <i>{translations[language].footer.video_policy}</i>
        </h1>
        {getContent()}
      </section>
    </div>
  );
};

export default VideoPolicy;

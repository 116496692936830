export const TOS_FR = () => {
  return (
    <div>
      <p className="c2">
        <span className="c3">
          Date d&#39;entr&eacute;e en vigueur&nbsp;: 01/12/2019
        </span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Les termes de cet accord (« Conditions d'utilisation ») r&eacute;gissent la relation entre
          vous et Ojiis (qui, avec ses filiales et soci&eacute;t&eacute;s
          affili&eacute;es, sont ci-apr&egrave;s d&eacute;nomm&eacute;es
          &laquo;&nbsp;Ojiis&nbsp;&raquo; ou &laquo;&nbsp;Nous&nbsp;&raquo;) -
          concernant votre utilisation de l&#39;application distribu&eacute;e
          par Ojiis que vous avez install&eacute;e, ainsi que toutes les mises
          &agrave; jour et mises &agrave; niveau connexes qui remplacent ou
          compl&egrave;tent l&#39;application &agrave; quelque &eacute;gard que
          ce soit (l&#39;&laquo;&nbsp;Application&nbsp;&raquo; ou
          &laquo;&nbsp;l&#39;App&nbsp;&raquo;), la licence pr&eacute;vaudra.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2" id="h.gjdgxs">
        <span>
          La collecte, l&#39;utilisation, &agrave; moins qu&#39;une telle mise
          &agrave; jour ou mise &agrave; niveau ne soit accompagn&eacute;e
          d&#39;une licence distincte, auquel cas la s&eacute;paration et la
          divulgation de vos informations (y compris les informations
          personnelles) sont r&eacute;gies par la politique de
          confidentialit&eacute; d&#39;Ojiis qui se trouve en ligne sur
          https://ojiis{" "}
        </span>
        <span className="c8">. com/politique-de-confidentialite</span>
        <span className="c3">
          &nbsp;(&quot;Politique de confidentialit&eacute;&quot;).
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span>
          L&#39;utilisation de l&#39;application est &eacute;galement
          r&eacute;gie par la politique vid&eacute;o d&#39;Ojiis, disponible en
          ligne &agrave; l&#39;adresse{" "}
        </span>
        <span className="c8">https://ojiis.com/video-policy</span>
        <span className="c3">
          &nbsp;(&quot;Politique vid&eacute;o&quot;) , qui est incorpor&eacute;e
          dans l&#39;Accord par cette r&eacute;f&eacute;rence.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Si vous t&eacute;l&eacute;chargez l&#39;application &agrave; partir de
          n&#39;importe quel march&eacute; en ligne d&#39;applications
          logicielles qui fonctionnent sur des appareils aliment&eacute;s par le
          syst&egrave;me d&#39;exploitation (par exemple, Apple App Store et
          Google Play, ou si vous jouez &agrave; l&#39;application en ligne
          (lorsqu&#39;elle est disponible), vous reconnaissez que vous avez
          examin&eacute; et accept&eacute; les conditions de service applicables
          &agrave; ce march&eacute; en ligne, &agrave; cette plate-forme ou
          &agrave; ce syst&egrave;me d&#39;exploitation (&laquo;&nbsp;Conditions
          sp&eacute;ciales&nbsp;&raquo;).
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          EN INSTALLANT, UTILISANT OU ACC&Eacute;DANT AU SERVICE, VOUS ACCEPTEZ
          CES CONDITIONS D&#39;UTILISATION. SI VOUS N&#39;ACCEPTEZ PAS CES
          CONDITIONS D&#39;UTILISATION, VEUILLEZ NE PAS INSTALLER, UTILISER OU
          ACC&Eacute;DER AU SERVICE. L&#39;UTILISATION DU SERVICE EST
          ANNUL&Eacute;E L&Agrave; O&Ugrave; L&#39;INTERDIT.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Les principales caract&eacute;ristiques de chaque Application figurent
          dans les descriptions des app stores.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">1. LICENCE</span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c0">
          1.1 Octroi d&#39;une licence limit&eacute;e d&#39;utilisation du
          service
        </span>
      </p>
      <p className="c2">
        <span className="c3">
          Gr&acirc;ce &agrave; l&#39;utilisation de l&#39;Application, vous
          acqu&eacute;rez et Ojiis vous accorde une licence personnelle,
          r&eacute;vocable et limit&eacute;e, non exclusive, non
          transf&eacute;rable et non sous-licenciable pour acc&eacute;der et
          utiliser le Service &agrave; vos propres fins de divertissement non
          commerciales. Vous acceptez de ne pas utiliser le Service &agrave;
          d&#39;autres fins.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">1.2 LIMITES DE LICENCE</span>
      </p>
      <p className="c2">
        <span className="c3">
          Toute utilisation de l&#39;Application en violation de ces Limitations
          de Licence est strictement interdite et peut entra&icirc;ner la
          r&eacute;vocation imm&eacute;diate de Votre Licence Limit&eacute;e et
          peut engager Votre responsabilit&eacute; en cas de violation de la
          loi.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Sauf si vous avez re&ccedil;u l&#39;autorisation &eacute;crite
          pr&eacute;alable d&#39;Ojiis, vous vous engagez &agrave; ne pas&nbsp;:
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <ul className="c6 lst-kix_list_3-0 start">
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser des astuces, des logiciels d&#39;automatisation, des bots,
            des hacks ou tout autre logiciel non autoris&eacute; con&ccedil;u
            pour modifier ou interf&eacute;rer avec l&#39;Application et/ou tout
            fichier faisant partie de l&#39;Application&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            exploiter commercialement l&#39;Application Ojiis, ses Personnages,
            son Design, ses mod&egrave;les 2D ou 3D, ses Illustrations ou toute
            autre partie de l&#39;Application&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            cr&eacute;er des &OElig;uvres D&eacute;riv&eacute;es de
            l&#39;Application&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            acc&eacute;der ou utiliser l&#39;Application avec toute technologie
            ou moyen autre que ceux fournis dans l&#39;Application, ou par
            d&#39;autres moyens explicitement autoris&eacute;s qu&#39;Ojiis peut
            d&eacute;signer&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            rendre une copie de l&#39;Application disponible sur un
            r&eacute;seau o&ugrave; elle pourrait &ecirc;tre utilis&eacute;e par
            plusieurs utilisateurs&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            enregistrer des noms de domaine, des comptes de m&eacute;dias
            sociaux ou des adresses connexes qui incluent les marques
            d&eacute;pos&eacute;es d&#39;Ojiis&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser l&#39;Application ou les actifs associ&eacute;s et/ou les
            marques d&eacute;pos&eacute;es d&#39;Ojiis sur ou en relation avec
            du contenu qui ( i ) fait la promotion de la triche, du piratage, de
            la violence, de la discrimination, de th&egrave;mes
            inappropri&eacute;s, d&#39;activit&eacute;s ill&eacute;gales ou de
            contenu sexuellement explicite&nbsp;; (ii) fait des
            d&eacute;clarations fausses, malhonn&ecirc;tes,
            d&eacute;sobligeantes ou diffamatoires &agrave; propos d&#39;Ojiis
            et/ou de ses produits, employ&eacute;s et agents&nbsp;; et/ou (iii)
            contient un autre contenu r&eacute;pr&eacute;hensible&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            revendre, copier, transf&eacute;rer, distribuer, afficher, traduire
            ou modifier l&#39;Application ou cr&eacute;er des &oelig;uvres
            d&eacute;riv&eacute;es de l&#39;Application ou d&#39;une partie de
            celle-ci&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            r&eacute;utiliser, copier ou distribuer du texte, des images, de la
            musique, des codes-barres, des vid&eacute;os, des donn&eacute;es,
            des hyperliens, des affichages ou tout autre contenu fourni par
            l&#39;Application&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            redistribuer, sous-licencier, louer, publier, ex&eacute;cuter,
            vendre, c&eacute;der, louer, commercialiser, transf&eacute;rer ou
            autrement mettre l&#39;Application &agrave; la disposition de
            tiers&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            chercher &agrave; d&eacute;sactiver, d&eacute;sosser,
            d&eacute;compiler ou tenter d&#39;une autre mani&egrave;re
            d&#39;extraire le code source de l&#39;Application ou d&#39;une
            partie de celle-ci, sauf si cela est express&eacute;ment
            autoris&eacute; ou requis par la loi applicable ou par les
            conditions de licence r&eacute;gissant l&#39;utilisation de tout
            composant open source inclus dans L&#39;application;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            supprimer, masquer ou modifier de quelque mani&egrave;re que ce soit
            tout avertissement, avis (y compris, mais sans s&#39;y limiter, tout
            avis de droit d&#39;auteur ou autre droit de
            propri&eacute;t&eacute;) ou lien qui appara&icirc;t dans
            l&#39;Application&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser l&#39;Application lors de la conduite de
            v&eacute;hicules&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            supprimer ou modifier les marques ou logos d&#39;Ojiis ou les
            mentions l&eacute;gales inclus dans l&#39;Application ou les actifs
            associ&eacute;s&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser le service pour tenter d&#39;obtenir un acc&egrave;s non
            autoris&eacute; &agrave; tout service, donn&eacute;e, compte ou
            r&eacute;seau par quelque moyen que ce soit&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            publier toute information contenant de la nudit&eacute;, de la
            violence ou un sujet offensant ou contenant un lien vers un tel
            contenu&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            tenter de harceler, abuser, menacer, diffamer ou autrement
            enfreindre ou violer les droits de toute autre partie&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser l&#39;Application d&#39;une mani&egrave;re ill&eacute;gale,
            frauduleuse ou trompeuse&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser la technologie ou tout autre moyen pour acc&eacute;der aux
            informations exclusives d&#39;Ojiis non autoris&eacute;es par
            Ojiis&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            utiliser ou lancer tout syst&egrave;me automatis&eacute; pour
            acc&eacute;der au site Web ou aux syst&egrave;mes informatiques
            d&#39;Ojiis&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            tenter d&#39;introduire des virus ou tout autre code informatique
            malveillant qui interrompt, d&eacute;truit ou limite la
            fonctionnalit&eacute; de tout logiciel, mat&eacute;riel informatique
            ou &eacute;quipement de t&eacute;l&eacute;communication&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            tenter d&#39;obtenir un acc&egrave;s non autoris&eacute; au
            r&eacute;seau informatique ou aux comptes d&#39;utilisateurs
            d&#39;Ojiis&nbsp;;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            encourager une conduite qui constituerait une infraction
            p&eacute;nale ou entra&icirc;nerait une responsabilit&eacute; civile
          </span>
        </li>
      </ul>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Ojiis se r&eacute;serve le droit de d&eacute;terminer, &agrave; sa
          seule discr&eacute;tion, quel type de comportement est
          consid&eacute;r&eacute; comme une violation des pr&eacute;sentes
          conditions d&#39;utilisation. En outre, Ojiis se r&eacute;serve le
          droit de prendre les mesures appropri&eacute;es &agrave; la suite de
          votre violation des termes des pr&eacute;sentes conditions
          d&#39;utilisation, y compris, mais sans s&#39;y limiter, de vous
          interdire d&#39;utiliser l&#39;application Ojiis en tout ou en partie.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c0">
          1.3 UTILISATIONS AUTORIS&Eacute;ES DE L&#39;APPLICATION
        </span>
      </p>
      <p className="c2">
        <span className="c3">
          L&#39;UTILISATION DE L&#39;APPLICATION ET DE TOUTES VOS INFORMATIONS
          TRANSMISES EN RELATION AVEC L&#39;APPLICATION EST LIMIT&Eacute;E
          &Agrave; LA FONCTIONNALIT&Eacute; DE L&#39;APPLICATION ET &Agrave;
          L&#39;OCTROI DE LICENCE LIMIT&Eacute; COMME D&Eacute;CRIT CI-DESSUS.
          VOUS NE POUVEZ PAS UTILISER L&#39;APPLICATION OU TOUT COMPOSANT DE
          CELLE-CI, SAUF EXPRESSEMENT AUTORIS&Eacute; PAR OJIIS PAR &Eacute;CRIT
          &Agrave; L&#39;AVANCE.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">
          2. ACC&Egrave;S ET T&Eacute;L&Eacute;CHARGEMENT DE L&#39;APPLICATION
          DEPUIS L&#39;APP STORE APPLE
        </span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Ce qui suit s&#39;applique &agrave; toute Application accessible via
          ou t&eacute;l&eacute;charg&eacute;e &agrave; partir de l&#39;App Store
          d&#39;Apple (&laquo; Application provenant de l&#39;App Store
          &raquo;)&nbsp;:
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous reconnaissez et acceptez que ( i ) les pr&eacute;sentes
            Conditions d&#39;utilisation sont conclues entre vous et Ojiis
            uniquement, et non Apple&nbsp;; et (ii) Ojiis, et non Apple, est
            seul responsable de l&#39;Application provenant de l&#39;App Store
            et de son contenu. Votre utilisation de l&#39;application provenant
            de l&#39;App Store doit &ecirc;tre conforme aux conditions
            d&#39;utilisation de l&#39;App Store d&#39;Apple.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous utiliserez l&#39;Application provenant de l&#39;App Store
            uniquement ( i ) sur un produit de marque Apple qui ex&eacute;cute
            iOS (le syst&egrave;me d&#39;exploitation propri&eacute;taire
            d&#39;Apple)&nbsp;; et (ii) tel qu&#39;autoris&eacute; par les
            &laquo; R&egrave;gles d&#39;utilisation &raquo;
            &eacute;nonc&eacute;es dans les Conditions d&#39;utilisation de
            l&#39;App Store d&#39;Apple.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous reconnaissez qu&#39;Apple n&#39;a aucune obligation de fournir
            des services de maintenance et d&#39;assistance concernant
            l&#39;Application provenant de l&#39;App Store.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            En cas de non-conformit&eacute; d&#39;une application provenant de
            l&#39;App Store &agrave; toute garantie applicable, vous pouvez en
            informer Apple, et Apple vous remboursera le prix d&#39;achat de
            l&#39;application provenant de l&#39;App Store et, dans la mesure
            maximale autoris&eacute;e par la loi applicable, Apple n&#39;aura
            aucune autre obligation de garantie quelle qu&#39;elle soit en ce
            qui concerne l&#39;application provenant de l&#39;App Store. Entre
            Ojiis et Apple, toute autre r&eacute;clamation, perte,
            responsabilit&eacute;, dommage, co&ucirc;t ou d&eacute;pense
            attribuable &agrave; un non-respect de toute garantie
            rel&egrave;vera de la seule responsabilit&eacute; d&#39;Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous et Ojiis reconnaissez que, entre Ojiis et Apple, Apple
            n&#39;est pas responsable du traitement de toute r&eacute;clamation
            que vous avez ou de toute r&eacute;clamation d&#39;un tiers
            concernant l&#39;application provenant de l&#39;App Store en votre
            possession et l&#39;utilisation de l&#39;application provenant de
            l&#39;App Store, y compris mais sans s&#39;y limiter ( i ) les
            r&eacute;clamations en responsabilit&eacute; du fait des
            produits&nbsp;; (ii) toute r&eacute;clamation selon laquelle une
            application provenant de l&#39;App Store n&#39;est pas conforme
            &agrave; toute exigence l&eacute;gale ou r&eacute;glementaire
            applicable&nbsp;; et (iii) les r&eacute;clamations d&eacute;coulant
            de la protection des consommateurs ou d&#39;une l&eacute;gislation
            similaire.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous et Ojiis reconnaissez qu&#39;en cas de r&eacute;clamation
            d&#39;un tiers selon laquelle une Application provenant de l&#39;App
            Store ou Votre possession et utilisation de cette Application
            provenant de l&#39;App Store porte atteinte aux droits de
            propri&eacute;t&eacute; intellectuelle de ce tiers, entre Ojiis et
            Apple, Ojiis, et non Apple , sera seul responsable de
            l&#39;enqu&ecirc;te, de la d&eacute;fense, du r&egrave;glement et de
            l&#39;acquittement de toute r&eacute;clamation pour violation de la
            propri&eacute;t&eacute; intellectuelle dans la mesure requise par
            les pr&eacute;sentes conditions d&#39;utilisation.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            En utilisant l&#39;application provenant de l&#39;App Store, vous
            d&eacute;clarez et garantissez que ( i ) vous n&#39;&ecirc;tes pas
            situ&eacute; dans un pays soumis &agrave; un embargo du gouvernement
            am&eacute;ricain, ou qui a &eacute;t&eacute; d&eacute;sign&eacute;
            par le gouvernement am&eacute;ricain comme un pays &laquo; soutenant
            le terrorisme &raquo; ; et (ii) Vous ne figurez sur aucune liste
            gouvernementale am&eacute;ricaine de parties interdites ou
            restreintes.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Sans limiter les autres conditions des pr&eacute;sentes conditions
            d&#39;utilisation, vous devez vous conformer &agrave; toutes les
            conditions d&#39;accord de tiers applicables lors de
            l&#39;utilisation de l&#39;application provenant de l&#39;App Store.
          </span>
        </li>
      </ul>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">3. CONTENU UTILISATEUR</span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Nonobstant toute disposition contraire stipul&eacute;e dans les
          pr&eacute;sentes Conditions d&#39;utilisation, vous &ecirc;tes
          autoris&eacute; &agrave; cr&eacute;er et &agrave; utiliser le &laquo;
          Contenu utilisateur &raquo; (tel que d&eacute;fini ci-dessous) sous
          r&eacute;serve des Limitations de licence et des R&egrave;gles
          sp&eacute;cifiques au Contenu utilisateur d&eacute;finies dans les
          pr&eacute;sentes.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Toute modification du Contenu Utilisateur cr&eacute;&eacute; par
          l&#39;Application ou l&#39;incorporation de ce Contenu Utilisateur
          dans d&#39;autres &oelig;uvres n&eacute;cessite l&#39;approbation
          &eacute;crite explicite d&#39;Ojiis. Il vous est express&eacute;ment
          interdit de sous-licencier, de louer, de c&eacute;der, de
          transf&eacute;rer ou de distribuer de toute autre mani&egrave;re
          l&#39;Application ou les droits d&#39;utilisation de
          l&#39;Application. Les pr&eacute;sentes conditions d&#39;utilisation
          ne vous autorisent pas &agrave; recevoir, et n&#39;obligent pas Ojiis
          &agrave; fournir, une documentation papier, une assistance, une
          assistance t&eacute;l&eacute;phonique, des am&eacute;liorations ou des
          mises &agrave; jour de l&#39;application.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">3.1 SOUMISSIONS DE CONTENU UTILISATEUR</span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          L&#39;Application peut vous permettre de cr&eacute;er du contenu tel
          que des vid&eacute;os, des donn&eacute;es, des photographies, des
          messages, des graphiques, du texte et d&#39;autres informations
          (&quot;Contenu utilisateur&quot;), et de partager ce Contenu
          utilisateur avec Ojiis ou avec d&#39;autres sites, y compris des sites
          de r&eacute;seaux sociaux, comme vous pouvez le d&eacute;signer. Vous
          &ecirc;tes seul responsable de Votre Contenu Utilisateur que vous
          t&eacute;l&eacute;chargez, publiez, affichez, cr&eacute;ez un lien
          vers ou autrement rendez disponible (ci-apr&egrave;s,
          &quot;partagez&quot;) sur ou via l&#39;Application, et Vous acceptez
          qu&#39;Ojiis agisse uniquement comme un conduit passif pour Votre
          distribution et publication en ligne de Votre Contenu Utilisateur.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          L&#39;Application peut permettre le partage du Contenu utilisateur de
          deux mani&egrave;res&nbsp;: ( i ) en t&ecirc;te-&agrave;-t&ecirc;te
          (par exemple, par SMS, e-mail, WeChat, Facebook et d&#39;autres
          plates-formes de messagerie)&nbsp;; et (ii) un &agrave; plusieurs (par
          exemple, Tik Tok, Facebook, YouTube, Twitter, Everyplay , e-mail).
          Sauf indication contraire explicite dans les pr&eacute;sentes, aucun
          contenu utilisateur n&#39;est stock&eacute; par Ojiis et Ojiis agit
          uniquement comme un conduit passif, vous offrant une plate-forme pour
          cr&eacute;er du contenu utilisateur et (uniquement pour vous aider et
          dans le but d&#39;am&eacute;liorer l&#39;exp&eacute;rience
          utilisateur) vous r&eacute;f&eacute;rant &agrave; un tiers tiers (par
          exemple, client de messagerie, application Facebook, Twitter ou
          YouTube) o&ugrave; vous pouvez d&eacute;cider quel contenu utilisateur
          partager, comment, quand et avec qui.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous comprenez que, sauf indication contraire explicite dans les
          pr&eacute;sentes, Ojiis n&#39;a aucun contr&ocirc;le sur
          l&#39;acc&egrave;s &agrave; votre contenu d&#39;utilisateur, le
          partage de votre contenu d&#39;utilisateur, la confidentialit&eacute;
          ou la confidentialit&eacute; de votre contenu d&#39;utilisateur et que
          vous, ainsi que les fournisseurs de services tiers (par exemple,
          Facebook, YouTube, Twitter), sont soumis aux conditions
          d&#39;utilisation desdits fournisseurs de services tiers et ont le
          contr&ocirc;le exclusif de ce Contenu Utilisateur.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous acceptez de ne pas partager de Contenu utilisateur qui&nbsp;: ( i
          ) peut cr&eacute;er un risque de pr&eacute;judice, de perte, de
          blessure physique ou mentale, de d&eacute;tresse &eacute;motionnelle,
          de d&eacute;c&egrave;s, d&#39;invalidit&eacute;, de
          d&eacute;figuration ou de maladie physique ou mentale pour vous, pour
          toute autre personne ou pour tout animal&nbsp;; (ii) peut cr&eacute;er
          un risque de toute autre perte ou dommage &agrave; toute personne ou
          propri&eacute;t&eacute;&nbsp;; (iii) cherche &agrave; nuire ou
          &agrave; exploiter des enfants en les exposant &agrave; un contenu
          inappropri&eacute;, en leur demandant des d&eacute;tails
          personnellement identifiables, ou autrement&nbsp;; (iv) peut
          constituer ou contribuer &agrave; un crime ou &agrave; un
          d&eacute;lit&nbsp;; (v) contient des informations ou du contenu
          qu&#39;Ojiis consid&egrave;re comme ill&eacute;gaux, nuisibles,
          abusifs, racialement ou ethniquement offensants, diffamatoires,
          portant atteinte &agrave; la vie priv&eacute;e ou aux droits de
          publicit&eacute;, harcelants, humiliants pour d&#39;autres personnes
          (publiquement ou autrement), diffamatoires, mena&ccedil;ant, profane
          ou autrement r&eacute;pr&eacute;hensible&nbsp;; (vi) contient des
          informations ou du contenu ill&eacute;gal (y compris, sans s&#39;y
          limiter, la divulgation d&#39;informations privil&eacute;gi&eacute;es
          en vertu du droit des valeurs mobili&egrave;res ou des secrets
          commerciaux d&#39;une autre partie)&nbsp;; (vii) contient des
          informations ou du contenu que vous n&#39;avez pas le droit de mettre
          &agrave; disposition en vertu d&#39;une loi ou de relations
          contractuelles ou fiduciaires&nbsp;; ou (viii) contient des
          informations ou du contenu dont vous savez qu&#39;ils ne sont pas
          corrects et &agrave; jour.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Si vous pensez qu&#39;un certain contenu utilisateur partag&eacute;
          via l&#39;application, mais stock&eacute; sur des services tiers (par
          exemple, Facebook, YouTube, etc.), porte atteinte &agrave; vos droits,
          veuillez contacter le fournisseur de services tiers respectif
          o&ugrave; ce contenu utilisateur est stock&eacute;. .
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous acceptez que tout Contenu utilisateur que Vous partagez ne viole
          pas et ne violera pas les droits de tiers de quelque nature que ce
          soit, y compris et sans s&#39;y limiter, les &laquo;&nbsp;Droits de
          propri&eacute;t&eacute; intellectuelle&nbsp;&raquo; (tels que
          d&eacute;finis &agrave; la section 7 des pr&eacute;sentes) ou les
          droits de publicit&eacute; et de confidentialit&eacute;. Dans la
          mesure o&ugrave; votre contenu utilisateur contient de la musique,
          vous d&eacute;clarez par la pr&eacute;sente que vous &ecirc;tes le
          propri&eacute;taire de tous les droits d&#39;auteur, y compris et sans
          s&#39;y limiter, les droits d&#39;ex&eacute;cution et
          d&#39;enregistrement m&eacute;canique et sonore, &agrave;
          l&#39;&eacute;gard de chaque composition musicale (y compris les
          paroles ) et enregistrement sonore contenus dans ce Contenu
          Utilisateur et que Vous avez le pouvoir d&#39;accorder la licence
          accord&eacute;e ci-dessous.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span>
          LA CR&Eacute;ATION ET LE PARTAGE DU CONTENU UTILISATEUR D&Eacute;CRIT
          DANS CETTE SECTION NE SONT PAS ACTIV&Eacute;S POUR LES UTILISATEURS
          QUI S&#39;IDENTIFIENT COMME &Acirc;G&Eacute;S DE MOINS DE 13 ANS.{" "}
        </span>
        <span className="c5">https://ojiis.com/privacy-policy </span>
        <span className="c8">.</span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">
          3.2 OCTROI DE LICENCE POUR LE CONTENU UTILISATEUR
        </span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          En cr&eacute;ant du contenu utilisateur via les applications Ojiis,
          vous accordez express&eacute;ment, et vous d&eacute;clarez et
          garantissez que vous avez le droit d&#39;accorder &agrave; Ojiis une
          licence gratuite, sous-licenciable, transf&eacute;rable,
          perp&eacute;tuelle, irr&eacute;vocable, non exclusive et mondiale
          d&#39;utilisation, reproduire, modifier, publier, mon&eacute;tiser,
          r&eacute;pertorier les informations concernant, &eacute;diter,
          traduire, distribuer, ex&eacute;cuter publiquement, afficher
          publiquement et cr&eacute;er des &oelig;uvres d&eacute;riv&eacute;es
          de, et autrement exploiter, tout ce contenu utilisateur, ainsi que
          votre nom, nom d&#39;utilisateur, voix, et/ou la ressemblance contenue
          dans Votre Contenu Utilisateur, en tout ou en partie, et sous toute
          forme, m&eacute;dia ou technologie, qu&#39;ils soient connus ou
          d&eacute;velopp&eacute;s ult&eacute;rieurement, pour une utilisation
          en relation avec l&#39;Application et Ojiis (et ses successeurs et
          soci&eacute;t&eacute;s affili&eacute;es &#39;) entreprise, y compris
          et sans limitation pour la promotion et la redistribution de tout ou
          partie de l&#39;Application (et de toute &oelig;uvre
          d&eacute;riv&eacute;e de celle-ci) ou de toute Application Ojiis dans
          tous les formats de m&eacute;dias et via toutes les collections de
          m&eacute;dias et en relation avec diverses publicit&eacute;s, telles
          que des banni&egrave;res publicitaires, annonces mobiles et annonces
          vid&eacute;o. Par la pr&eacute;sente, vous accordez &eacute;galement
          &agrave; chaque utilisateur de l&#39;application une licence non
          exclusive pour acc&eacute;der &agrave; votre contenu utilisateur via
          l&#39;application, et pour utiliser, reproduire, distribuer, afficher
          et ex&eacute;cuter ce contenu utilisateur tel qu&#39;autoris&eacute;
          par la fonctionnalit&eacute; de l&#39;application ou d&#39;Internet et
          en vertu du pr&eacute;sent Accord.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">
          3.3 R&Egrave;GLES SP&Eacute;CIFIQUES AU CONTENU UTILISATEUR
        </span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c3">
          En cr&eacute;ant du contenu utilisateur via les applications Ojiis,
          vous acceptez ces r&egrave;gles sp&eacute;cifiques qui
          d&eacute;crivent votre utilisation des droits de
          propri&eacute;t&eacute; intellectuelle d&#39;Ojiis&nbsp;:
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <ul className="c6 lst-kix_list_1-0 start">
        <li className="c1 li-bullet-0">
          <span className="c3">
            Le contenu utilisateur sera utilis&eacute; dans le seul but
            d&#39;afficher, d&#39;identifier et de discuter des applications
            Ojiis &agrave; des fins non commerciales conform&eacute;ment aux
            pr&eacute;sentes r&egrave;gles sp&eacute;cifiques. Vous
            n&#39;&ecirc;tes pas autoris&eacute; &agrave; cr&eacute;er de
            nouvelles applications, produits et/ou contenus bas&eacute;s sur les
            droits de propri&eacute;t&eacute; intellectuelle d&#39;Ojiis,
            m&ecirc;me si ce contenu est gratuit.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; facturer des
            frais de quelque nature que ce soit aux clients ou aux visiteurs de
            Votre Contenu Utilisateur, sauf si Vous avez obtenu
            l&#39;autorisation &eacute;crite expresse pr&eacute;alable
            d&#39;Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Ojiis, &agrave; sa seule discr&eacute;tion, se r&eacute;serve le
            droit de mon&eacute;tiser ou d&#39;utiliser le Contenu Utilisateur
            ou ses parties cr&eacute;&eacute;es via les Applications Ojiis. Dans
            la mesure o&ugrave; Ojiis n&#39;utilise pas son droit de
            mon&eacute;tiser le contenu utilisateur, vous pouvez
            mon&eacute;tiser votre contenu utilisateur en utilisant des
            publicit&eacute;s, mais vous &ecirc;tes seul responsable de vous
            assurer que cette publicit&eacute; est conforme &agrave; toutes les
            lois, r&egrave;gles, r&eacute;glementations et
            r&eacute;glementations applicables. politiques des
            d&eacute;veloppeurs.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Vous ne devez pas cr&eacute;er et utiliser le Contenu utilisateur
            sous l&#39;impression qu&#39;Ojiis est un cr&eacute;ateur ou un
            sponsor de Votre Contenu utilisateur. En outre, vous
            n&#39;&ecirc;tes pas non plus autoris&eacute; &agrave; cr&eacute;er
            des &eacute;l&eacute;ments &agrave; partir des droits de
            propri&eacute;t&eacute; intellectuelle d&#39;Ojiis qui soient
            similaires aux logos, marques d&eacute;pos&eacute;es d&#39;Ojiis ou
            &agrave; tout autre &eacute;l&eacute;ment des droits de
            propri&eacute;t&eacute; intellectuelle d&#39;Ojiis. Vous
            n&#39;&ecirc;tes pas autoris&eacute; &agrave; modifier de quelque
            mani&egrave;re que ce soit les droits de propri&eacute;t&eacute;
            intellectuelle d&#39;Ojiis sans l&#39;autorisation &eacute;crite
            expresse pr&eacute;alable d&#39;Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Sauf si vous avez obtenu l&#39;autorisation &eacute;crite
            pr&eacute;alable d&#39;Ojiis, vous n&#39;&ecirc;tes pas
            autoris&eacute; &agrave; fabriquer ou &agrave; distribuer
            (gratuitement ou autrement) des &eacute;l&eacute;ments physiques
            portant l&#39;un des droits de propri&eacute;t&eacute;
            intellectuelle d&#39;Ojiis.
          </span>
        </li>
      </ul>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Ojiis se r&eacute;serve le droit de d&eacute;terminer, &agrave; sa
          seule discr&eacute;tion, quel contenu utilisateur est conforme aux
          r&egrave;gles sp&eacute;cifiques susmentionn&eacute;es et peut
          r&eacute;voquer la licence limit&eacute;e dans les pr&eacute;sentes
          conditions d&#39;utilisation &agrave; tout moment et pour quelque
          raison que ce soit.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">
          3.4 LIMITATION DE RESPONSABILIT&Eacute; POUR LE CONTENU UTILISATEUR
        </span>
      </p>
      <p className="c2">
        <span className="c3">
          Ojiis n&#39;assume aucune responsabilit&eacute; et n&#39;assume aucune
          responsabilit&eacute; pour tout contenu utilisateur que vous ou tout
          autre utilisateur ou tiers cr&eacute;ez avec l&#39;application ou
          partagez via l&#39;application. Vous serez seul responsable de Votre
          propre Contenu Utilisateur et des cons&eacute;quences de son partage
          ou de sa publication. Vous comprenez et acceptez que toute perte ou
          tout dommage de quelque nature que ce soit r&eacute;sultant de
          l&#39;utilisation de tout Contenu d&#39;utilisateur que Vous envoyez,
          t&eacute;l&eacute;chargez, t&eacute;l&eacute;chargez, diffusez,
          publiez, transmettez, affichez, partagez ou rendez autrement
          disponible ou accessible via Votre l&#39;utilisation de
          l&#39;Application rel&egrave;ve de Votre seule responsabilit&eacute;.
          En relation avec Votre Contenu Utilisateur et en plus de toute autre
          repr&eacute;sentation et garantie, Vous affirmez, d&eacute;clarez et
          garantissez ce qui suit&nbsp;:
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c3">
            Votre contenu d&#39;utilisateur et son utilisation par Ojiis et
            l&#39;application n&#39;enfreindront aucun droit d&#39;un tiers, y
            compris, mais sans s&#39;y limiter, les droits de
            propri&eacute;t&eacute; intellectuelle, les droits &agrave; la vie
            priv&eacute;e et les droits de publicit&eacute;.{" "}
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Ojiis peut exercer les droits sur votre contenu utilisateur
            accord&eacute;s en vertu du pr&eacute;sent accord sans &ecirc;tre
            tenu responsable du paiement des frais de guilde, des
            r&eacute;sidus, des paiements, des frais ou des redevances payables
            en vertu de toute convention collective ou autrement.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c3">
            Ojiis n&#39;est pas responsable de l&#39;affichage public ou de
            l&#39;utilisation abusive de votre contenu utilisateur. Vous
            comprenez et reconnaissez que vous pouvez &ecirc;tre expos&eacute;
            &agrave; un contenu utilisateur inexact, offensant, ind&eacute;cent,
            r&eacute;pr&eacute;hensible ou inappropri&eacute; pour les enfants,
            et vous acceptez qu&#39;Ojiis ne soit pas responsable des dommages
            que vous pr&eacute;tendez subir &agrave; la suite d&#39;un tel
            contenu utilisateur. .
          </span>
        </li>
      </ol>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">4. ACC&Egrave;S</span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous devez fournir, &agrave; vos propres frais,
          l&#39;&eacute;quipement, les connexions Internet ou les appareils
          mobiles et/ou les plans de service pour acc&eacute;der et utiliser
          l&#39;application. Ojiis ne garantit pas que l&#39;Application est
          disponible dans toutes les zones g&eacute;ographiques. Vous
          reconnaissez que lorsque vous utilisez l&#39;application, votre
          op&eacute;rateur de t&eacute;l&eacute;phonie mobile peut vous facturer
          des frais pour les donn&eacute;es, la messagerie et/ou tout autre
          acc&egrave;s sans fil. V&eacute;rifiez aupr&egrave;s de votre
          op&eacute;rateur si de tels frais s&#39;appliquent &agrave; vous. Vous
          &ecirc;tes seul responsable de tous les frais que Vous engagez pour
          acc&eacute;der &agrave; l&#39;Application &agrave; partir de Votre
          appareil mobile et/ou de votre appareil PC. Votre droit d&#39;utiliser
          l&#39;Application est &eacute;galement fond&eacute; sur Votre respect
          des conditions applicables des accords que Vous avez conclus avec des
          tiers lors de l&#39;utilisation de l&#39;Application.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">5. ACHATS IN-APP</span>
      </p>
      <p className="c2">
        <span className="c3">
          Détails de l'abonnement et processus de renouvellement
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">Modalités de l'abonnement :</span>
      </p>
      <p className="c2">
        <span>
          En accédant et en utilisant notre application Ojiis ("L'Application"),
          vous reconnaissez et acceptez les modalités d'abonnement suivantes :
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          1.1. Essai gratuit : L'Application offre un essai gratuit de 3 jours
          aux nouveaux utilisateurs ("la Période d'essai"). Pendant la Période
          d'essai, vous aurez accès à l'ensemble des fonctionnalités disponibles
          dans l'Application. À la fin de la Période d'essai, votre abonnement
          sera automatiquement converti en un abonnement payant, tel que décrit
          à la section 2 ci-dessous, sauf si vous annulez votre abonnement avant
          l'expiration de la Période d'essai.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          1.2. Abonnement payant : À l'expiration de la Période d'essai, vous
          pouvez choisir de vous abonner à l'Application sur une base mensuelle
          ou annuelle ("L'Abonnement"). L'Abonnement vous accorde un accès
          continu aux fonctionnalités et au contenu de l'Application pour la
          durée sélectionnée. Le tarif d'abonnement de l'Application est de 4,99
          $ par mois ou de 49,99 $ par an, selon le cas, et peuvent être
          modifiés à notre discrétion.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">2. Renouvellement de l'abonnement :</span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          2.1. Renouvellement automatique : les abonnements seront
          automatiquement renouvelés à moins qu'ils ne soient annulés dans les
          24 heures précédant la fin de la période en cours. Le renouvellement
          se fera au tarif d'abonnement en vigueur, sauf avis contraire.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          2.2. Cycle de facturation : Le cycle de facturation de votre
          abonnement dépend de la durée que vous avez choisie. Pour les
          abonnements mensuels, le cycle de facturation sera de 30 jours à
          compter de la date de votre abonnement initial. Pour les abonnements
          annuels, le cycle de facturation sera de 365 jours à compter de la
          date de votre abonnement initial.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          2.3. Autorisation de paiement : En vous abonnant à l'application, vous
          nous autorisez à facturer les frais d'abonnement applicables au mode
          de paiement associé à votre identifiant d'application ou à d'autres
          modes de paiement autorisés liés à votre compte App Store.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          2.4. Modifications de tarif : Nous nous réservons le droit de modifier
          les frais d'abonnement à tout moment, en vous fournissant un préavis
          raisonnable. Ce préavis peut être envoyé par e-mail ou affiché dans
          l'Application. Votre utilisation continue de l'Application après la
          date d'entrée en vigueur de la modification de tarif constitue votre
          acceptation du nouveau tarif d'abonnement.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">3. Annulation et remboursements :</span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          3.1. Annulation de l'abonnement : Vous pouvez annuler votre abonnement
          à tout moment en gérant vos abonnements via les paramètres de votre
          identifiant d'application ou en suivant les instructions fournies par
          l'App Store. Veuillez noter que l'annulation de votre abonnement ne
          vous donne pas droit à un remboursement pour la partie inutilisée de
          l'abonnement.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          3.2. Remboursements : Sauf si la loi applicable l'exige, tous les
          frais d'abonnement ne sont pas remboursables. En cas de résiliation ou
          d'annulation de votre abonnement, vous ne serez pas remboursé pour la
          partie inutilisée de la période d'abonnement.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">4. Coordonnées</span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          Si vous avez des questions ou des préoccupations concernant votre
          abonnement, son renouvellement ou tout autre sujet connexe, veuillez
          contacter notre équipe de support à support@ojiis.com.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c3">
          Pour plus d'informations sur la gestion des achats intégrés, veuillez
          consulter notre foire aux questions disponible sur
          https://ojiis.com/faq .
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>

      <p className="c2">
        <span className="c0">6. DUR&Eacute;E ET R&Eacute;SILIATION</span>
      </p>
      <p className="c2">
        <span className="c3">
          La dur&eacute;e des pr&eacute;sentes conditions d&#39;utilisation
          commencera &agrave; la date &agrave; laquelle vous installez ou
          utilisez l&#39;application et se termine &agrave; la date &agrave;
          laquelle vous avez mis l&#39;application au rebut ou &agrave; la date
          de r&eacute;siliation par Ojiis des pr&eacute;sentes conditions
          d&#39;utilisation. Vous pouvez r&eacute;silier les pr&eacute;sentes
          Conditions d&#39;utilisation en d&eacute;sinstallant
          l&#39;Application.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          La r&eacute;siliation ne limitera aucun des autres droits ou recours
          d&#39;Ojiis en droit ou en &eacute;quit&eacute;. Si l&#39;une des
          plates-formes d&eacute;sactive la possibilit&eacute; d&#39;utiliser
          l&#39;application sur votre appareil conform&eacute;ment &agrave;
          votre accord avec cette plate-forme, tous les droits de licence
          associ&eacute;s avec Ojiis seront &eacute;galement
          r&eacute;sili&eacute;s.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">7. R&Eacute;SERVE DE DROITS</span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous avez obtenu une licence pour l&#39;Application et Vos droits sont
          soumis aux pr&eacute;sentes Conditions d&#39;utilisation. Sauf
          autorisation expresse dans les pr&eacute;sentes, Ojiis se
          r&eacute;serve tous les droits, titres et int&eacute;r&ecirc;ts dans
          l&#39;Application. Cette licence est limit&eacute;e aux droits de
          propri&eacute;t&eacute; intellectuelle d&#39;Ojiis et n&#39;inclut
          aucun droit sur d&#39;autres brevets ou propri&eacute;t&eacute;
          intellectuelle.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Ojiis conserve tous les droits, titres et int&eacute;r&ecirc;ts
          relatifs aux droits de propri&eacute;t&eacute; intellectuelle
          d&#39;Ojiis, tels que d&eacute;finis ci-dessous, qu&#39;ils soient
          enregistr&eacute;s ou non, et toutes leurs applications, &agrave;
          l&#39;exception du droit d&#39;auteur de la technologie tierce. Le
          logiciel Ojiis est prot&eacute;g&eacute; par les lois et
          trait&eacute;s applicables dans le monde entier.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          Aux fins des pr&eacute;sentes Conditions d&#39;utilisation,
          &laquo;&nbsp;Droits de propri&eacute;t&eacute;
          intellectuelle&nbsp;&raquo; d&eacute;signe tous les droits de brevet,
          noms commerciaux, droits de propri&eacute;t&eacute;, droits
          d&#39;auteur, titres, codes informatiques, effets audiovisuels,
          th&egrave;mes, personnages, noms de personnages, histoires, dialogues,
          param&egrave;tres , &oelig;uvres d&#39;art, effets sonores,
          &oelig;uvres musicales, droits sur les masques, droits moraux, droits
          de publicit&eacute;, droits sur les marques, les habillages
          commerciaux et les marques de service, survaleur, droits sur les
          secrets commerciaux et autres droits de propri&eacute;t&eacute;
          intellectuelle qui peuvent exister actuellement ou exister
          ult&eacute;rieurement, et toutes les applications correspondantes et
          les enregistrements, renouvellements et extensions de celles-ci, en
          vertu des lois de tout &eacute;tat, pays, territoire ou autre
          juridiction.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">8. EXCLUSION DE GARANTIES</span>
      </p>
      <p className="c2">
        <span className="c3">
          DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, L&#39;APPLICATION
          VOUS EST FOURNIE &laquo; TEL QUELLE &raquo;, AVEC TOUS LES
          D&Eacute;FAUTS, SANS GARANTIE D&#39;AUCUNE SORTE, SANS ASSURANCES DE
          PERFORMANCE OU GARANTIES D&#39;AUCUNE SORTE, ET VOTRE UTILISATION EST
          &Agrave; VOS PROPRES RISQUES. L&#39;INT&Eacute;GRALIT&Eacute; DU
          RISQUE D&#39;UNE QUALIT&Eacute; ET D&#39;UNE PERFORMANCE SATISFAISANTE
          VOUS INCOMBE. OJIIS ET LES CONC&Eacute;DANTS DE LICENCE D&#39;OJIIS
          (COLLECTIVEMENT &quot;OJIIS&quot; AUX FINS DE CETTE SECTION ET DE LA
          SECTION 10) N&#39;OFFRENT AUCUNE ET TOUTES LES GARANTIES EXPRESSES,
          IMPLICITES OU L&Eacute;GALES, Y COMPRIS LES GARANTIES IMPLICITES DE
          CONDITION, D&#39;UTILISATION ININTERROMPUE, DE QUALIT&Eacute;
          MARCHANDE, DE SATISFACTION QUALIT&Eacute;, AD&Eacute;QUATION &Agrave;
          UN USAGE PARTICULIER, ABSENCE DE VIOLATION DES DROITS DE TIERS ET
          GARANTIES (LE CAS &Eacute;CH&Eacute;ANT) D&Eacute;COULANT D&#39;UNE
          CONDUITE, D&#39;UNE UTILISATION OU D&#39;UNE PRATIQUE COMMERCIALE.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          OJIIS NE GARANTIT PAS CONTRE L&#39;INTERF&Eacute;RENCE AVEC VOTRE
          JOUISSANCE DE L&#39;APPLICATION&nbsp;; QUE L&#39;APPLICATION
          R&Eacute;PONDRA &Agrave; VOS EXIGENCES&nbsp;; QUE LE FONCTIONNEMENT DE
          L&#39;APPLICATION SERA ININTERROMPU OU SANS ERREUR&nbsp;; QUE
          L&#39;APPLICATION INTEROPERERA OU SERA COMPATIBLE AVEC TOUTE AUTRE
          APPLICATION&nbsp;; QUE TOUTE ERREUR DANS LA DEMANDE SERA
          CORRIG&Eacute;E&nbsp;; OU QUE L&#39;APPLICATION SERA DISPONIBLE POUR
          LES R&Eacute;INSTALLATIONS SUR LE M&Ecirc;ME APPAREIL OU SUR PLUSIEURS
          APPAREILS.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          AUCUN CONSEIL ORAL OU &Eacute;CRIT FOURNI PAR OJIIS OU TOUT
          REPR&Eacute;SENTANT AUTORIS&Eacute; OU TIERS NE CONSTITUE UNE
          GARANTIE. CERTAINES JURIDICTIONS N&#39;AUTORISENT PAS L&#39;EXCLUSION
          OU LA LIMITATION DES GARANTIES IMPLICITES OU LES LIMITATIONS DES
          DROITS L&Eacute;GAUX APPLICABLES D&#39;UN CONSOMMATEUR, DONC CERTAINES
          OU TOUTES LES EXCLUSIONS ET LIMITATIONS CI-DESSUS PEUVENT NE PAS
          S&#39;APPLIQUER &Agrave; VOUS.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">9. LIMITATION DE RESPONSABILIT&Eacute;</span>
      </p>
      <p className="c2">
        <span className="c3">
          DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, OJIIS, SES
          FILIALES OU SES AFFILI&Eacute;S NE SERONT EN AUCUN CAS RESPONSABLES
          ENVERS VOUS DE TOUT DOMMAGE PERSONNEL, DOMMAGE MAT&Eacute;RIEL, PERTE
          DE PROFITS, CO&Ucirc;T DE BIENS OU SERVICES DE SUBSTITUTION, PERTE DE
          DONN&Eacute;ES, PERTE DE BONNE VOLONT&Eacute; , ARR&Ecirc;T DE
          TRAVAIL, PANNE OU DYSFONCTIONNEMENT DE L&#39;ORDINATEUR, OU TOUTE
          AUTRE FORME DE DOMMAGES DIRECTS OU INDIRECTS, SP&Eacute;CIAUX,
          ACCESSOIRES, CONS&Eacute;CUTIFS OU PUNITIFS DE TOUTES CAUSES
          D&#39;ACTION D&Eacute;COULANT DE OU LI&Eacute;ES &Agrave; CETTE
          CONDITIONS DE SERVICES OU &Agrave; L&#39;APPLICATION, QU&#39;ELLES
          D&Eacute;COULENT D&#39;UN D&Eacute;LIT ( Y COMPRIS LA
          N&Eacute;GLIGENCE), CONTRAT, RESPONSABILIT&Eacute; STRICTE OU AUTRE,
          QU&#39;OJIIS A &Eacute;T&Eacute; AVIS&Eacute; OU NON DE LA
          POSSIBILIT&Eacute; DE TELS DOMMAGES ET QU&#39;UNE R&Eacute;PARATION,
          UN REMPLACEMENT OU UN REMBOURSEMENT (SI ACCORD&Eacute; &Agrave; NOTRE
          SEULE DISCR&Eacute;TION) POUR L&#39;APPLICATION NE COMPENSE PAS
          ENTI&Egrave;REMENT VOUS POUR TOUTE PERTE.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c3">
          CERTAINES JURIDICTIONS NE PERMETTENT PAS UNE LIMITATION DE
          RESPONSABILIT&Eacute; EN CAS DE D&Eacute;C&Egrave;S, DE BLESSURES
          CORPORELLES, DE FAUSSES D&Eacute;CLARATIONS FRAUDULEUSES OU DE
          CERTAINS ACTES INTENTIONNELS OU N&Eacute;GLIGENTS, OU LA VIOLATION DE
          LOIS SP&Eacute;CIFIQUES, OU LA LIMITATION DES DOMMAGES ACCESSOIRES OU
          CONS&Eacute;CUTIFS, DONC CERTAINES OU TOUTES LES LIMITATIONS DE
          RESPONSABILIT&Eacute; CI-DESSUS PEUT NE PAS S&#39;APPLIQUER &Agrave;
          VOUS. En aucun cas, la responsabilit&eacute; totale d&#39;Ojiis envers
          vous pour tous les dommages (sauf si la loi applicable l&#39;exige) ne
          d&eacute;passera le montant que vous avez effectivement pay&eacute;
          pour l&#39;application. CETTE LIMITATION S&#39;APPLIQUE, MAIS
          N&#39;EST PAS LIMIT&Eacute;E &Agrave; TOUT CE QUI CONCERNE
          L&#39;APPLICATION, LES SERVICES OU LE CONTENU RENDU DISPONIBLE VIA
          L&#39;APPLICATION.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">10. INDEMNISATION</span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous acceptez de d&eacute;fendre, d&#39;indemniser et
          d&#39;exon&eacute;rer Ojiis et ses employ&eacute;s, sous-traitants,
          dirigeants et administrateurs de toutes les r&eacute;clamations,
          poursuites, dommages, co&ucirc;ts, poursuites, amendes,
          p&eacute;nalit&eacute;s, responsabilit&eacute;s, d&eacute;penses (y
          compris les honoraires d&#39;avocat) qui d&eacute;coulent de Votre
          utilisation ou votre mauvaise utilisation de l&#39;Application, la
          violation des Conditions d&#39;utilisation ou la violation des droits
          d&#39;un tiers. Ojiis se r&eacute;serve le droit d&#39;assumer la
          d&eacute;fense et le contr&ocirc;le exclusifs de toute affaire
          autrement sujette &agrave; indemnisation de votre part, auquel cas
          vous coop&eacute;rerez pour faire valoir toutes les d&eacute;fenses
          disponibles.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">11. INJONCTION</span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous acceptez qu&#39;une violation des pr&eacute;sentes conditions
          d&#39;utilisation causera un pr&eacute;judice irr&eacute;parable
          &agrave; Ojiis pour lequel des dommages-int&eacute;r&ecirc;ts ne
          constitueraient pas un recours ad&eacute;quat et Ojiis aura le droit
          de demander une r&eacute;paration &eacute;quitable en plus de tout
          recours dont il dispose en vertu des pr&eacute;sentes ou en vertu de
          la loi sans caution. , autre garantie ou preuve de dommages.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">13. DROIT APPLICABLE</span>
      </p>
      <p className="c2">
        <span className="c3 c10">
          Le pr&eacute;sent Contrat et tous les litiges ou controverses
          d&eacute;coulant de ou li&eacute;s au pr&eacute;sent Contrat seront
          r&eacute;gis et interpr&eacute;t&eacute;s conform&eacute;ment aux lois
          de l&#39;&Eacute;tat du Qu&eacute;bec et du Canada, &agrave;
          l&#39;exclusion de ses r&egrave;gles de conflit de lois.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">14. ARBITRAGE</span>
      </p>
      <p className="c2">
        <span className="c3">
          Nous ferons tous les efforts raisonnables pour r&eacute;soudre tout
          d&eacute;saccord que vous pourriez avoir avec Ojiis. Si ces efforts
          &eacute;chouent, en utilisant l&#39;application, vous acceptez que
          toute r&eacute;clamation, litige ou controverse que vous pourriez
          avoir contre Ojiis d&eacute;coulant de, li&eacute; &agrave; ou
          li&eacute; de quelque mani&egrave;re que ce soit au pr&eacute;sent
          accord et/ou &agrave; l&#39;application, sera r&eacute;solu
          exclusivement par arbitrage d&eacute;finitif et ex&eacute;cutoire.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15. DISPOSITIONS FINALES</span>
      </p>
      <p className="c2 c7">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c0">15.1 ADMISSIBILIT&Eacute;</span>
      </p>
      <p className="c2">
        <span className="c3">
          Toute personne qui utilise l&#39;Application d&eacute;clare &agrave;
          Ojiis qu&#39;elle a au moins l&#39;&acirc;ge de la majorit&eacute; en
          vertu de la loi applicable, ou si elle n&#39;a pas atteint
          l&#39;&acirc;ge de la majorit&eacute;, qu&#39;elle est soit un mineur
          &eacute;mancip&eacute;, soit qu&#39;elle poss&egrave;de le
          consentement l&eacute;gal d&#39;un parent ou d&#39;un tuteur
          l&eacute;gal, et sont pleinement capables et comp&eacute;tents pour
          conclure les termes, conditions, obligations, affirmations,
          d&eacute;clarations et garanties &eacute;nonc&eacute;es dans le
          pr&eacute;sent accord, et pour respecter et se conformer au
          pr&eacute;sent accord.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.2 DIVISIBILIT&Eacute; ET SURVIE</span>
      </p>
      <p className="c2">
        <span className="c3">
          Si une disposition des pr&eacute;sentes Conditions d&#39;utilisation
          est ill&eacute;gale ou inapplicable en vertu de la loi applicable, le
          reste de la disposition sera modifi&eacute; pour obtenir le plus
          fid&egrave;lement possible l&#39;effet de la condition d&#39;origine
          et toutes les autres dispositions resteront pleinement en vigueur.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.3 INT&Eacute;GRALIT&Eacute; DU CONTRAT</span>
      </p>
      <p className="c2">
        <span className="c3">
          Le pr&eacute;sent accord constitue l&#39;int&eacute;gralit&eacute; de
          l&#39;accord entre vous et Ojiis en ce qui concerne l&#39;application
          et remplace tous les accords ant&eacute;rieurs ou contemporains
          concernant ce sujet. Aucun amendement ou modification des
          pr&eacute;sentes conditions d&#39;utilisation ne sera contraignant
          &agrave; moins d&#39;&ecirc;tre fait par &eacute;crit et sign&eacute;
          par Ojiis. Aucun d&eacute;faut d&#39;exercice, ni aucun retard dans
          l&#39;exercice, de la part de l&#39;une ou l&#39;autre des parties, de
          tout droit ou de tout pouvoir en vertu des pr&eacute;sentes
          n&#39;emp&ecirc;che l&#39;exercice ult&eacute;rieur de tout autre
          droit en vertu des pr&eacute;sentes. En cas de conflit entre les
          pr&eacute;sentes Conditions d&#39;utilisation, tout achat applicable
          ou d&#39;autres conditions, les conditions des pr&eacute;sentes
          Conditions d&#39;utilisation pr&eacute;vaudront.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.4 LANGUE</span>
      </p>
      <p className="c2">
        <span className="c3">
          La version originale de ces conditions d&#39;utilisation est
          r&eacute;dig&eacute;e en anglais. Toute traduction de celui-ci dans
          une autre langue est effectu&eacute;e pour les besoins locaux et en
          cas de litige, d&#39;incoh&eacute;rence ou de divergence entre la
          version anglaise et toute version non anglaise, la version anglaise
          des pr&eacute;sentes conditions d&#39;utilisation pr&eacute;vaudra,
          dans la mesure o&ugrave; non interdite par la l&eacute;gislation
          locale dans Votre juridiction.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.5 EXPORTATION</span>
      </p>
      <p className="c2">
        <span className="c3">
          Vous acceptez de respecter les lois am&eacute;ricaines et autres lois
          applicables en mati&egrave;re de contr&ocirc;le des exportations et
          acceptez de ne pas transf&eacute;rer l&#39;Application &agrave; un
          ressortissant &eacute;tranger ou &agrave; une destination nationale,
          ce qui est interdit par ces lois, sans d&#39;abord obtenir, puis vous
          conformer &agrave; toute autorisation gouvernementale requise. Vous
          certifiez que vous n&#39;&ecirc;tes pas une personne avec laquelle
          Ojiis est interdit de faire des affaires en vertu de la loi
          applicable.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.6 MODIFICATIONS</span>
      </p>
      <p className="c2">
        <span className="c3">
          Nous mettrons occasionnellement &agrave; jour ces conditions
          d&#39;utilisation si n&eacute;cessaire pour prot&eacute;ger nos
          utilisateurs, fournir des informations &agrave; jour et
          r&eacute;pondre aux modifications juridiques et techniques. La version
          la plus r&eacute;cente des Conditions d&#39;utilisation
          pr&eacute;vaudra. Veuillez consulter r&eacute;guli&egrave;rement cette
          page Web pour tout changement. Nous nous r&eacute;servons le droit de
          modifier ces conditions d&#39;utilisation &agrave; tout moment en
          publiant la version modifi&eacute;e &agrave; l&#39;adresse
          mentionn&eacute;e ci-dessus. Votre utilisation de l&#39;Application
          apr&egrave;s l&#39;int&eacute;gration des modifications constituera
          Votre acceptation des modifications.
        </span>
      </p>
      <p className="c2 c7">
        <span className="c3"></span>
      </p>
      <p className="c2">
        <span className="c0">15.7 COORDONN&Eacute;ES</span>
      </p>
      <p className="c2">
        <span>
          Si vous souhaitez contacter Ojiis pour toute question, plainte ou
          r&eacute;clamation concernant l&#39;application, vous devez visiter le
          site Web d&#39;Ojiis &agrave; l&rsquo;adresse{" "}
        </span>
        <span className="c13">
          <a
            className="c9"
            href="https://www.google.com/url?q=http://www.ojiis.com&amp;sa=D&amp;source=editors&amp;ust=1682449696226331&amp;usg=AOvVaw2-pnEHIxl8AhIWuUggPSl0"
          >
            www.ojiis.com
          </a>
        </span>
        <span>&nbsp;ou contacter </span>
        <span className="c12">support@ojiis.com</span>
      </p>
      <p className="c2 c7">
        <span className="c3 c4"></span>
      </p>
      <p className="c2">
        <span className="c0">
          15.8 TRANSFERT DE PROPRIETE POUR LA VENTE D&#39;UNE APPLICATION
        </span>
      </p>
      <p className="c2">
        <span>
          Dans la mesure o&ugrave; la l&eacute;gislation applicable permet aux
          parties &agrave; une transaction de s&#39;entendre sur le lieu de
          transfert du titre de propri&eacute;t&eacute; pour les ventes de
          biens, Vous acceptez que le titre de vente de l&#39;Application
          (logiciel vendu par t&eacute;l&eacute;chargement &eacute;lectronique)
          passe l&agrave; o&ugrave; Ojiis est domicili&eacute;.
        </span>
      </p>
    </div>
  );
};

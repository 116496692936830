export const VideoPolicySP = () => {
  return (
    <div>
      <p className="c16">
        <span className="c1">
          Tal como se usa en esta Pol&iacute;tica de video, &quot;Ojiis&quot;,
          &quot;nosotros&quot;, &quot;nuestro&quot; o &quot;nuestro&quot; se
          refiere a Ojiis y todas sus subsidiarias y afiliadas.
        </span>
      </p>
      <p className="c16">
        <span className="c1">
          Esta Pol&iacute;tica de video es parte e incorporada por referencia a
          los T&eacute;rminos de servicio que rigen el uso de todas las
          aplicaciones de software de la marca &quot;Ojiis&quot; (las
          &quot;Aplicaciones&quot;), que pueden operar en cualquier tipo de
          dispositivo inform&aacute;tico (incluidos, entre otros, un dispositivo
          m&oacute;vil), y se aplica a su uso de las Aplicaciones y cualquier
          otro sitio web y/o URL que pueda reemplazarlo o que pueda agregarse a
          la lista de sitios web publicados por nosotros y vinculados a esta
          Pol&iacute;tica de video (los &quot;Sitios web&quot; ). Adem&aacute;s,
          y sin perjuicio de cualquier disposici&oacute;n en contrario
          establecida en el presente, esta Pol&iacute;tica de video
          tambi&eacute;n rige el uso de todo el contenido de video de Ojiis
          publicado en las Cuentas de redes sociales (incluidos, entre otros,
          Tik Tok, Facebook, Twitter, Instagram y Pinterest) y los canales de
          YouTube.
        </span>
      </p>
      <p className="c16">
        <span className="c1">
          Al usar las Aplicaciones, los Sitios web, las Cuentas de redes
          sociales o los canales de YouTube, como se define anteriormente, usted
          acepta y desea estar sujeto a los t&eacute;rminos de esta
          Pol&iacute;tica de video. Se le permite crear videos
          (&quot;Video(s)&quot;), utilizando nuestras aplicaciones,
          im&aacute;genes de video, metraje, m&uacute;sica, sonidos, discursos o
          cualquier otro recurso de Ojiis (&quot;Contenido de Ojiis&quot;),
          sujeto a las siguientes limitaciones y reglas :
        </span>
      </p>
      <p className="c11">
        <span className="c0">USO NO COMERCIAL. </span>
        <span className="c1">
          No se le permite cobrar ning&uacute;n tipo de tarifa a los usuarios
          para ver o acceder a sus videos. A menos que obtenga una
          aprobaci&oacute;n previa por escrito de Ojiis, tampoco puede vender o
          licenciar sus videos a cambio de ning&uacute;n tipo de pago. Ojiis se
          reserva el derecho de emitir un reclamo de monetizaci&oacute;n en
          Videos con Contenido de Ojiis.
        </span>
      </p>
      <p className="c11">
        <span className="c0">USO LIMITADO. </span>
        <span className="c1">
          Solo se permite un uso limitado del Contenido de Ojiis en sus Videos.
          Sin perjuicio de cualquier disposici&oacute;n en contrario establecida
          en el presente, la limitaci&oacute;n antes mencionada no se aplica a
          los videos que muestren actividades de las aplicaciones genuinas de
          Ojii, tal como se define a continuaci&oacute;n.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SIN DUPLICAR. </span>
        <span className="c1">
          Esta Pol&iacute;tica de video est&aacute; destinada a Videos que
          incluyen un giro creativo en el Contenido de Ojiis, por lo tanto, la
          simple duplicaci&oacute;n de activos de Ojiis no est&aacute; en
          l&iacute;nea con esta Pol&iacute;tica de video y Ojiis se reserva el
          derecho de eliminar o monetizar (en su totalidad o en parte) cualquier
          Video que se est&eacute; reproduciendo. Contenido de Ojiis.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SER APROPIADO. </span>
        <span className="c1">
          No tiene permitido crear y publicar videos que contengan desnudos,
          contenido sexualmente expl&iacute;cito, contenido violento o
          gr&aacute;fico, contenido odioso, da&ntilde;ino o peligroso, contenido
          ofensivo, contenido que da&ntilde;e la marca o cualquier otro
          contenido inapropiado que haga que lo falso, deshonesto, despectivo
          sea sensacionalista o declaraciones difamatorias sobre Ojiis y/o sus
          productos, empleados y agentes.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SOLO CONTENIDO ORIGINAL. </span>
        <span className="c1">
          Se le permite crear y publicar videos que muestren la actividad de las
          aplicaciones genuinas de Ojiis. Tenga en cuenta que puede haber
          aplicaciones en l&iacute;nea fraudulentas que usen el Contenido de
          Ojiis y que Ojiis solicitar&aacute; incondicionalmente que se eliminen
          dichos Videos que infrinjan el material con derechos de autor de
          Ojiis.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SIN TRAMPAS NI HACKEO. </span>
        <span className="c1">
          No tiene permitido crear, publicar ni promocionar videos que expliquen
          c&oacute;mo hacer trampa y/o piratear las aplicaciones de Ojiis.
        </span>
      </p>
      <p className="c11">
        <span className="c0">DEJA CLARO QUE ES UN PRODUCTO DE FAN. </span>
        <span className="c1">
          No se le permite crear y publicar videos bajo la impresi&oacute;n de
          que Ojiis es un creador o patrocinador de su video o de manera que la
          audiencia pueda entender que dicho video es un lanzamiento oficial de
          Ojiis.
        </span>
      </p>
      <p className="c11">
        <span className="c0">RESPETE LA PROPIEDAD CREATIVA. </span>
        <span className="c1">
          Aparte del contenido de Ojiis, no utilice materiales creativos de
          terceros en sus videos a menos que haya obtenido su aprobaci&oacute;n
          previa por escrito o haya comprado la licencia apropiada de dicho
          tercero. Ojiis no es responsable de ning&uacute;n reclamo de derechos
          de autor presentado por dichos terceros.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SIN DESCARGA SIN CONEXI&Oacute;N. </span>
        <span className="c1">
          No se le permite descargar videos oficiales de Ojiis de ninguna
          plataforma en l&iacute;nea (incluidos, entre otros, YouTube, Youku,
          Vimeo y Facebook Live) y difundir duplicaciones simples de dichos
          videos en otra plataforma en l&iacute;nea, realizar trabajos derivados
          de dichos videos y/o ponerlos a disposici&oacute;n para su descarga
          sin conexi&oacute;n.
        </span>
      </p>
      <p className="c11">
        <span className="c0">SIN DATOS ENGA&Ntilde;OSOS. </span>
        <span className="c5">
          No puede usar metadatos enga&ntilde;osos o no relacionados (incluidos,
          entre otros, t&iacute;tulos, descripciones, etiquetas), que incluyen
          marcas comerciales de Ojiis en los videos publicados.
        </span>
      </p>
      <ul className="c7 lst-kix_list_1-0 start">
        <li className="c15 li-bullet-0">
          <span className="c0">MINIATURAS. </span>
          <span className="c5">
            No se le permite replicar los motivos utilizados en las miniaturas
            originales de YouTube de Ojiis y/o utilizar los gr&aacute;ficos
            promocionales/sociales oficiales de Ojiis para las miniaturas.
          </span>
        </li>
      </ul>
      <p className="c13">
        <span className="c1">
          Ojiis se reserva el derecho de determinar, a su exclusivo criterio,
          qu&eacute; videos cumplen con las limitaciones y reglas mencionadas
          anteriormente. El incumplimiento de esta Pol&iacute;tica de video
          puede resultar en que Ojiis tome medidas para que se elimine su video
          sin previo aviso ni responsabilidad hacia usted.
        </span>
      </p>
      <p className="c13">
        <span className="c4 c0">
          CAMBIOS A NUESTRA POL&Iacute;TICA DE V&Iacute;DEO
        </span>
      </p>
      <p className="c13">
        <span className="c5 c6">Consulte </span>
        <span className="c6 c5 c12">https://ojiis.com/video-policy </span>
        <span className="c10 c6 c5">
          peri&oacute;dicamente para conocer los cambios en nuestra
          Pol&iacute;tica de videos. Ojiis se reserva el derecho de cambiar esta
          Pol&iacute;tica de video en cualquier momento y por cualquier motivo.
        </span>
      </p>
      <p className="c13">
        <span className="c4 c0">INFORMACI&Oacute;N DEL CONTACTO</span>
      </p>
      <p className="c13">
        <span className="c6 c5 c10">
          En caso de que tenga alguna pregunta, no dude en contactarnos a
          trav&eacute;s de support@ojiis.com.
        </span>
      </p>
      <p className="c2">
        <span className="c4 c0"></span>
      </p>
      <p className="c2">
        <span className="c0 c4"></span>
      </p>
      <p className="c2">
        <span className="c4 c17"></span>
      </p>
      <p className="c2">
        <span className="c4 c14"></span>
      </p>
    </div>
  );
};

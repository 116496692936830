import { homepage } from "../components/Homepage/Homepage.lang.fr";
import { footer } from "../components/Footer/Footer.lang.fr";
import { faq } from "../components/Pages/FAQ/FAQ.lang.fr";
import { contact_us } from "../components/Pages/ContactUs/ContactUs.lang.fr";

export const fr = {
  homepage,
  footer,
  faq,
  contact_us,
  see_more: "Voir Plus",
};

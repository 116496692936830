export const homepage = {
  section_1_line_1: "UNA NUEVA GENERACIÓN",
  section_1_line_2: "DE EMOJIS",
  section_1_line_3: "Se mueven. Hablan. Son graciosos.",
  section_1_line_4: "Están en 3D. ¡Parecen tomar vida!",
  section_1_line_5: "SON EL SIGUIENTE NIVEL DE EMOJIS",
  section_2_line_1: "HAY MILLONES DE OPCIONES PARA",
  section_2_line_2: "PERSONAIZAR TUS OJIIS",
  section_2_line_3: "Hay un Ojii para todo.",
  section_2_line_4: "¡Personalízalo y exprésate como nunca antes!",
  section_2_line_5: "¡Libera tu creatividad!",
  section_3_line_1: "LLEVALO AL SIGUIENTE",
  section_3_line_2: "NIVEL CON AR",
  section_3_line_3: "Tráelos a tu mundo con la realidad aumentada",
  section_4_line_1: "CALCOMANIAS TOTALMENTE",
  section_4_line_2: "PERSONALIZABLES",
  section_4_line_3: "¡Elige una imagen de la animación de tu Ojii para",
  section_4_line_4: "hacer tu propia calcomanía!",
  section_5_line_1: "CREADOR DE HISTORIAS",
  section_5_line_2: "INNOVADORAS",
  section_5_line_3:
    "Selecciona tus Ojiis y construye tus propias historias personalizadas.",
  section_6_line_1: "SIN",
  section_6_line_2: "ANUNCIOS",
  section_6_line_3: "¡Alimenta tu creatividad sin interrupciones!",
  section_7_line_1: "DALE VOZ",
  section_7_line_2: "A TU OJII",
  section_7_line_3: "¡Agrega tu toque personal!",
  section_7_line_4: "¡Graba tu voz, añade tu música, o escribe en él!",
  section_8_line_1: "TRANSFORMA TUS OJIIS",
  section_8_line_2: "EN ANIMALES",
  section_8_line_3: "¡Saca el Animal que llevas dentro!",
  section_8_line_4: "Transforma los Ojiis en tu animal favorito.",
  section_9_line_1: "CREA VIDEOS CON ESTILO",
  section_9_line_2: "EN LAS ESCENAS",
  section_9_line_3: "Haz videos únicos con fondos geniales",
  section_9_line_4: "o trae tus propio contenido a la escena.",
  section_10_line_1: "DESBLOQUEA TODO PARA CREAR PUBLICACIONES",
  section_10_line_2: "ÚNICAS Y DIVERTIDAS",
  section_10_line_3: "Personaliza tu comunicación como nunca antes.",
  section_10_line_4: "No lo digas. ¡Hazlo Ojii!",
  media_gallery: "GALERÍA MULTIMEDIA",
};

export const VideoPolicyFR = () => {
  return (
    <div>
      <p className="c12">
        <span className="c6 c3">
          Tel qu&#39;utilis&eacute; dans cette politique vid&eacute;o,
          &laquo;&nbsp;Ojiis&nbsp;&raquo;, &laquo;&nbsp;nous&nbsp;&raquo;,
          &laquo;&nbsp;notre&nbsp;&raquo; ou &laquo;&nbsp;notre&nbsp;&raquo;
          fait r&eacute;f&eacute;rence &agrave; Ojiis et &agrave; toutes ses
          filiales et soci&eacute;t&eacute;s affili&eacute;es.
        </span>
      </p>
      <p className="c12">
        <span className="c6 c3">
          Cette politique vid&eacute;o fait partie et est incorpor&eacute;e par
          r&eacute;f&eacute;rence dans les conditions d&#39;utilisation
          r&eacute;gissant l&#39;utilisation de toutes les applications
          logicielles de la marque &laquo;&nbsp;Ojiis&nbsp;&raquo; (la ou les
          &laquo;&nbsp;application(s)&nbsp;&raquo;), qui peuvent fonctionner sur
          tout type d&#39;appareil informatique (y compris, sans s&#39;y
          limiter, un appareil mobile), et s&#39;applique &agrave; votre
          utilisation des Applications et de tout autre site Web et/ou URL
          susceptible de le remplacer ou pouvant &ecirc;tre ajout&eacute;
          &agrave; la liste des sites Web que nous publions et renvoyant
          &agrave; la pr&eacute;sente Politique vid&eacute;o (le ou les
          &laquo;&nbsp;Sites Web&nbsp;&raquo; ). En outre, et nonobstant toute
          disposition contraire indiqu&eacute;e dans les pr&eacute;sentes, cette
          politique vid&eacute;o r&eacute;git &eacute;galement l&#39;utilisation
          de tout le contenu vid&eacute;o Ojiis publi&eacute; sur les comptes de
          m&eacute;dias sociaux (y compris, mais sans s&#39;y limiter, Tik Tok,
          Facebook, Twitter, Instagram et Pinterest) et les cha&icirc;nes
          YouTube.
        </span>
      </p>
      <p className="c12">
        <span className="c6 c3">
          En utilisant les applications, les sites Web, les comptes de
          m&eacute;dias sociaux ou les cha&icirc;nes YouTube, tels que
          d&eacute;finis ci-dessus, vous acceptez et souhaitez &ecirc;tre
          li&eacute; par les termes de cette politique vid&eacute;o. Vous
          &ecirc;tes autoris&eacute; &agrave; cr&eacute;er des vid&eacute;os
          (&quot;Vid&eacute;o(s)&quot;), en utilisant nos applications, images
          vid&eacute;o, s&eacute;quences, musique, sons, paroles ou tout autre
          &eacute;l&eacute;ment d&#39;Ojiis (&quot;Contenu Ojiis&quot;), sous
          r&eacute;serve des limitations et r&egrave;gles suivantes :
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">USAGE NON COMMERCIAL. </span>
        <span className="c6 c3">
          Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; facturer des frais
          aux utilisateurs pour visionner ou acc&eacute;der &agrave; vos
          vid&eacute;os. Sauf si vous obtenez une approbation &eacute;crite
          pr&eacute;alable d&#39;Ojiis, vous n&#39;&ecirc;tes pas non plus
          autoris&eacute; &agrave; vendre ou &agrave; conc&eacute;der sous
          licence vos Vid&eacute;os pour un paiement de quelque nature que ce
          soit. Ojiis se r&eacute;serve le droit d&#39;&eacute;mettre une
          demande de mon&eacute;tisation sur les vid&eacute;os pr&eacute;sentant
          du contenu Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c3 c14">UTILISATION LIMIT&Eacute;E. </span>
        <span className="c6 c3">
          Seule une utilisation limit&eacute;e du Contenu Ojiis est
          autoris&eacute;e dans vos Vid&eacute;os. Nonobstant toute disposition
          contraire &eacute;nonc&eacute;e dans les pr&eacute;sentes, la
          limitation susmentionn&eacute;e ne s&#39;applique pas aux
          vid&eacute;os montrant des activit&eacute;s authentiques provenant de
          l&#39;applications Ojiis telles que d&eacute;finies ci-dessous.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">PAS DE DUPLICATION. </span>
        <span className="c6 c3">
          Cette politique vid&eacute;o est destin&eacute;e aux vid&eacute;os qui
          incluent un d&eacute;riv&eacute; cr&eacute;atif du contenu Ojiis, par
          cons&eacute;quent, la simple duplication des actifs Ojiis n&#39;est
          pas conforme &agrave; cette politique vid&eacute;o et Ojiis se
          r&eacute;serve le droit de supprimer ou de mon&eacute;tiser (en tout
          ou en partie) toutes les vid&eacute;os qui reproduisent le contenu des
          Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">&Ecirc;TRE APPROPRI&Eacute;. </span>
        <span className="c6 c3">
          Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; cr&eacute;er et
          &agrave; publier des vid&eacute;os contenant de la nudit&eacute;, du
          contenu sexuellement explicite, du contenu violent ou graphique, du
          contenu haineux, nuisible ou dangereux, du contenu offensant, du
          contenu pr&eacute;judiciable &agrave; la marque ou tout autre contenu
          inappropri&eacute; qui rend faux, malhonn&ecirc;te, d&eacute;nigrant
          sensationnel ou d&eacute;clarations diffamatoires sur Ojiis et/ou ses
          produits, employ&eacute;s et agents.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">CONTENU ORIGINAL UNIQUEMENT. </span>
        <span className="c6 c3">
          Vous &ecirc;tes autoris&eacute; &agrave; cr&eacute;er et &agrave;
          publier des vid&eacute;os montrant l&#39;activit&eacute; des
          applications authentiques d&#39;Ojiis. Gardez &agrave; l&#39;esprit
          qu&#39;il peut y avoir des applications en ligne frauduleuses
          utilisant le contenu d&#39;Ojiis et qu&#39;Ojiis demandera sans
          condition de supprimer toute vid&eacute;o portant atteinte au droit
          d&#39;auteurs d&#39;Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">PAS DE TRICHE OU DE PIRATAGE. </span>
        <span className="c6 c3">
          Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; cr&eacute;er,
          publier et promouvoir des vid&eacute;os qui expliquent la tricherie
          et/ou le piratage des applications Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">
          INDIQUEZ QU&#39;IL S&#39;AGIT D&#39;UN PRODUIT DE FAN.{" "}
        </span>
        <span className="c6 c3">
          Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; cr&eacute;er et
          &agrave; publier des vid&eacute;os sous l&#39;impression qu&#39;Ojiis
          est un cr&eacute;ateur ou un sponsor de votre vid&eacute;o ou
          d&#39;une mani&egrave;re que le public pourrait comprendre que cette
          vid&eacute;o est une version officielle d&#39;Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">
          RESPECTEZ LA PROPRI&Eacute;T&Eacute; CR&Eacute;ATIVE.{" "}
        </span>
        <span className="c6 c3">
          En dehors du contenu d&#39;Ojiis, n&#39;utilisez pas de
          mat&eacute;riel cr&eacute;atif de tiers dans vos vid&eacute;os, sauf
          si vous avez obtenu leur approbation &eacute;crite pr&eacute;alable ou
          achet&eacute; une licence appropri&eacute;e aupr&egrave;s de ce tiers.
          Ojiis n&#39;est pas responsable des r&eacute;clamations de droits
          d&#39;auteur soulev&eacute;es par ces tiers.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">
          AUCUN T&Eacute;L&Eacute;CHARGEMENT HORS LIGNE.{" "}
        </span>
        <span className="c6 c3">
          Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave;
          t&eacute;l&eacute;charger des vid&eacute;os Ojiis officielles &agrave;
          partir de toute plate-forme en ligne (y compris, mais sans s&#39;y
          limiter, YouTube, Youku, Vimeo et Facebook Live) et &agrave; diffuser
          de simples duplications de ces vid&eacute;os sur une autre plate-forme
          en ligne, &agrave; cr&eacute;er des &oelig;uvres
          d&eacute;riv&eacute;es de ces Vid&eacute;os et/ou les rendre
          disponibles pour un t&eacute;l&eacute;chargement hors ligne.
        </span>
      </p>
      <p className="c2">
        <span className="c14 c3">PAS DE DONN&Eacute;ES TROMPEUSES. </span>
        <span className="c3">
          Vous ne pouvez pas utiliser de m&eacute;tadonn&eacute;es trompeuses
          et/ou non li&eacute;es (y compris, mais sans s&#39;y limiter, les
          titres, descriptions, balises), qui incluent les marques
          d&eacute;pos&eacute;es d&#39;Ojiis dans les vid&eacute;os
          publi&eacute;es.
        </span>
      </p>
      <ul className="c7 lst-kix_list_1-0 start">
        <li className="c10 li-bullet-0">
          <span className="c14 c3">VIGNETTES. </span>
          <span className="c3">
            Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave; reproduire les
            motifs utilis&eacute;s dans les vignettes YouTube originales
            d&#39;Ojiis et/ou &agrave; utiliser les graphiques
            promotionnels/sociaux officiels d&#39;Ojiis pour les vignettes.
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c6 c3">
          Ojiis se r&eacute;serve le droit de d&eacute;terminer, &agrave; sa
          seule discr&eacute;tion, quelles vid&eacute;os sont conformes aux
          limitations et r&egrave;gles susmentionn&eacute;es. Le non-respect de
          cette politique vid&eacute;o peut amener Ojiis &agrave; prendre des
          mesures pour faire supprimer votre vid&eacute;o sans pr&eacute;avis ni
          responsabilit&eacute; envers vous.
        </span>
      </p>
      <p className="c1">
        <span className="c11 c3">
          MODIFICATIONS DE NOTRE POLITIQUE VID&Eacute;O
        </span>
      </p>
      <p className="c1">
        <span className="c3 c15">
          Veuillez consulter r&eacute;guli&egrave;rement{" "}
        </span>
        <span className="c3 c9">https://ojiis.com/video-policy </span>
        <span className="c3 c13">
          pour conna&icirc;tre les modifications apport&eacute;es &agrave; notre
          politique vid&eacute;o. Ojiis se r&eacute;serve le droit de modifier
          cette politique vid&eacute;o &agrave; tout moment et pour quelque
          raison que ce soit.
        </span>
      </p>
      <p className="c1">
        <span className="c3 c11">COORDONN&Eacute;ES</span>
      </p>
      <p className="c1">
        <span className="c13 c3">
          Si vous avez des questions, n&#39;h&eacute;sitez pas &agrave; nous
          contacter via support@ojiis.com.
        </span>
      </p>
      <p className="c1 c8">
        <span className="c11 c3"></span>
      </p>
      <p className="c1 c8">
        <span className="c11 c3"></span>
      </p>
      <p className="c1 c8">
        <span className="c6 c16"></span>
      </p>
      <p className="c1 c8">
        <span className="c4"></span>
      </p>
    </div>
  );
};

import { useContext, useEffect } from "react";
import { LANG_ABREVIATION } from "../../../constants/language";
import { translations } from "../../../translations/texts";
import Header from "../../Header/Header";
import "./TOS.scss";
import { TOS_DE } from "./TOS_DE";
import { TOS_EN } from "./TOS_EN";
import { TOS_FR } from "./TOS_FR";
import { TOS_SP } from "./TOS_SP";
import { CombinedContext } from "../../context/combinedContext";

export const TOS = () => {
  const { language } = useContext(CombinedContext)

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getContent = () => {
    switch (language.toUpperCase()) {
      case LANG_ABREVIATION.EN: {
        return <TOS_EN />;
      }

      case LANG_ABREVIATION.FR: {
        return <TOS_FR />;
      }

      case LANG_ABREVIATION.DE: {
        return <TOS_DE />;
      }

      case LANG_ABREVIATION.SP: {
        return <TOS_SP />;
      }

      default: {
        return <TOS_EN />;
      }
    }
  };

  return (
    <div className="tos">
      <Header nonHome />
      <section>
        <h1>
          <i>{translations[language].footer.tos}</i>
        </h1>
        {getContent()}
      </section>
    </div>
  );
};

export default TOS;

export const VideoPolicyDE = () => {
  return (
    <div>
      <p className="c0">
        <span className="c3">
          As used in this Video Policy, &ldquo;Ojiis&rdquo;, &ldquo;we&rdquo;,
          &ldquo;us&rdquo; &ldquo;our&rdquo;, or &ldquo;ours&rdquo; refers to
          Ojiis and all of its subsidiaries and affiliates.
        </span>
      </p>
      <p className="c0">
        <span className="c3">
          This Video Policy is part of and incorporated by reference into the
          Terms of Service governing the use of all &ldquo;Ojiis&rdquo; branded
          software applications (the &ldquo;Application/s&rdquo;), which may
          operate on any type of computing device (including without limitation,
          a mobile device), and applies to your use of the Applications and any
          other websites and/or URL which may replace it or which may be added
          to the list of websites published by us and linking to this Video
          Policy (the &ldquo;Website/s&rdquo;). Furthermore, and notwithstanding
          anything to the contrary stated herein, this Video Policy also governs
          the use of all Ojiis video content published on Social Media Accounts
          (including but not limited to Tik Tok, Facebook, Twitter, Instagram,
          and Pinterest) and YouTube channels.
        </span>
      </p>
      <p className="c0">
        <span className="c3">
          By using the Applications, the Websites, Social Media Accounts or
          YouTube channels, as defined above, you agree to and wish to be bound
          by the terms of this Video Policy. You are allowed to create videos
          (&ldquo;Video(s)&rdquo;), using our applications, video images,
          footage, music, sounds, speech, or any other assets from Ojiis
          (&ldquo;Ojiis Content&rdquo;), subject to the following limitations
          and rules:
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">NON-COMMERCIAL USE.</span>
        <span className="c3">
          &nbsp;You are not allowed to charge any kind of fees from users to
          view or access your Videos. Unless you obtain a prior written approval
          from Ojiis, you are also not allowed to sell or license your Videos
          for a payment of any kind. Ojiis reserves the right to issue a
          monetization claim on Videos featuring Ojiis Content.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">LIMITED USE.</span>
        <span className="c3">
          &nbsp;Only a limited use of Ojiis Content is permitted in your Videos.
          Notwithstanding anything to the contrary stated herein, aforementioned
          limitation does not apply to Videos showing activities from Ojiis
          genuine applications as defined herein below.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">NO DUPLICATING.</span>
        <span className="c3">
          &nbsp;This Video Policy is intended for Videos that include creative
          spin on Ojiis Content, hence simple duplication of Ojiis assets is not
          in line with this Video Policy and Ojiis reserves the right to remove
          or monetize (in whole or in part) any Videos that are reproducing
          Ojiis Content.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">BE APPROPRIATE.</span>
        <span className="c3">
          &nbsp;You are not allowed to create and post Videos that contain
          nudity, sexually explicit content, violent or graphic content,
          hateful, harmful, or dangerous content, offensive content, brand
          damaging content or any other inappropriate content that makes untrue,
          dishonest, disparaging sensational or libelous statements about Ojiis
          and/or its products, employees, and agents.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">ORIGINAL CONTENT ONLY.</span>
        <span className="c3">
          &nbsp;You are permitted to create and post Videos showing activity
          from Ojiis&rsquo; genuine applications. Keep in mind that there may be
          fraudulent online applications using Ojiis Content and Ojiis will
          unconditionally request to remove any such Videos infringing
          Ojiis&rsquo; copyright material.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">NO CHEATING OR HACKING.</span>
        <span className="c3">
          &nbsp;You are not allowed to create, post, and promote Videos that
          explain cheating and/or hacking into Ojiis applications.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">MAKE IT CLEAR IT IS A FAN PRODUCT.</span>
        <span className="c3">
          &nbsp;You are not allowed to create and post Videos under the
          impression that Ojiis is a creator or sponsor of your Video or in a
          way that the audience might understand that such Video is an official
          Ojiis&rsquo; release.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">RESPECT CREATIVE OWNERSHIP.</span>
        <span className="c3">
          &nbsp;Aside from Ojiis content do not use any third-party creative
          materials in your Videos unless you have obtained their prior written
          approval or purchased appropriate license from such third party. Ojiis
          is not responsible for any copyright claims raised from such third
          parties.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">NO OFFLINE DOWNLOAD.</span>
        <span className="c3">
          &nbsp;You are not permitted to download any official Ojiis Videos from
          any online platform (including but not limited to YouTube, Youku,
          Vimeo, and Facebook Live) and disseminate simple duplication(s) of
          such Videos on another online platform, make any derivative works of
          such Videos and/or make them available for offline download.
        </span>
      </p>
      <p className="c0 c1">
        <span className="c4">NO MISLEADING DATA.</span>
        <span className="c15">
          &nbsp;You cannot use misleading and/or unrelated metadata (including
          but not limited to titles, descriptions, tags), which include
          Ojiis&rsquo; trademarks in published Videos.
        </span>
      </p>
      <p>
        <span className="c4">THUMBNAILS.</span>
        <span className="c15">
          &nbsp;You are not permitted to replicate motives used in Ojiis&rsquo;
          original YouTube thumbnails and/or use Ojiis&rsquo; official
          promo/social graphics for thumbnails.
        </span>
      </p>
      <p className="c8">
        <span className="c3">
          Ojiis reserves the right to determine in its sole discretion, which
          Videos are in accordance with the above-mentioned limitations and
          rules. Failure to comply with this Video Policy may result in Ojiis
          taking action to have your Video removed without any notice and
          liability to you.
        </span>
      </p>
      <p className="c8">
        <span className="c6 c4">CHANGES TO OUR VIDEO POLICY</span>
      </p>
      <p className="c8">
        <span className="c15 c17">Please check </span>
        <span className="c17 c15 c18">https://ojiis.com/video-policy</span>
        <span className="c15 c16">
          &nbsp;regularly for changes to our Video Policy. Ojiis reserves the
          right to change this Video Policy at any time and for any reason.
        </span>
      </p>
      <p className="c8">
        <span className="c4 c6">CONTACT INFORMATION</span>
      </p>
      <p className="c8">
        <span className="c16 c15">
          In case you have any questions feel free to contact us via
          support@ojiis.com.
        </span>
      </p>
      <p className="c13 c12">
        <span className="c6 c4"></span>
      </p>
      <p className="c12 c13">
        <span className="c6 c4"></span>
      </p>
      <p className="c13 c12">
        <span className="c6 c9"></span>
      </p>
      <p className="c13 c12">
        <span className="c6 c14"></span>
      </p>
    </div>
  );
};

export const homepage = {
  section_1_line_1: "EINE NEUE GENERATION",
  section_1_line_2: "VON EMOJIS",
  section_1_line_3: "Sie ziehen um. Sie sprechen. Sie sind lustig.",
  section_1_line_4: "Sie sind in 3D. Sie sind am Leben!",
  section_1_line_5: "SIE SIND DAS NÄCHSTE LEVEL VON EMOJIS",
  section_2_line_1: "MILLIONEN PERSONALISIERTER OPTIONEN",
  section_2_line_2: "FÜR IHRE OJIIS",
  section_2_line_3: "Es gibt ein Ojii für alles.",
  section_2_line_4: "Passen Sie es an und drücken Sie sich aus wie nie zuvor!",
  section_2_line_5: "Lassen Sie Ihrer Kreativität freien Lauf!",
  section_3_line_1: "BRINGEN SIE ES AUF DIE NÄCHSTE",
  section_3_line_2: "LEVEL MIT AR",
  section_3_line_3: "Bringen Sie sie mit Augmented Reality in Ihre Welt",
  section_4_line_1: "VOLLSTÄNDIG ANPASSBARE",
  section_4_line_2: "AUFKLEBER",
  section_4_line_3: "Wähle einen Rahmen aus deiner Ojii- Animation",
  section_4_line_4: "um deinen eigenen Sticker zu erstellen!",
  section_5_line_1: "INNOVATIVER",
  section_5_line_2: "GESCHICHTENSCHÖPFER",
  section_5_line_3:
    "Wählen Sie Ihre Ojiis aus und erstellen Sie Ihre eigenen personalisierten Geschichten.",
  section_6_line_1: "KEINE",
  section_6_line_2: "WERBUNG",
  section_6_line_3: "Tanken Sie Ihre Kreativität ohne Unterbrechung!",
  section_7_line_1: "GEBEN SIE IHREM",
  section_7_line_2: "OJII EINE STIMME",
  section_7_line_3: "Fügen Sie Ihre persönliche Note hinzu!",
  section_7_line_4:
    "Nehmen Sie Ihre Stimme auf, fügen Sie Ihre Musik hinzu oder schreiben Sie darauf!",
  section_8_line_1: "VERWANDELN SIE IHRE",
  section_8_line_2: "OJIIS IN TIERE",
  section_8_line_3: "Bring das Tier in dir zum Vorschein!",
  section_8_line_4: "Verwandle die Ojiis in dein Lieblingstier.",
  section_9_line_1: "ERSTELLEN SIE VIDEOS",
  section_9_line_2: "MIT SYLISCHEN SZENEN",
  section_9_line_3:
    "Erstellen Sie einzigartige Videos mit coolen Hintergründen",
  section_9_line_4: "oder bringen Sie Ihre eigenen in Szene.",
  section_10_line_1: "SCHALTE ALLES FREI",
  section_10_line_2: "UM LUSTIGE UND EINZIGARTIGE BEITRÄGE ZU ERSTELLEN",
  section_10_line_3: "Personalisieren Sie Ihre Kommunikation wie nie zuvor.",
  section_10_line_4: "Sag es nicht. Ojii es !",
  media_gallery: "MEDIEN GALLERIE",
};

export const TOS_SP = () => {
  return (
    <div>
      <p className="c3">
        <span className="c0">Fecha de vigencia: 01/12/2019</span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Los t&eacute;rminos de este acuerdo ("Términos de Uso") rigen la relaci&oacute;n entre usted y Ojiis (que,
          junto con sus subsidiarias y afiliadas, se denominan en el presente
          &quot;Ojiis&quot; o &quot;nosotros&quot;), con respecto a su uso de la
          aplicaci&oacute;n distribuida por Ojiis que usted instal&oacute;,
          as&iacute; como todas y cada una de las actualizaciones y
          actualizaciones relacionadas que reemplazan o complementan la
          aplicaci&oacute;n en cualquier aspecto (la
          &quot;Aplicaci&oacute;n&quot; o &quot;Aplicaci&oacute;n&quot;),
          regir&aacute;n la licencia.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3" id="h.gjdgxs">
        <span>
          La recopilaci&oacute;n, el uso, a menos que dicha actualizaci&oacute;n
          o actualizaci&oacute;n vaya acompa&ntilde;ada de una licencia por
          separado, en cuyo caso la separaci&oacute;n y divulgaci&oacute;n de Su
          informaci&oacute;n (incluida la informaci&oacute;n personal) se rige
          por la Pol&iacute;tica de privacidad de Ojiis, que se encuentra en
          l&iacute;nea en https://ojiis{" "}
        </span>
        <span className="c7">. com/privacy-policy</span>
        <span className="c0">
          &nbsp;(&quot;Pol&iacute;tica de privacidad&quot;).
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span>
          El uso de la Aplicaci&oacute;n tambi&eacute;n se rige por la
          Pol&iacute;tica de video de Ojiis, que se encuentra en l&iacute;nea en{" "}
        </span>
        <span className="c7">https://ojiis.com/video-policy </span>
        <span className="c0">
          (&quot;Pol&iacute;tica de video&quot;), que se incorpora al Acuerdo
          mediante esta referencia.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Si descarga la Aplicaci&oacute;n de cualquier mercado en l&iacute;nea
          para aplicaciones de software que funcionan en dispositivos que
          funcionan con el sistema operativo (por ejemplo, Apple App Store y
          Google Play, o si juega la Aplicaci&oacute;n en l&iacute;nea (cuando
          est&eacute; disponible), reconoce que tiene revis&oacute; y
          acept&oacute; los t&eacute;rminos de servicio aplicables a dicho
          mercado, plataforma o sistema operativo en l&iacute;nea
          (&quot;T&eacute;rminos especiales&quot;).
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          AL INSTALAR, UTILIZAR O ACCEDER AL SERVICIO, USTED ACEPTA ESTOS
          T&Eacute;RMINOS DEL SERVICIO. SI NO ACEPTA ESTOS T&Eacute;RMINOS DE
          SERVICIO, NO INSTALE, USE NI ACCEDA DE CUALQUIER OTRO MODO AL
          SERVICIO. EL USO DEL SERVICIO ES NULO DONDE EST&Eacute; PROHIBIDO.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Las principales caracter&iacute;sticas de cada Aplicaci&oacute;n se
          pueden encontrar en las descripciones de las tiendas de aplicaciones.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">1. LICENCIA</span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c4">
          1.1 Otorgamiento de una Licencia Limitada para Usar el Servicio
        </span>
      </p>
      <p className="c3">
        <span className="c0">
          Mediante el uso de la Aplicaci&oacute;n, usted adquiere y Ojiis le
          otorga una licencia personal, revocable y limitada, no exclusiva,
          intransferible y no sublicenciable para acceder y utilizar el Servicio
          para sus propios fines de entretenimiento no comercial. Usted acepta
          no utilizar el Servicio para ning&uacute;n otro prop&oacute;sito.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">1.2 LIMITACIONES DE LA LICENCIA</span>
      </p>
      <p className="c3">
        <span className="c0">
          Cualquier uso de la Aplicaci&oacute;n en violaci&oacute;n de estas
          Limitaciones de licencia est&aacute; estrictamente prohibido y puede
          resultar en la revocaci&oacute;n inmediata de Su Licencia limitada y
          puede generar Su responsabilidad por violaciones de la ley.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          A menos que haya recibido una autorizaci&oacute;n previa por escrito
          de Ojiis, acepta no:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c6 lst-kix_list_3-0 start">
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar trucos, software de automatizaci&oacute;n, bots, hacks o
            cualquier otro software no autorizado dise&ntilde;ado para modificar
            o interferir con la Aplicaci&oacute;n y/o cualquier archivo que
            forme parte de la Aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            explotar comercialmente la Aplicaci&oacute;n Ojiis, sus Personajes,
            Dise&ntilde;o, modelos 2D o 3D, Ilustraciones o cualquier otra parte
            de la Aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            crear Obras Derivadas de la Aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            acceder o utilizar la Aplicaci&oacute;n con cualquier
            tecnolog&iacute;a o medio distinto a los provistos en la
            Aplicaci&oacute;n, o a trav&eacute;s de otros medios
            expl&iacute;citamente autorizados que Ojiis pueda designar;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            hacer una copia de la Aplicaci&oacute;n disponible en una red donde
            pueda ser utilizada por m&uacute;ltiples usuarios;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            registrar nombres de dominio, cuentas de redes sociales o
            direcciones relacionadas que incluyan marcas registradas de Ojiis;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar la Aplicaci&oacute;n o los activos relacionados y/o las marcas
            comerciales de Ojiis en o en relaci&oacute;n con contenido que ( i )
            promueva trampas, pirater&iacute;a inform&aacute;tica, violencia,
            discriminaci&oacute;n, temas inapropiados, actividades ilegales o
            contenido sexualmente expl&iacute;cito; (ii) hace declaraciones
            falsas, deshonestas, denigrantes o difamatorias sobre Ojiis y/o sus
            productos, empleados y agentes; y/o (iii) contiene otro contenido
            objetable;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            revender, copiar, transferir, distribuir, exhibir, traducir o
            modificar la Aplicaci&oacute;n o hacer trabajos derivados de la
            Aplicaci&oacute;n o cualquier parte de la misma;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            reutilizar, copiar o distribuir texto, im&aacute;genes,
            m&uacute;sica, c&oacute;digos de barras, videos, datos,
            hiperv&iacute;nculos, visualizaciones o cualquier otro contenido
            proporcionado por la Aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            redistribuir, sublicenciar, alquilar, publicar, realizar, vender,
            ceder, arrendar, comercializar, transferir o poner la
            Aplicaci&oacute;n a disposici&oacute;n de terceros;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            intente deshabilitar, realizar ingenier&iacute;a inversa,
            descompilar o intentar extraer el c&oacute;digo fuente de la
            Aplicaci&oacute;n o cualquier parte de la misma, a menos que la ley
            aplicable o los t&eacute;rminos de licencia que rigen el uso de
            cualquier componente de c&oacute;digo abierto incluido dentro lo
            permitan o exijan expresamente. la aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            eliminar, ocultar o alterar de cualquier manera cualquier
            advertencia, aviso (incluido, entre otros, cualquier aviso de
            derechos de autor u otros derechos de propiedad) o enlace que
            aparezca en la Aplicaci&oacute;n;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar la Aplicaci&oacute;n cuando opere veh&iacute;culos;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            eliminar o alterar las marcas comerciales o los logotipos de Ojiis o
            los avisos legales incluidos en la Aplicaci&oacute;n o los activos
            relacionados;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar el servicio para intentar obtener acceso no autorizado a
            cualquier servicio, datos, cuenta o red por cualquier medio;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            publicar cualquier informaci&oacute;n que contenga desnudos,
            violencia o temas ofensivos o que contenga un enlace a dicho
            contenido;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            intente acosar, abusar, amenazar, difamar o infringir o violar los
            derechos de cualquier otra parte;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            utilizar la Aplicaci&oacute;n de forma ilegal, fraudulenta o
            enga&ntilde;osa;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar tecnolog&iacute;a o cualquier otro medio para acceder a la
            informaci&oacute;n de propiedad de Ojiis no autorizada por Ojiis;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            usar o iniciar cualquier sistema automatizado para acceder al sitio
            web o sistemas inform&aacute;ticos de Ojiis;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            intentar introducir virus o cualquier otro c&oacute;digo
            inform&aacute;tico malicioso que interrumpa, destruya o limite la
            funcionalidad de cualquier software, hardware o equipo de
            telecomunicaciones;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            intentar obtener acceso no autorizado a la red inform&aacute;tica o
            cuentas de usuario de Ojiis;
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            fomentar conductas que constituir&iacute;an un delito penal o
            dar&iacute;an lugar a responsabilidad civil
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Ojiis se reserva el derecho de determinar, a su exclusivo criterio,
          qu&eacute; tipo de conducta se considera que viola estos
          T&eacute;rminos de servicio. Adem&aacute;s, Ojiis se reserva el
          derecho de tomar las medidas apropiadas como resultado de su
          violaci&oacute;n de los t&eacute;rminos de estos T&eacute;rminos de
          servicio, lo que incluye, entre otros, prohibirle el uso de la
          aplicaci&oacute;n Ojiis en su totalidad o en parte.
        </span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c4">1.3 USOS PERMITIDOS DE LA APLICACI&Oacute;N</span>
      </p>
      <p className="c3">
        <span className="c0">
          EL USO DE LA APLICACI&Oacute;N Y CUALQUIER INFORMACI&Oacute;N SUYA
          TRANSMITIDA EN RELACI&Oacute;N CON LA APLICACI&Oacute;N EST&Aacute;
          LIMITADO A LA FUNCIONALIDAD DE LA APLICACI&Oacute;N YA LA
          CONCESI&Oacute;N DE LICENCIA LIMITADA COMO SE DESCRIBE ANTERIORMENTE.
          USTED NO PUEDE UTILIZAR LA APLICACI&Oacute;N O CUALQUIER COMPONENTE DE
          LA MISMA, SALVO QUE OJIIS LO AUTORIZE EXPRESAMENTE POR ESCRITO CON
          ANTICIPACI&Oacute;N.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">
          2. ACCESO Y DESCARGA DE LA APLICACI&Oacute;N DESDE APP STORE DE APPLE
        </span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Lo siguiente se aplica a cualquier aplicaci&oacute;n a la que se
          acceda o se descargue de la tienda de aplicaciones de Apple
          (&quot;Aplicaci&oacute;n con origen en la tienda de
          aplicaciones&quot;):
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c1 li-bullet-0">
          <span className="c0">
            Usted reconoce y acepta que ( i ) estos T&eacute;rminos de servicio
            se celebran &uacute;nicamente entre usted y Ojiis, y no Apple; y
            (ii) Ojiis, no Apple, es el &uacute;nico responsable de la
            aplicaci&oacute;n con origen en la App Store y su contenido. Su uso
            de la Aplicaci&oacute;n con origen en la App Store debe cumplir con
            los T&eacute;rminos de servicio de la App Store de Apple.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Utilizar&aacute; la aplicaci&oacute;n con origen en la App Store
            &uacute;nicamente ( i ) en un producto de la marca Apple que ejecute
            iOS (software del sistema operativo propietario de Apple); y (ii)
            seg&uacute;n lo permitido por las &quot;Reglas de uso&quot;
            establecidas en los T&eacute;rminos de servicio de la App Store de
            Apple.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Usted reconoce que Apple no tiene ninguna obligaci&oacute;n de
            proporcionar ning&uacute;n servicio de mantenimiento y soporte con
            respecto a la Aplicaci&oacute;n con origen en la App Store.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            En caso de que cualquier Aplicaci&oacute;n con origen en la App
            Store no cumpla con cualquier garant&iacute;a aplicable, puede
            notificar a Apple, y Apple le reembolsar&aacute; el precio de compra
            de la Aplicaci&oacute;n con origen en la App Store y, en la medida
            m&aacute;xima permitida por la ley aplicable, Apple no tendr&aacute;
            ninguna otra obligaci&oacute;n de garant&iacute;a con respecto a la
            Aplicaci&oacute;n con origen en la App Store. Entre Ojiis y Apple,
            cualquier otro reclamo, p&eacute;rdida, responsabilidad,
            da&ntilde;o, costo o gasto atribuible a cualquier incumplimiento de
            cualquier garant&iacute;a ser&aacute; responsabilidad exclusiva de
            Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Usted y Ojiis reconocen que, entre Ojiis y Apple, Apple no es
            responsable de abordar ning&uacute;n reclamo que tenga o cualquier
            reclamo de un tercero relacionado con la aplicaci&oacute;n con
            origen en la tienda de aplicaciones en su posesi&oacute;n y el uso
            de la aplicaci&oacute;n con origen en la tienda de aplicaciones,
            incluidos, entre otros, sin limitarse a ( i ) reclamos de
            responsabilidad por productos; (ii) cualquier reclamo de que una
            aplicaci&oacute;n procedente de la tienda de aplicaciones no cumple
            con los requisitos legales o reglamentarios aplicables; y (iii)
            reclamaciones derivadas de la protecci&oacute;n del consumidor o
            legislaci&oacute;n similar.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Usted y Ojiis reconocen que, en caso de que un tercero reclame que
            una aplicaci&oacute;n de origen de la tienda de aplicaciones o su
            posesi&oacute;n y uso de esa aplicaci&oacute;n de origen de la
            tienda de aplicaciones infringe los derechos de propiedad
            intelectual de ese tercero, entre Ojiis y Apple, Ojiis, no Apple ,
            ser&aacute; el &uacute;nico responsable de la investigaci&oacute;n,
            defensa, resoluci&oacute;n y descarga de cualquier reclamo de
            infracci&oacute;n de propiedad intelectual en la medida requerida
            por estos T&eacute;rminos de servicio.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Al utilizar la aplicaci&oacute;n con origen en la tienda de
            aplicaciones, usted declara y garantiza que ( i ) no se encuentra en
            un pa&iacute;s que est&eacute; sujeto a un embargo del gobierno de
            los EE. UU., o que haya sido designado por el gobierno de los EE.
            UU. como un pa&iacute;s de &quot;apoyo al terrorismo&quot;; y (ii)
            no figura en ninguna lista del Gobierno de EE. UU. de partes
            prohibidas o restringidas.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Sin limitar ning&uacute;n otro t&eacute;rmino de estos
            T&eacute;rminos de servicio, debe cumplir con todos los
            t&eacute;rminos de acuerdo de terceros aplicables al usar la
            Aplicaci&oacute;n con origen en la App Store.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">3. CONTENIDO DEL USUARIO</span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Sin perjuicio de cualquier disposici&oacute;n en contrario establecida
          en estos T&eacute;rminos de servicio, se le permite crear y utilizar
          &quot;Contenido de usuario&quot; (como se define a
          continuaci&oacute;n) sujeto a las Limitaciones de licencia y las
          Reglas espec&iacute;ficas para el Contenido de usuario definido en
          este documento.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Cualquier modificaci&oacute;n del Contenido de usuario creado por la
          Aplicaci&oacute;n o la incorporaci&oacute;n de dicho Contenido de
          usuario en otros trabajos requiere la aprobaci&oacute;n
          expl&iacute;cita por escrito de Ojiis. Se le proh&iacute;be
          expresamente sublicenciar, alquilar, arrendar, transferir o distribuir
          de otro modo la Aplicaci&oacute;n o los derechos de uso de la
          Aplicaci&oacute;n. Estos T&eacute;rminos de servicio no le dan derecho
          a recibir, y no obligan a Ojiis a proporcionar, documentaci&oacute;n
          impresa, soporte, asistencia telef&oacute;nica o mejoras o
          actualizaciones de la Aplicaci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">3.1 ENV&Iacute;OS DE CONTENIDO DE USUARIO</span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          La Aplicaci&oacute;n puede permitirle crear contenido como videos,
          datos, fotograf&iacute;as, mensajes, gr&aacute;ficos, texto y otra
          informaci&oacute;n (&quot;Contenido de usuario&quot;), y compartir
          dicho Contenido de usuario con Ojiis o con otros sitios, incluidos los
          sitios de redes sociales, como usted puede designar. Usted es el
          &uacute;nico responsable de su Contenido de usuario que cargue,
          publique, muestre, enlace o ponga a disposici&oacute;n (en adelante,
          &quot;compartir&quot;) en o a trav&eacute;s de la Aplicaci&oacute;n, y
          acepta que Ojiis solo act&uacute;a como un conducto pasivo para su
          distribuci&oacute;n y publicaci&oacute;n en l&iacute;nea de su
          contenido de usuario.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          La Aplicaci&oacute;n puede permitir compartir el Contenido del usuario
          de dos maneras: ( i ) uno a uno (por ejemplo, a trav&eacute;s de SMS,
          correo electr&oacute;nico, WeChat, Facebook y otras plataformas de
          mensajer&iacute;a); y (ii) uno a muchos (p. ej., Tik Tok, Facebook,
          YouTube, Twitter, Everyplay , correo electr&oacute;nico). A menos que
          se indique expl&iacute;citamente lo contrario en este documento, Ojiis
          no almacena ning&uacute;n Contenido de usuario y Ojiis solo
          act&uacute;a como un conducto pasivo, ofreci&eacute;ndole una
          plataforma para crear Contenido de usuario y (&uacute;nicamente para
          ayudarlo y en aras de mejorar la experiencia del usuario)
          refiri&eacute;ndolo a un tercero. -Servicio de fiesta (p. ej., cliente
          de correo electr&oacute;nico, aplicaci&oacute;n de Facebook, Twitter o
          YouTube) en el que puede decidir qu&eacute; Contenido de usuario
          compartir, c&oacute;mo, cu&aacute;ndo y con qui&eacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted comprende que, a menos que se indique expl&iacute;citamente lo
          contrario en este documento, Ojiis no tiene control sobre el acceso a
          Su Contenido de usuario, el intercambio de Su Contenido de usuario, la
          privacidad o confidencialidad de Su Contenido de usuario y que Usted,
          junto con los proveedores de servicios de terceros (por ejemplo,
          Facebook, YouTube, Twitter), est&aacute;n sujetos a los
          t&eacute;rminos de servicio de dichos proveedores de servicios
          externos y tienen el control exclusivo sobre dicho Contenido de
          usuario.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted acepta no compartir Contenido de usuario que: ( i ) pueda crear
          un riesgo de da&ntilde;o, p&eacute;rdida, lesi&oacute;n f&iacute;sica
          o mental, angustia emocional, muerte, discapacidad,
          desfiguraci&oacute;n o enfermedad f&iacute;sica o mental para usted,
          para cualquier otra persona o para cualquier animal; (ii) puede crear
          un riesgo de cualquier otra p&eacute;rdida o da&ntilde;o a cualquier
          persona o propiedad; (iii) busque da&ntilde;ar o explotar a los
          ni&ntilde;os exponi&eacute;ndolos a contenido inapropiado,
          pidi&eacute;ndoles detalles de identificaci&oacute;n personal, o de
          otra manera; (iv) puede constituir o contribuir a un delito o agravio;
          (v) contiene cualquier informaci&oacute;n o contenido que Ojiis
          considere ilegal, da&ntilde;ino, abusivo, racial o &eacute;tnicamente
          ofensivo, difamatorio, infractor, invasivo de la privacidad personal o
          los derechos de publicidad, acosador, humillante para otras personas
          (p&uacute;blicamente o de otra manera), calumnioso, amenazante,
          profano o de otra manera objetable; (vi) contiene cualquier
          informaci&oacute;n o contenido que sea ilegal (incluyendo, sin
          limitaci&oacute;n, la divulgaci&oacute;n de informaci&oacute;n
          privilegiada bajo la ley de valores o de secretos comerciales de
          terceros); (vii) contiene cualquier informaci&oacute;n o contenido que
          no tiene derecho a poner a disposici&oacute;n bajo ninguna ley o bajo
          relaciones contractuales o fiduciarias; o (viii) contiene cualquier
          informaci&oacute;n o contenido que Usted sabe que no es correcto y
          actual.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Si cree que cierto Contenido de usuario compartido a trav&eacute;s de
          la Aplicaci&oacute;n, pero almacenado en servicios de terceros (p.
          ej., Facebook, YouTube, etc.), est&aacute; infringiendo Sus derechos,
          comun&iacute;quese con el proveedor de servicios externo respectivo
          donde se almacena dicho Contenido de usuario. .
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted acepta que cualquier Contenido de usuario que comparta no viola
          ni violar&aacute; los derechos de terceros de ning&uacute;n tipo,
          incluidos, entre otros, los &quot;Derechos de propiedad
          intelectual&quot; (como se define en la Secci&oacute;n 7 del presente)
          o los derechos de publicidad y privacidad. En la medida en que Su
          Contenido de usuario contenga m&uacute;sica, por la presente declara
          que es el propietario de todos los derechos de autor, incluidos, entre
          otros, los derechos de ejecuci&oacute;n y de grabaci&oacute;n
          mec&aacute;nica y de sonido, con respecto a todas y cada una de las
          composiciones musicales (incluidas las letras ) y la grabaci&oacute;n
          de sonido contenida en dicho Contenido de usuario y que tiene la
          facultad de otorgar la licencia otorgada a continuaci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span>
          CREAR Y COMPARTIR CONTENIDO DE USUARIO COMO SE DESCRIBE EN ESTA
          SECCI&Oacute;N NO EST&Aacute; HABILITADO PARA USUARIOS QUE SE
          IDENTIFICAN COMO MENORES DE 13 A&Ntilde;OS. M&Aacute;S
          INFORMACI&Oacute;N SOBRE LA RECOPILACI&Oacute;N DE INFORMACI&Oacute;N
          RELACIONADA CON NI&Ntilde;OS MENORES DE 13 A&Ntilde;OS SE DESCRIBE EN
          NUESTRA POL&Iacute;TICA DE PRIVACIDAD, DISPONIBLE EN{" "}
        </span>
        <span className="c5">https://ojiis.com/privacy-policy </span>
        <span className="c7">.</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">
          3.2 CONCESI&Oacute;N DE LICENCIA PARA EL CONTENIDO DEL USUARIO
        </span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Al crear Contenido de usuario a trav&eacute;s de las Aplicaciones de
          Ojiis, usted otorga expresamente, declara y garantiza que tiene
          derecho a otorgar a Ojiis una licencia mundial libre de
          regal&iacute;as, sublicenciable, transferible, perpetua, irrevocable,
          no exclusiva, para usar, reproducir, modificar, publicar, monetizar,
          enumerar informaci&oacute;n relacionada con, editar, traducir,
          distribuir, realizar p&uacute;blicamente, exhibir p&uacute;blicamente
          y hacer trabajos derivados y explotar de otro modo todo el Contenido
          de usuario, as&iacute; como su nombre, nombre de usuario, voz, y/o
          semejanza contenida en Su Contenido de usuario, en su totalidad o en
          parte, y en cualquier forma, medio o tecnolog&iacute;a, ya sea que se
          conozca ahora o se desarrolle en el futuro, para su uso en
          relaci&oacute;n con la Aplicaci&oacute;n y Ojiis (y sus sucesores y
          afiliados) &#39;) negocio, que incluye, entre otros, la
          promoci&oacute;n y redistribuci&oacute;n de parte o la totalidad de la
          Aplicaci&oacute;n (y cualquier trabajo derivado de la misma) o
          cualquier Aplicaci&oacute;n de Ojiis en cualquier formato de medios y
          a trav&eacute;s de cualquier colecci&oacute;n de medios y en
          relaci&oacute;n con varios anuncios, como anuncios publicitarios,
          anuncios para m&oacute;viles y anuncios de v&iacute;deo. Por la
          presente, tambi&eacute;n otorga a cada usuario de la Aplicaci&oacute;n
          una licencia no exclusiva para acceder a Su Contenido de usuario a
          trav&eacute;s de la Aplicaci&oacute;n, y para usar, reproducir,
          distribuir, mostrar y realizar dicho Contenido de usuario seg&uacute;n
          lo permita la funcionalidad de la Aplicaci&oacute;n o Internet y bajo
          este acuerdo.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">
          3.3 REGLAS ESPEC&Iacute;FICAS PARA EL CONTENIDO DEL USUARIO
        </span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Al crear Contenido de usuario a trav&eacute;s de las Aplicaciones de
          Ojiis, usted acepta estas Reglas espec&iacute;ficas que describen Su
          uso de los Derechos de propiedad intelectual de Ojiis:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c6 lst-kix_list_1-0 start">
        <li className="c1 li-bullet-0">
          <span className="c0">
            El Contenido del usuario se utilizar&aacute; con el &uacute;nico
            prop&oacute;sito de mostrar, identificar y analizar las Aplicaciones
            de Ojiis sin fines comerciales de acuerdo con estas Reglas
            espec&iacute;ficas. No se le permite crear nuevas aplicaciones,
            productos y/o contenido basado en los derechos de propiedad
            intelectual de Ojiis, incluso si dicho contenido fuera gratuito.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            No se le permite cobrar ning&uacute;n tipo de tarifa a los clientes
            o visitantes de su Contenido de usuario, a menos que obtenga la
            aprobaci&oacute;n previa por escrito de Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Ojiis, a su exclusivo criterio, se reserva el derecho de monetizar o
            utilizar el Contenido del usuario o sus partes creadas a
            trav&eacute;s de las Aplicaciones de Ojiis. En la medida en que
            Ojiis no haga uso de su derecho a monetizar el Contenido de usuario,
            puede monetizar Su Contenido de usuario mediante el uso de anuncios;
            sin embargo, Usted es el &uacute;nico responsable de garantizar que
            dicho anuncio cumpla con todas y cada una de las leyes, normas,
            reglamentos y disposiciones aplicables. pol&iacute;ticas del
            desarrollador.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            No debe crear ni utilizar el Contenido de usuario bajo la
            impresi&oacute;n de que Ojiis es un creador o patrocinador de Su
            Contenido de usuario. Adem&aacute;s, tampoco se le permite crear
            ning&uacute;n material a partir de los Derechos de propiedad
            intelectual de Ojiis que sea similar a los logotipos, marcas
            comerciales o cualquier otro elemento de los Derechos de propiedad
            intelectual de Ojiis. No se le permite modificar de ninguna manera
            los Derechos de propiedad intelectual de Ojiis sin la
            aprobaci&oacute;n previa y expresa por escrito de Ojiis.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            A menos que haya obtenido la aprobaci&oacute;n previa por escrito de
            Ojiis, no puede fabricar ni distribuir (gratis o de otro modo)
            ning&uacute;n elemento f&iacute;sico que tenga los derechos de
            propiedad intelectual de Ojiis.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Ojiis se reserva el derecho de determinar, a su exclusivo criterio,
          qu&eacute; Contenido de usuario est&aacute; de acuerdo con las Reglas
          espec&iacute;ficas mencionadas anteriormente y puede revocar la
          Licencia limitada en estos T&eacute;rminos de servicio en cualquier
          momento y por cualquier motivo.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">
          3.4 LIMITACI&Oacute;N DE RESPONSABILIDAD POR EL CONTENIDO DEL USUARIO
        </span>
      </p>
      <p className="c3">
        <span className="c0">
          Ojiis no asume ninguna responsabilidad por ning&uacute;n Contenido de
          usuario que usted o cualquier otro usuario o tercero cree con la
          Aplicaci&oacute;n o comparta a trav&eacute;s de la Aplicaci&oacute;n.
          Usted ser&aacute; el &uacute;nico responsable de su propio Contenido
          de usuario y de las consecuencias de compartirlo o publicarlo. Usted
          comprende y acepta que cualquier p&eacute;rdida o da&ntilde;o de
          cualquier tipo que ocurra como resultado del uso de cualquier
          Contenido de usuario que env&iacute;e, cargue, descargue, transmita,
          publique, transmita, muestre, comparta o de otra manera ponga a
          disposici&oacute;n o acceda a trav&eacute;s de su el uso de la
          Aplicaci&oacute;n es &uacute;nicamente su responsabilidad. En
          relaci&oacute;n con Su Contenido de Usuario y adem&aacute;s de
          cualquier otra representaci&oacute;n y garant&iacute;a, Usted afirma,
          declara y garantiza lo siguiente:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c0">
            Su Contenido de usuario y el uso de Ojiis del mismo y de la
            Aplicaci&oacute;n no infringir&aacute;n ning&uacute;n derecho de
            terceros, incluidos, entre otros, los derechos de propiedad
            intelectual, los derechos de privacidad y los derechos de
            publicidad.{" "}
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Ojiis puede ejercer los derechos sobre Su Contenido de usuario
            otorgados en virtud de este Acuerdo sin responsabilidad por el pago
            de tarifas gremiales, residuales, pagos, tarifas o regal&iacute;as
            pagaderos en virtud de cualquier acuerdo de negociaci&oacute;n
            colectiva o de otra manera.
          </span>
        </li>
        <li className="c1 li-bullet-0">
          <span className="c0">
            Ojiis no es responsable de ninguna exhibici&oacute;n p&uacute;blica
            o mal uso de su contenido de usuario. Usted comprende y reconoce que
            puede estar expuesto a Contenido de usuario que es inexacto,
            ofensivo, indecente, objetable o inapropiado para los ni&ntilde;os,
            y acepta que Ojiis no ser&aacute; responsable de los da&ntilde;os
            que alega haber incurrido como resultado de dicho Contenido de
            usuario. .
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">4. ACCESO</span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted debe proporcionar, a Su cargo, el equipo, las conexiones a
          Internet o los dispositivos m&oacute;viles y/o los planes de servicio
          para acceder y utilizar la Aplicaci&oacute;n. Ojiis no garantiza que
          la Aplicaci&oacute;n est&eacute; disponible en todas las ubicaciones
          geogr&aacute;ficas. Usted reconoce que cuando usa la
          Aplicaci&oacute;n, su proveedor de servicios inal&aacute;mbricos puede
          cobrarle tarifas por datos, mensajes y/u otro acceso
          inal&aacute;mbrico. Verifique con su proveedor para ver si existen
          cargos de este tipo que se le apliquen. Usted es el &uacute;nico
          responsable de cualquier costo en el que incurra para acceder a la
          Aplicaci&oacute;n desde su dispositivo m&oacute;vil y/o dispositivo de
          PC. Su derecho a usar la Aplicaci&oacute;n tambi&eacute;n se basa en
          Su cumplimiento de los t&eacute;rminos aplicables de los acuerdos que
          tiene con terceros cuando usa la Aplicaci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">5. COMPRAS DENTRO DE LA APLICACI&Oacute;N</span>
      </p>
      <p className="c3">
        <span className="c0">
          Detalles de suscripción y proceso de renovación
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">1. Condiciones de suscripción:</span>
      </p>
      <p className="c3">
        <span>
          Al acceder y utilizar nuestra aplicación Ojiis ("la App"), usted
          reconoce y acepta los siguientes términos de suscripción:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          1.1. Prueba gratuita: la aplicación ofrece una prueba gratuita de 3
          días para nuevos usuarios ("el Período De Prueba"). Durante el Período
          De Prueba, tendrá acceso a la gama completa de funciones disponibles
          en la aplicación. Al finalizar el Período De Prueba, la suscripción se
          convertirá automáticamente en una suscripción de paga, como se
          describe a continuación en la sección 2, a menos que se cancele la
          suscripción antes de que el Período De Prueba expire.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          1.2. Suscripción de paga: Al vencimiento del Período De Prueba, puede
          optar por suscribirse a la Aplicación de forma mensual o anual ("la
          Suscripción"). La suscripción le otorga acceso continuo a las
          funciones y al contenido de la aplicación durante el tiempo
          seleccionado. La tarifa de la suscripción en la aplicación es de $4,99
          por mes o $49,99 por año, según corresponda, y está sujeta a cambios a
          nuestra discreción.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          1.3. Todas las tarifas de Suscripción están en dólar USD y están
          sujetas a cambio y localización a la moneda local, lo cual será
          realizado por la tienda de aplicaciones desde la que se descargó la
          Aplicación, lo que significa que los precios pueden variar.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">2. Renovación de suscripción:</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          2.1. Renovación automática: Las suscripciones se renovarán
          automáticamente a menos que se cancelen dentro de las 24 horas
          anteriores al final del período actual. La renovación se realizará a
          la tarifa de suscripción actual, a menos que se notifique lo
          contrario.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          2.2. Período de facturación: La fecha de facturación de su suscripción
          dependerá de la duración que haya elegido. Para suscripciones
          mensuales, el período de facturación será cada 30 días a partir de la
          fecha de su suscripción inicial. Para suscripciones anuales, el
          período de facturación será cada 365 días a partir de la fecha de su
          suscripción inicial.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          2.3. Autorización de pago: Al suscribirse a la aplicación, nos
          autoriza a cobrar la tarifa de suscripción correspondiente al método
          de pago asociado con su ID de aplicación u otros métodos de pago
          autorizados vinculados a su cuenta de App Store.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          2.4. Cambios de precios: Nos reservamos el derecho de cambiar las
          tarifas de suscripción en cualquier momento, previa notificación
          razonable. Dicho aviso puede enviarse por correo electrónico o
          mostrarse dentro de la aplicación. Su uso continuo de la aplicación
          después de la fecha de entrada en vigencia del cambio de precio
          constituye a su aceptación de la nueva tarifa de suscripción.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">3. Cancelación y Devoluciones:</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          3.1. Cancelación de la suscripción: Puede cancelar su suscripción en
          cualquier momento administrando sus suscripciones a través de la
          configuración de su ID de la aplicación o siguiendo las instrucciones
          proporcionadas por la tienda de aplicaciones. Tenga en cuenta que
          cancelar su suscripción no le da derecho a un reembolso por cualquier
          parte no utilizada de la suscripción.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          3.2. Reembolsos: Salvo que lo exija la ley aplicable, todas las
          tarifas de suscripción no son reembolsables. En caso de rescisión o
          cancelación de su suscripción, no se le reembolsará ninguna parte no
          utilizada en el período de su suscripción.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">4. Información de contacto:</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
          Si tiene alguna pregunta o inquietud con respecto a su suscripción,
          renovación o cualquier otro asunto relacionado, comuníquese con
          nuestro equipo de soporte en support@ojiis.com.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      <p className="c3">
        <span className="c0">
        Para obtener información más detallada sobre cómo administrar las compras dentro de la aplicación, consulte nuestra Pregunta Frecuente disponible en https://ojiis.com/faq.  
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>

      

      <p className="c3">
        <span className="c4">6. PLAZO Y TERMINACI&Oacute;N</span>
      </p>
      <p className="c3">
        <span className="c0">
          El t&eacute;rmino de estos T&eacute;rminos de servicio
          comenzar&aacute; en la fecha en que instale o utilice la
          Aplicaci&oacute;n y finalizar&aacute; en la fecha en que Usted enajene
          la Aplicaci&oacute;n o en la fecha en que Ojiis rescinda estos
          T&eacute;rminos de servicio, lo que ocurra primero. Puede rescindir
          estos T&eacute;rminos de servicio desinstalando la Aplicaci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          La rescisi&oacute;n no limitar&aacute; ninguno de los otros derechos o
          recursos de Ojiis por ley o equidad. Si alguna de las plataformas
          deshabilita la capacidad de usar la Aplicaci&oacute;n en Su
          dispositivo de conformidad con Su acuerdo con dicha plataforma,
          tambi&eacute;n se cancelar&aacute;n todos los derechos de licencia
          asociados con Ojiis.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">7. RESERVA DE DERECHOS</span>
      </p>
      <p className="c3">
        <span className="c0">
          Ha obtenido una licencia para la Aplicaci&oacute;n y Sus derechos
          est&aacute;n sujetos a estos T&eacute;rminos de servicio. Salvo que se
          le haya otorgado una licencia expresa en este documento, Ojiis se
          reserva todos los derechos, t&iacute;tulos e intereses en la
          Aplicaci&oacute;n. Esta licencia est&aacute; limitada a los derechos
          de propiedad intelectual de Ojiis y no incluye ning&uacute;n derecho a
          otras patentes o propiedad intelectual.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          Ojiis retiene todos los derechos, t&iacute;tulos e intereses sobre los
          Derechos de propiedad intelectual de Ojiis, como se define a
          continuaci&oacute;n, est&eacute;n registrados o no, y todas las
          Aplicaciones de los mismos, excepto los derechos de autor de la
          tecnolog&iacute;a de terceros. El software Ojiis est&aacute; protegido
          por leyes y tratados aplicables en todo el mundo.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          A los efectos de estas Condiciones de servicio, &quot;Derechos de
          propiedad intelectual&quot; se refiere a todos los derechos de
          patente, nombres comerciales, derechos de propiedad, derechos de
          autor, t&iacute;tulos, c&oacute;digos inform&aacute;ticos, efectos
          audiovisuales, temas, personajes, nombres de personajes, historias,
          di&aacute;logos, escenarios. , obras de arte, efectos de sonido, obras
          musicales, derechos de trabajo de m&aacute;scaras, derechos morales,
          derechos de publicidad, marca registrada, imagen comercial y derechos
          de marca de servicio, fondo de comercio, derechos de secreto comercial
          y otros derechos de propiedad intelectual que puedan existir ahora o
          que puedan existir en el futuro, y todas las solicitudes y registros,
          renovaciones y extensiones de los mismos, bajo las leyes de cualquier
          estado, pa&iacute;s, territorio u otra jurisdicci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">8. RENUNCIA DE GARANT&Iacute;AS</span>
      </p>
      <p className="c3">
        <span className="c0">
          EN LA MEDIDA M&Aacute;XIMA QUE LO PERMITA LA LEY APLICABLE, LA
          APLICACI&Oacute;N SE LE PROPORCIONA &quot;TAL CUAL&quot;, CON TODOS
          LOS DEFECTOS, SIN GARANT&Iacute;A DE NING&Uacute;N TIPO, SIN
          GARANT&Iacute;AS DE RENDIMIENTO O GARANT&Iacute;AS DE NING&Uacute;N
          TIPO, Y SU USO ES BAJO SU PROPIO RIESGO. TODO EL RIESGO DE LA CALIDAD
          Y EL RENDIMIENTO SATISFACTORIOS RESIDE CON USTED. OJIIS Y LOS
          LICENCIANTES DE OJIIS (COLECTIVAMENTE &ldquo;OJIIS&rdquo; A LOS
          EFECTOS DE ESTA SECCI&Oacute;N Y DE LA SECCI&Oacute;N 10) NO OTORGAN,
          Y POR EL PRESENTE RENUNCIA, CUALQUIERA Y TODAS LAS GARANT&Iacute;AS
          EXPL&Iacute;CITAS, IMPL&Iacute;CITAS O LEGALES, INCLUIDAS LAS
          GARANT&Iacute;AS IMPL&Iacute;CITAS DE CONDICI&Oacute;N, USO
          ININTERRUMPIDO, COMERCIALIZACI&Oacute;N, SATISFACCI&Oacute;N CALIDAD,
          IDONEIDAD PARA UN FIN DETERMINADO, NO VIOLACI&Oacute;N DE DERECHOS DE
          TERCEROS Y GARANT&Iacute;AS (SI LAS HAY) DERIVADAS DE UN CURSO DE
          NEGOCIACI&Oacute;N, USO O PR&Aacute;CTICA COMERCIAL.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          OJIIS NO GARANTIZA CONTRA INTERFERENCIAS CON SU DISFRUTE DE LA
          APLICACI&Oacute;N; QUE LA APLICACI&Oacute;N CUMPLIR&Aacute; CON SUS
          REQUISITOS; QUE EL FUNCIONAMIENTO DE LA APLICACI&Oacute;N SER&Aacute;
          ININTERRUMPIDO O LIBRE DE ERRORES; QUE LA APLICACI&Oacute;N
          INTEROPERAR&Aacute; O SER&Aacute; COMPATIBLE CON CUALQUIER OTRA
          APLICACI&Oacute;N; QUE CUALQUIER ERROR EN LA APLICACI&Oacute;N
          SER&Aacute; CORREGIDO; O QUE LA APLICACI&Oacute;N ESTAR&Aacute;
          DISPONIBLE PARA REINSTALACIONES EN EL MISMO O M&Uacute;LTIPLES
          DISPOSITIVOS.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          NING&Uacute;N CONSEJO ORAL O ESCRITO PROPORCIONADO POR OJIIS O
          CUALQUIER REPRESENTANTE AUTORIZADO O UN TERCERO CREAR&Aacute; UNA
          GARANT&Iacute;A. ALGUNAS JURISDICCIONES NO PERMITEN LA
          EXCLUSI&Oacute;N O LAS LIMITACIONES DE LAS GARANT&Iacute;AS
          IMPL&Iacute;CITAS O LAS LIMITACIONES DE LOS DERECHOS ESTATUTARIOS
          APLICABLES DE UN CONSUMIDOR, POR LO QUE ALGUNAS O TODAS LAS
          EXCLUSIONES Y LIMITACIONES ANTERIORES PUEDEN NO APLICAR EN SU CASO.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">9. LIMITACI&Oacute;N DE RESPONSABILIDAD</span>
      </p>
      <p className="c3">
        <span className="c0">
          EN LA MEDIDA M&Aacute;XIMA QUE LO PERMITA LA LEY APLICABLE, EN
          NING&Uacute;N CASO OJIIS, SUS SUBSIDIARIAS O SUS AFILIADAS
          SER&Aacute;N RESPONSABLES ANTE USTED POR CUALQUIER LESI&Oacute;N
          PERSONAL, DA&Ntilde;O A LA PROPIEDAD, P&Eacute;RDIDA DE GANANCIAS,
          COSTO DE BIENES O SERVICIOS SUSTITUTOS, P&Eacute;RDIDA DE DATOS,
          P&Eacute;RDIDA DE FONDO DE COMERCIO , PARO DEL TRABAJO, FALLA O MAL
          FUNCIONAMIENTO DE LA COMPUTADORA, O CUALQUIER OTRA FORMA DE
          DA&Ntilde;O DIRECTO O INDIRECTO, ESPECIAL, INCIDENTAL, CONSECUENTE O
          PUNITIVO DE CUALQUIER CAUSA DE ACCI&Oacute;N QUE SURJA O EST&Eacute;
          RELACIONADA CON ESTE T&Eacute;RMINO DE SERVICIOS O LA
          APLICACI&Oacute;N, YA SEA QUE SURJA DE AGRAVIO ( INCLUYENDO
          NEGLIGENCIA), CONTRATO, RESPONSABILIDAD ESTRICTA O DE CUALQUIER
          CUALQUIER OTRO TIPO, SI OJIIS HA SIDO O NO ADVERTIDO DE LA POSIBILIDAD
          DE DICHOS DA&Ntilde;OS Y SI O NO LA REPARACI&Oacute;N, EL REEMPLAZO O
          UN REEMBOLSO (SI SE ADJUDICA A NUESTRA SOLA DISCRECI&Oacute;N) POR LA
          APLICACI&Oacute;N NO COMPENSA TOTALMENTE USTED POR CUALQUIER
          P&Eacute;RDIDA.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c0">
          ALGUNAS JURISDICCIONES NO PERMITEN UNA LIMITACI&Oacute;N DE
          RESPONSABILIDAD POR MUERTE, LESIONES PERSONALES, DECLARACIONES FALSAS
          FRAUDULENTAS O CIERTOS ACTOS INTENCIONALES O NEGLIGENTES, O
          VIOLACI&Oacute;N DE ESTATUTOS ESPEC&Iacute;FICOS, O LA
          LIMITACI&Oacute;N DE DA&Ntilde;OS INCIDENTALES O CONSECUENTES, POR LO
          QUE ALGUNAS O TODAS LAS LIMITACIONES DE RESPONSABILIDAD ANTERIORES
          PUEDE NO APLICAR EN USTED. En ning&uacute;n caso, la responsabilidad
          total de Ojiis frente a Usted por todos los da&ntilde;os (salvo que lo
          exija la ley aplicable) exceder&aacute; el monto que realmente
          pag&oacute; por la Aplicaci&oacute;n. ESTA LIMITACI&Oacute;N SE
          APLICA, PERO NO SE LIMITA A TODO LO RELACIONADO CON LA
          APLICACI&Oacute;N, LOS SERVICIOS O EL CONTENIDO DISPONIBLE A
          TRAV&Eacute;S DE LA APLICACI&Oacute;N.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">10. INDEMNIZACI&Oacute;N</span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted acepta defender, indemnizar y eximir de responsabilidad a Ojiis
          y sus empleados, contratistas, funcionarios y directores de todos y
          cada uno de los reclamos, juicios, da&ntilde;os, costos, juicios,
          multas, sanciones, responsabilidades, gastos (incluidos los honorarios
          de abogados) que surjan de Su uso o mal uso de la Aplicaci&oacute;n,
          violaci&oacute;n de los T&eacute;rminos de servicio o violaci&oacute;n
          de cualquier derecho de un tercero. Ojiis se reserva el derecho de
          asumir la defensa y el control exclusivos de cualquier asunto sujeto a
          indemnizaci&oacute;n por su parte, en cuyo caso cooperar&aacute; para
          hacer valer las defensas disponibles.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">11. MEDIDAS CAUTELARES</span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted acepta que el incumplimiento de estos T&eacute;rminos de
          servicio causar&aacute; da&ntilde;os irreparables a Ojiis por los
          cuales los da&ntilde;os monetarios no ser&iacute;an un remedio
          adecuado y Ojiis tendr&aacute; derecho a buscar una
          compensaci&oacute;n equitativa adem&aacute;s de cualquier remedio que
          pueda tener en virtud del presente o por ley sin una fianza. , otra
          seguridad, o prueba de da&ntilde;os.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">13. LEY APLICABLE</span>
      </p>
      <p className="c3">
        <span className="c0 c10">
          Este Acuerdo y todas las disputas o controversias que surjan de o
          est&eacute;n relacionadas con este Acuerdo se regir&aacute;n e
          interpretar&aacute;n de acuerdo con las leyes del Estado de
          Qu&eacute;bec y Canad&aacute;, excluyendo sus reglas de conflicto de
          leyes.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">14. ARBITRAJE</span>
      </p>
      <p className="c3">
        <span className="c0">
          Haremos todos los esfuerzos razonables para resolver cualquier
          desacuerdo que tenga con Ojiis. Si esos esfuerzos fallan, al usar la
          Aplicaci&oacute;n, usted acepta que cualquier reclamo, disputa o
          controversia que pueda tener contra Ojiis que surja de, se relacione o
          est&eacute; conectado de alguna manera con este Acuerdo y/o la
          Aplicaci&oacute;n, ser&aacute; resuelta exclusivamente por arbitraje
          final y vinculante.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15. DISPOSICIONES FINALES</span>
      </p>
      <p className="c2">
        <span className="c4"></span>
      </p>
      <p className="c3">
        <span className="c4">15.1 ELEGIBILIDAD</span>
      </p>
      <p className="c3">
        <span className="c0">
          Cualquier persona que use la Aplicaci&oacute;n declara a Ojiis que
          tiene al menos la mayor&iacute;a de edad seg&uacute;n la ley
          aplicable, o si es menor de edad, que es un menor emancipado o posee
          el consentimiento legal de un padre o tutor legal, y son totalmente
          capaces y competentes para celebrar los t&eacute;rminos, condiciones,
          obligaciones, afirmaciones, representaciones y garant&iacute;as
          establecidas en este Acuerdo, y para cumplir y cumplir con este
          Acuerdo.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.2 DIVISIBILIDAD Y SUPERVIVENCIA</span>
      </p>
      <p className="c3">
        <span className="c0">
          Si alguna disposici&oacute;n de estos T&eacute;rminos de servicio es
          ilegal o inaplicable seg&uacute;n la ley aplicable, el resto de la
          disposici&oacute;n se modificar&aacute; para lograr lo m&aacute;s
          cerca posible el efecto del t&eacute;rmino original y todas las
          dem&aacute;s disposiciones continuar&aacute;n en pleno vigor y efecto.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.3 ACUERDO COMPLETO</span>
      </p>
      <p className="c3">
        <span className="c0">
          Este Acuerdo constituye el acuerdo completo entre Usted y Ojiis con
          respecto a la Aplicaci&oacute;n y reemplaza todos los entendimientos
          anteriores o contempor&aacute;neos con respecto a dicho tema. Ninguna
          enmienda o modificaci&oacute;n de estos T&eacute;rminos de servicio
          ser&aacute; vinculante a menos que se realice por escrito y
          est&eacute; firmada por Ojiis. Ninguna falta de ejercicio, ni retraso
          en el ejercicio, por parte de cualquiera de las partes, de cualquier
          derecho o poder en virtud del presente impide el ejercicio posterior
          de cualquier otro derecho en virtud del presente. En caso de conflicto
          entre estos T&eacute;rminos de servicio, cualquier compra aplicable u
          otros t&eacute;rminos, prevalecer&aacute;n los t&eacute;rminos de
          estos T&eacute;rminos de servicio.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.4 IDIOMA</span>
      </p>
      <p className="c3">
        <span className="c0">
          La versi&oacute;n original de estos T&eacute;rminos de servicio
          est&aacute; escrita en ingl&eacute;s. Cualquier traducci&oacute;n del
          mismo a cualquier otro idioma se realiza seg&uacute;n los requisitos
          locales y en caso de disputa, inconsistencia o discrepancia entre la
          versi&oacute;n en ingl&eacute;s y cualquier otra versi&oacute;n en
          ingl&eacute;s, prevalecer&aacute; la versi&oacute;n en ingl&eacute;s
          de estos T&eacute;rminos de servicio, en la medida en que no prohibido
          por la ley local en Su jurisdicci&oacute;n.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.5 EXPORTACI&Oacute;N</span>
      </p>
      <p className="c3">
        <span className="c0">
          Usted acepta cumplir con las leyes de control de exportaci&oacute;n de
          EE. UU. y otras leyes aplicables y acepta no transferir la
          Aplicaci&oacute;n a un extranjero o destino nacional, lo cual
          est&aacute; prohibido por dichas leyes, sin obtener primero y luego
          cumplir con cualquier autorizaci&oacute;n gubernamental requerida.
          Usted certifica que no es una persona con la que Ojiis tenga prohibido
          realizar transacciones comerciales seg&uacute;n la ley aplicable.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.6 CAMBIOS</span>
      </p>
      <p className="c3">
        <span className="c0">
          Ocasionalmente actualizaremos estos T&eacute;rminos de servicio
          seg&uacute;n sea necesario para proteger a nuestros usuarios,
          proporcionar informaci&oacute;n actualizada y responder a cambios
          legales y t&eacute;cnicos. Prevalecer&aacute; la versi&oacute;n
          m&aacute;s reciente de los T&eacute;rminos de servicio. Por favor,
          consulte esta p&aacute;gina web regularmente para cualquier cambio.
          Nos reservamos el derecho de cambiar estos T&eacute;rminos de servicio
          en cualquier momento mediante la publicaci&oacute;n de la
          versi&oacute;n modificada en la direcci&oacute;n mencionada
          anteriormente. Su uso de la Aplicaci&oacute;n despu&eacute;s de que se
          integren los cambios constituir&aacute; Su aceptaci&oacute;n de los
          cambios.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c3">
        <span className="c4">15.7 INFORMACI&Oacute;N DE CONTACTO</span>
      </p>
      <p className="c3">
        <span>
          Si desea comunicarse con Ojiis con cualquier pregunta, queja o reclamo
          con respecto a la Aplicaci&oacute;n, debe visitar el sitio web de
          Ojiis en{" "}
        </span>
        <span className="c9">www.ojiis.com </span>
        <span>o comunicarse con </span>
        <span className="c9">support@ojiis.com</span>
      </p>
      <p className="c2">
        <span className="c0 c8"></span>
      </p>
      <p className="c3">
        <span className="c4">
          15.8 TRANSFERENCIA DE T&Iacute;TULO PARA LA VENTA DE UNA
          APLICACI&Oacute;N
        </span>
      </p>
      <p className="c3">
        <span>
          En la medida en que la legislaci&oacute;n aplicable permita a las
          partes de una transacci&oacute;n acordar d&oacute;nde se transfiere el
          t&iacute;tulo de propiedad para la venta de la propiedad, usted acepta
          que el t&iacute;tulo de la venta de la Aplicaci&oacute;n (software
          vendido mediante descarga electr&oacute;nica) se transfiere al
          domicilio de Ojiis.
        </span>
      </p>
    </div>
  );
};

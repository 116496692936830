import { useContext, useEffect, useRef, useState } from "react";
import { translations } from "../../../translations/texts";
import Header from "../../Header/Header";
import "./ContactUs.scss";
import { HiLink } from "react-icons/hi";
import ReCAPTCHA from "react-google-recaptcha";
import { emailContactUs, emailJobs, emailSupport } from "../../../requests";
import { CombinedContext } from "../../context/combinedContext";

export const ContactUs = () => {
  const { language } = useContext(CombinedContext)
  const [formType, setFormType] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [verification, setVerification] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const inputFile = useRef(null);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    clearAllErrors();
    setEmail("");
    setSubject("");
    setMessage("");
    setAttachments([]);
    inputFile.current.value = null;
  }, [formType]);

  const openForm = (type) => {
    setFormType(type);
    setHide(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    clearAllErrors();

    let valid = true;

    if (!email) {
      valid = false;
      setEmailError(true);
    }

    if (!subject) {
      valid = false;
      setSubjectError(true);
    }

    if (!message) {
      valid = false;
      setMessageError(true);
    }

    if (!verification) {
      valid = false;
      setVerificationError(true);
    }

    if (valid) {
      switch (formType) {
        case "info": {
          let obj = {
            subject,
            message,
            email,
          };

          emailContactUs(obj)
            .then(() => {
              alert("Successfully sent");
            })
            .catch((err) => {
              alert("Request failed: " + err.message);
            });
          break;
        }

        case "support": {
          const obj = {
            subject,
            message,
            email,
          };

          if (attachments.length) {
            const att = await getAttachments();

            if (att.length > 0) {
              obj.attachment1 = att[0];
            }

            if (att.length > 1) {
              obj.attachment2 = att[1];
            }
          }

          emailSupport(obj)
            .then(() => {
              alert("Successfully sent");
            })
            .catch((err) => {
              console.log(err);
              alert("Request failed: " + err.message);
            });
          break;
        }

        case "jobs": {
          const obj = {
            subject,
            message,
            email,
          };

          if (attachments.length) {
            const att = await getAttachments();

            if (att.length > 0) {
              obj.attachment1 = att[0];
            }

            if (att.length > 1) {
              obj.attachment2 = att[1];
            }
          }

          emailJobs(obj)
            .then(() => {
              alert("Successfully sent");
            })
            .catch((err) => {
              alert("Request failed: " + err.message);
            });
          break;
        }

        default: {
          return null;
        }
      }
    }
  };

  const getAttachments = () => {
    return Promise.all(
      attachments.map(async (value) => {
        const content = await getBase64(value);

        return {
          filename: value.name,
          content,
        };
      })
    );
  };

  const clickAttachFiles = () => {
    inputFile.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split("base64,")[1]);
      };

      reader.onerror = (error) => {
        reject(error);
        alert("Error: ", error);
      };
    });
  };

  const attachFiles = (e) => {
    setAttachments([]);
    const files = Object.values(e.target.files);

    if (files.length > 2) {
      alert("Maximum 2 files");
    } else {
      setAttachments(files);
    }
  };

  const getFormTitle = () => {
    switch (formType) {
      case "support":
        return translations[language].contact_us.supportTitle;
      case "info":
        return translations[language].contact_us.informationTitle;
      case "jobs":
        return translations[language].contact_us.jobsTitle;
      default:
        return translations[language].contact_us.supportTitle;
    }
  };

  const clearAllErrors = () => {
    setEmailError(false);
    setSubjectError(false);
    setMessageError(false);
    setVerificationError(false);
  };

  const cancel = () => {
    setHide(true);
    clearAllErrors();
  };

  return (
    <div className="contact-us">
      <Header nonHome />
      <section>
        <div className="left-column-wrapper">
          <h1>
            <i>{translations[language].footer.contact_us}</i>
          </h1>
          <div className="contact-us-content-1">
            <div className="links">
              <p onClick={() => openForm("support")}>
                {translations[language].contact_us.support}
              </p>
              <p onClick={() => openForm("info")}>
                {translations[language].contact_us.information}
              </p>
              <p onClick={() => openForm("jobs")}>
                {translations[language].contact_us.jobs}
              </p>
            </div>
          </div>
        </div>

        <div className={`contact-us-content-2 ${hide ? "--hide" : ""}`}>
          <div className="form-wrap">
            <form onSubmit={submitForm}>
              <p className="title">{getFormTitle()}</p>
              <input
                type="email"
                value={email}
                placeholder={translations[language].contact_us.email}
                className="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="error">{emailError ? "Required" : ""}</p>
              <input
                value={subject}
                placeholder={
                  formType === "jobs"
                    ? translations[language].contact_us.position
                    : translations[language].contact_us.subject
                }
                className="subject"
                onChange={(e) => setSubject(e.target.value)}
              />
              <p className="error">{subjectError ? "Required" : ""}</p>
              <textarea
                value={message}
                placeholder={translations[language].contact_us.message}
                className="message"
                onChange={(e) => setMessage(e.target.value)}
              />
              <p className="error">{messageError ? "Required" : ""}</p>
              {formType !== "info" ? (
                <div className="attach-wrap">
                  <p onClick={clickAttachFiles} className="attach">
                    {translations[language].contact_us.attach}&nbsp;
                    <HiLink />
                  </p>
                </div>
              ) : null}
              <input
                onChange={attachFiles}
                type="file"
                multiple
                accept=".pdf,.docx,.doc,.txt,.png,.jpg"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <div className="files">
                {attachments.map((file, index) => (
                  <p key={index}>{file.name}</p>
                ))}
              </div>
              <div className="wrap-buttons">
                <div className="wrap-recap">
                  <ReCAPTCHA
                    sitekey="6LczJDkmAAAAANvvHWAKOmkDHiY-lvHUZk2jbvDg"
                    onChange={(result) => setVerification(result)}
                  />
                  <p className="error">{verificationError ? "Required" : ""}</p>
                </div>
                <div className="buttons">
                  <button type="submit">
                    {translations[language].contact_us.submit}
                  </button>
                  <button type="reset" onClick={cancel}>
                    {translations[language].contact_us.cancel}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="invisible-placeholder"></div>
        {/* this is meant to be the last item in the flex row. To center the middle form section */}
      </section>
    </div>
  );
};

export default ContactUs;

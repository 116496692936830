import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const SubFAQSection = ({ question, answer }) => {
  return (
    <section data-testid="container-faq-section" className="accordion-wrapper">
      <Accordion className="accordion-inner-wrapper">
        <div className="faq-section-question">
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div>{question}</div>

            <div className="icons">
              <span role="button" className="open">
                <IoIosArrowDown className="icon-arrow-down" />
              </span>
              <span role="button" className="close">
                <IoIosArrowUp />
              </span>
            </div>
          </AccordionSummary>
        </div>
        <AccordionDetails className="accordian-details">
          <p
            dangerouslySetInnerHTML={{ __html: answer }}
            className="faq-section-answer"
          >
            {}
          </p>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default SubFAQSection;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Background from "../assets/background_desktop.js";
import "./App.scss";
import { Homepage } from "./Homepage/Homepage.js";
import { ROUTES } from "../constants/routes.js";
import FAQ from "./Pages/FAQ/FAQ.js";
import ContactUs from "./Pages/ContactUs/ContactUs.js";
import TOS from "./Pages/TOS/TOS.js";
import VideoPolicy from "./Pages/VideoPolicy/VideoPolicy.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.js";
import Footer from "./Footer/Footer.js";
import { CombinedContextProvider } from "./context/combinedContext.js";

const App = () => {

	return (
		<div className="app">
			<BrowserRouter>
				<CombinedContextProvider>
					<Background className="background" />
					<div className="gradient-top" />
					<Routes>
						<Route exact path={ROUTES.HOME} element={<Homepage />} />
						<Route exact path={ROUTES.FAQ} element={<FAQ />} />
						<Route exact path={ROUTES.CONTACT_US} element={<ContactUs />} />
						<Route exact path={ROUTES.TOS} element={<TOS />} />
						<Route exact path={ROUTES.VIDEO_POLICY} element={<VideoPolicy />} />
						<Route exact path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
					</Routes>
					<Footer/>
				</CombinedContextProvider>
			</BrowserRouter>
		</div>
	);
};

export default App;

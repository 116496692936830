import { useContext, useEffect, useState } from "react";
import { translations } from "../../../translations/texts";
import Header from "../../Header/Header";
import "./FAQ.scss";
import SubFAQSection from "./FAQSection/SubFAQSection";
import { CombinedContext } from "../../context/combinedContext";

export const FAQ = () => {
  const { language } = useContext(CombinedContext)
  let [maxIndex, setMaxIndex] = useState(5);
  let [hide, setHide] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="faq">
      <Header nonHome />
      <section>
        <h1>
          <i>{translations[language].footer.faq}</i>
        </h1>
        <div className="accordion-section">
          {Object.entries(translations[language].faq).map((_key, index) =>
            index < maxIndex ? (
              <SubFAQSection
                key={index}
                question={
                  translations[language].faq[`section${index + 1}`].question
                }
                answer={
                  translations[language].faq[`section${index + 1}`].answer
                }
              />
            ) : null
          )}
        </div>
        <div className={`wrap-see-more ${hide ? "--hide" : ""}`}>
          <button
            onClick={() => {
              setMaxIndex(Object.entries(translations[language].faq).length);
              setHide(true);
            }}
            className="see-more"
          >
            {translations[language].see_more}
          </button>
        </div>
      </section>
    </div>
  );
};

export default FAQ;

export const PrivacyPolicyEN = () => {
  return (
    <div className="c8 c11 doc-content">
      <p className="c2">
        <span className="c1">
          Ojiis (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
          operates the website and the Ojiis mobile application (the
          &ldquo;Service&rdquo;).
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1">
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. We use your data to
          provide and improve the Service. By using the Service, you agree to
          the collection and use of information in accordance with this policy.
          Unless otherwise defined in this Privacy Policy, terms used in this
          Privacy Policy have the same meanings as in our Terms of Service. By
          downloading the Ojiis mobile application, you agree to these terms.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Information Collection and Use</span>
      </p>
      <p className="c2">
        <span className="c1">
          We automatically collect several different types of information for
          various purposes to provide and improve our Service to you.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Types of Data Collected</span>
      </p>
      <p className="c0">
        <span className="c3 c5"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Personal Data</span>
      </p>
      <p className="c2">
        <span>
          We currently do not ask you to provide us with personally identifiable
          information such as information that can be used to contact or
          identify you (&ldquo;Personal Data&rdquo;). You can choose to provide
          us with personal information if you, for instance, contact our
          customer service departmen
        </span>
        <span className="c1 c8">t.</span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1 c8">
          The service allows you to record and store videos, using your native
          video device. Should you choose to record videos and keep them, those
          videos will be stored locally, meaning they &nbsp;will stay on your
          device. We do not retain a copy. When you delete the video, it is
          permanently deleted.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Usage Data</span>
      </p>
      <p className="c2">
        <span className="c1">
          We may collect information when you access our Service (&ldquo;Usage
          Data&rdquo;) such as a mobile device unique ID, the IP address of your
          mobile device, your mobile operating system, and we may aggregate this
          data for use in data analysis. We do not use this information to track
          your use individually or to send you advertising. For instance, we may
          aggregate information we receive that a particular functionality of
          the Service is being heavily used, and we may use this information to
          improve the overall experience of the App.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Use of Data</span>
      </p>
      <p className="c2">
        <span className="c1">
          Ojiis uses the collected User Data to maintain and improve the
          Service, including preventing technical issues and addressing customer
          service issues. It does not use the information to advertise to you.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Storage of Data</span>
      </p>
      <p className="c2">
        <span className="c1">
          All Data is stored on servers in the Canada, and any information is
          stored for a legitimate business purpose. Information pertaining to an
          individual transaction, such as an email sent through the Service,
          that can identify you personally is kept only for as long as there is
          a legitimate business interest in doing so and then may be destroyed
          or hashed so that it cannot identify you. For instance, if you send us
          a communication that contains your name, we may keep that information
          for as long as we need to resolve your matter, and for a legally
          mandated period of time. After your matter is resolved, we may retain
          a record of the transaction, but we will remove any information that
          could identify you personally if that is no longer necessary. We
          reserve the right to keep Personal Data or any data indefinitely if we
          reasonably believe it is necessary.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1">
          Ojiis will take steps reasonably necessary to ensure that your data is
          treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will be sent to an organization or a
          country unless there are adequate controls in place, including the
          security of your data and other personal information.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Disclosure of Data</span>
      </p>
      <p className="c2">
        <span className="c1">
          We do not sell or disclose any Personal Data to third parties. Ojiis
          does not routinely collect data that identifies anyone personally. In
          the event you have supplied Personal Data, we may disclose that
          Personal Data in the good faith belief that such action is necessary:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c10 lst-kix_list_1-0 start">
        <li className="c2 c4 li-bullet-0">
          <span className="c1">To comply with a legal obligation</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1">
            To protect and defend the rights or property of Ojiis
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1">
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1">
            To protect the personal safety of users of the Service or the public
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1">To protect against legal liability</span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Security of Data</span>
      </p>
      <p className="c2">
        <span className="c1">
          We do not sell any Personal Data and if we transfer your data to our
          Service Providers for a legitimate business purpose. The security of
          all our data is important to us, and we make commercially reasonable
          efforts to keep the Service safe. Please remember that no method of
          transmission over the Internet, or method of electronic storage is
          100% secure and we cannot guarantee its absolute security.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Service Providers</span>
      </p>
      <p className="c2">
        <span className="c1">
          We may employ third party companies and individuals to facilitate our
          Service (&ldquo;Service Providers&rdquo;), to provide the Service on
          our behalf, to perform Service-related services or to assist us in
          analysing how our Service is used. These Service Providers have agreed
          to terms consistent with this Privacy Policy and do not sell or
          otherwise use your Personal Data for marketing purposes. To the extent
          these Service Providers have access to your Personal Data, it is only
          to perform a requested task on our behalf and they are obligated not
          to disclose or use it for any other purpose.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Deletion of the Mobile Application</span>
      </p>
      <p className="c2">
        <span className="c1">
          Deletion of the mobile application will delete the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c5 c3">Analytics</span>
      </p>
      <p className="c2">
        <span className="c1">
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Links To Other Sites</span>
      </p>
      <p className="c2">
        <span className="c1">
          We do not share information with other mobile applications. Our
          Service may, however, contain links to other sites that are not
          operated by us. If you click on a third-party link, you will be
          directed to that third party&rsquo;s site. We strongly advise you to
          review the Privacy Policy of every site you visit. We have no control
          over and assume no responsibility for the content, privacy policies or
          practices of any third-party sites or services.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Children&rsquo;s Privacy</span>
      </p>
      <p className="c2">
        <span>
          Protecting the privacy of minors is especially important. For that
          reason, Ojiis does not knowingly collect or solicit personal
          information from anyone under the age of 13 or knowingly allow such
          persons to register with our Service. If you are under 13, please do
          not send any information about yourself to us, including your name or
          email address. No one under the age of 13 is allowed to provide any
          personal information to or on Ojiis. In the event that we learn that
          we have collected personal information from a child under the age of
          13 without verification of parental consent, we will delete that
          information as quickly as possible. If you believe that we might have
          any information from or about a child under 13, please{" "}
        </span>
        <span className="c9">contact us.</span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Changes To This Privacy Policy</span>
      </p>
      <p className="c2">
        <span className="c1">
          As we improve functionality to our site, we may add features,
          including the change to our privacy policies and procedures, we will
          post those changes on our website to keep you updated regarding
          information collection, how we use it and under what circumstances we
          may disclose it. Changes to this Privacy Policy are effective when
          they are posted on this page.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c3">Contact Us</span>
      </p>
      <p className="c2">
        <span>
          If you have any questions about this Privacy Policy, please{" "}
        </span>
        <span className="c9">contact us.</span>
      </p>
      <p className="c0">
        <span className="c6"></span>
      </p>
      <p className="c0">
        <span className="c1 c7"></span>
      </p>
    </div>
  );
};

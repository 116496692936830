import { useNavigate } from "react-router-dom";
import { ASSETS_DOMAIN, ASSETS_PATHS } from "../../ojiis-assets";
import SocialMedia from "../SocialMedia/SocialMedia";
import "./Header.scss";
import { ROUTES } from "../../constants/routes";
import { isOnMobile } from "../../utilities";

const Header = ({ nonHome }) => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <header>
      {/* {!nonHome ? (
        <img
          alt=""
          className="gradient-top"
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.BACKGROUND_GRADIENTS.TOP_GRADIENT}`}
        />
      ) : (
        ""
      )} */}
      {!isOnMobile() ? <div className="header-space"></div> : ""}
      <div className="header-logo">
        <img
          alt=""
          onClick={goToHome}
          className={`main-logo ${nonHome ? "--smaller" : ""}`}
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LOGOS.LANDING_PAGE_MAIN_LOGO}`}
        />
        {!nonHome ? (
          <div className="app-icons">
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                (window.location.href =
                  "https://apps.apple.com/ca/app/ojiis/id1491394934?platform=iphone")
              }
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.APP_STORE_ICONS.APP_STORE}`}
              alt=""
            />
            <img
              alt=""
              style={{ cursor: "pointer" }}
              className="play-store"
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=com.bmad.OjiisApp")
              }
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.APP_STORE_ICONS.PLAY_STORE}`}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {!isOnMobile() ? <SocialMedia /> : ""}
    </header>
  );
};

export default Header;

import { LANG_ABREVIATION } from "../../../constants/language";

export const languageOnLoad = () => {
	const localLang = localStorage.getItem("lang");
	let initialLanguage;

	const english = LANG_ABREVIATION.EN;

	if (!localLang) {
		initialLanguage = english.toLowerCase();
		localStorage.setItem("lang", english.toLowerCase());
	} else {
		initialLanguage = localLang;
	}

	return initialLanguage.toLowerCase();
};


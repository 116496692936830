export const homepage = {
  section_1_line_1: "NOUVELLE GÉNÉRATION",
  section_1_line_2: "D'ÉMOJIS",
  section_1_line_3: "Ils bougent. Ils parlent. Ils sont rigolos.",
  section_1_line_4: "Ils sont en 3D. Ils sont vivants !",
  section_1_line_5: "ILS SONT LE FUTUR DES EMOJIS",
  section_2_line_1: "DES MILLIONS",
  section_2_line_2: "DE PERSONNALISATIONS",
  section_2_line_3: "Il y a un Ojii pour tout.",
  section_2_line_4: "Personnalise-le et exprime-toi comme jamais auparavant !",
  section_2_line_5: "Ta créativité n'a pas de limite !",
  section_3_line_1: "PASSE À UN AUTRE NIVEAU",
  section_3_line_2: "AVEC LA RA",
  section_3_line_3: "Amène les Ojiis dans ton monde avec la réalité augmentée",
  section_4_line_1: "STICKERS",
  section_4_line_2: "PERSONNALISÉS",
  section_4_line_3: "Choisi une image dans l'animation de ton Ojii",
  section_4_line_4: "et crée ton propre Sticker !",
  section_5_line_1: "CRÉATEUR D'HISTOIRE",
  section_5_line_2: "UNIQUE",
  section_5_line_3: "Sélectionne tes Ojiis et scénarise tes propres histoires.",
  section_6_line_1: "PAS DE",
  section_6_line_2: "PUBLICITÉ",
  section_6_line_3: "Crée sans interruption !",
  section_7_line_1: "DONNE UNE VOIX",
  section_7_line_2: "À TON OJII",
  section_7_line_3: "Ajoute ta touche personnelle !",
  section_7_line_4:
    "Enregistre ta voix. Ajoute ta musique ou écrit sur tes créations !",
  section_8_line_1: "TRANSFORME TES OJIIS",
  section_8_line_2: "EN ANIMAUX",
  section_8_line_3: "Fais ressortir l'Animal en toi !",
  section_8_line_4: "Transforme les Ojiis en ton animal préféré.",
  section_9_line_1: "DES SCÈNES STYLISÉES",
  section_9_line_2: "POUR TES CRÉATIONS",
  section_9_line_3: "Crée des vidéos uniques avec des arrière-plans sympas",
  section_9_line_4: "ou importe tes propres images.",
  section_10_line_1: "DÉBLOQUE TOUT ET CRÉE",
  section_10_line_2: "DES POSTS DRÔLES ET UNIQUES",
  section_10_line_3: "Personnalise ta communication comme jamais auparavant.",
  section_10_line_4: "Ne le dit pas. Oji-le !",
  media_gallery: "GALERIE",
};

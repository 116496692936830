export const contact_us = {

  support:"Soutien",
  information:"Information",
  jobs:"Emplois",
  
  supportTitle:"Soumettre un ticket",
  informationTitle:"Renseignements généraux",
  jobsTitle:"Déposer une candidature",

  email: "Courriel",
  position:"Position",
  subject:"Sujet",
  message:"Message",
  attach:"Attacher",
  submit:"Soumettre",
  cancel:"Annuler",
};

import axios from "axios";

export const emailContactUs = (values) => {
  return axios.post("https://api.ojiis.com/web/contactus", values);
};

export const emailSupport = (values) => {
  return axios.post("https://api.ojiis.com/web/emailsupport", values);
};

export const emailJobs = (values) => {
  return axios.post("https://api.ojiis.com/web/emailjobs", values);
};

import { useContext } from "react";
import { LANG_ABREVIATION } from "../../../constants/language";
import { ASSETS_DOMAIN, ASSETS_PATHS } from "../../../ojiis-assets";
import { CombinedContext } from "../../context/combinedContext";


export const Languages = () => {

  const { setLanguage } = useContext(CombinedContext)

  const english = LANG_ABREVIATION.EN

  const french = LANG_ABREVIATION.FR

  const german = LANG_ABREVIATION.DE

  const spanish = LANG_ABREVIATION.SP

  return (
    <div className="languages">
      <img
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.LANGUAGE_ICONS.ENGLISH}`}
        onClick={() => {
          localStorage.setItem("lang", english.toLowerCase());
          setLanguage(english.toLowerCase())
        }}
      />
      <img
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.LANGUAGE_ICONS.FRENCH}`}
        onClick={() => {
          localStorage.setItem("lang", french.toLowerCase());
          setLanguage(french.toLowerCase())
        }}
      />
      <img
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.LANGUAGE_ICONS.GERMAN}`}
        onClick={() => {
          localStorage.setItem("lang", german.toLowerCase());
          setLanguage(german.toLowerCase())
        }}
      />
      <img
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.LANGUAGE_ICONS.SPANISH}`}
        onClick={() => {
          localStorage.setItem("lang", spanish.toLowerCase());
          setLanguage(spanish.toLowerCase())
        }}
      />
    </div>
  );
};

export const PrivacyPolicySP = () => {
  return (
    <div>
      <p className="c4">
        <span className="c3">
          Ojiis (&quot;nosotros&quot;, &quot;nosotros&quot; o
          &quot;nuestro&quot;) opera el sitio web y la aplicaci&oacute;n
          m&oacute;vil de Ojiis (el &quot;Servicio&quot;).
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c3">
          Esta p&aacute;gina le informa sobre nuestras pol&iacute;ticas con
          respecto a la recopilaci&oacute;n, el uso y la divulgaci&oacute;n de
          datos personales cuando utiliza nuestro Servicio y las opciones que
          tiene asociadas con esos datos. Utilizamos sus datos para proporcionar
          y mejorar el Servicio. Al usar el Servicio, usted acepta la
          recopilaci&oacute;n y el uso de informaci&oacute;n de acuerdo con esta
          pol&iacute;tica. A menos que se defina lo contrario en esta
          Pol&iacute;tica de privacidad, los t&eacute;rminos utilizados en esta
          Pol&iacute;tica de privacidad tienen los mismos significados que en
          nuestros T&eacute;rminos de servicio. Al descargar la
          aplicaci&oacute;n m&oacute;vil Ojiis, acepta estos t&eacute;rminos.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c7 c2">
          Recopilaci&oacute;n y uso de informaci&oacute;n
        </span>
      </p>
      <p className="c4">
        <span className="c3">
          Recopilamos autom&aacute;ticamente varios tipos diferentes de
          informaci&oacute;n para varios prop&oacute;sitos para brindarle y
          mejorar nuestro Servicio.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c7 c2">Tipos de datos recopilados</span>
      </p>
      <p className="c1">
        <span className="c7 c2"></span>
      </p>
      <p className="c4">
        <span className="c7 c2">Informaci&oacute;n personal</span>
      </p>
      <p className="c4">
        <span>
          Actualmente no le pedimos que nos proporcione informaci&oacute;n de
          identificaci&oacute;n personal, como informaci&oacute;n que pueda
          usarse para contactarlo o identificarlo (&quot;Datos
          personales&quot;). Puede optar por proporcionarnos informaci&oacute;n
          personal si, por ejemplo, se pone en contacto con nuestro departamento
          de atenci&oacute;n al cliente{" "}
        </span>
        <span className="c3 c9">.</span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c3 c9">
          El servicio le permite grabar y almacenar videos, utilizando su
          dispositivo de video nativo. Si elige grabar videos y conservarlos,
          esos videos se almacenar&aacute;n localmente, lo que significa que
          permanecer&aacute;n en su dispositivo. No conservamos una copia.
          Cuando elimina el video, se elimina de forma permanente.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Datos de uso</span>
      </p>
      <p className="c4">
        <span className="c3">
          Podemos recopilar informaci&oacute;n cuando accede a nuestro Servicio
          (&quot;Datos de uso&quot;), como una identificaci&oacute;n
          &uacute;nica del dispositivo m&oacute;vil, la direcci&oacute;n IP de
          su dispositivo m&oacute;vil, su sistema operativo m&oacute;vil, y
          podemos agregar estos datos para su uso en el an&aacute;lisis de
          datos. No utilizamos esta informaci&oacute;n para rastrear su uso
          individualmente o para enviarle publicidad. Por ejemplo, podemos
          agregar informaci&oacute;n que recibimos de que una funcionalidad
          particular del Servicio se est&aacute; utilizando mucho, y podemos
          usar esta informaci&oacute;n para mejorar la experiencia general de la
          Aplicaci&oacute;n.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Uso de datos</span>
      </p>
      <p className="c4">
        <span className="c3">
          Ojiis utiliza los Datos de usuario recopilados para mantener y mejorar
          el Servicio, incluida la prevenci&oacute;n de problemas
          t&eacute;cnicos y la resoluci&oacute;n de problemas de servicio al
          cliente. No utiliza la informaci&oacute;n para hacerle publicidad.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Almacenamiento de datos</span>
      </p>
      <p className="c4">
        <span className="c3">
          Todos los datos se almacenan en servidores en Canad&aacute;, y
          cualquier informaci&oacute;n se almacena para un prop&oacute;sito
          comercial leg&iacute;timo. La informaci&oacute;n relacionada con una
          transacci&oacute;n individual, como un correo electr&oacute;nico
          enviado a trav&eacute;s del Servicio, que puede identificarlo
          personalmente, se conserva solo mientras exista un inter&eacute;s
          comercial leg&iacute;timo para hacerlo y luego puede destruirse o
          codificarse para que no pueda identificarlo. . Por ejemplo, si nos
          env&iacute;a una comunicaci&oacute;n que contiene su nombre, es
          posible que conservemos esa informaci&oacute;n durante el tiempo que
          necesitemos para resolver su asunto y durante un per&iacute;odo de
          tiempo obligatorio por ley . Despu&eacute;s de que se resuelva su
          asunto, podemos conservar un registro de la transacci&oacute;n, pero
          eliminaremos cualquier informaci&oacute;n que pueda identificarlo
          personalmente si ya no es necesaria. Nos reservamos el derecho de
          conservar los Datos personales o cualquier dato de forma indefinida si
          creemos razonablemente que es necesario.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c3">
          Ojiis tomar&aacute; las medidas razonablemente necesarias para
          garantizar que sus datos se traten de forma segura y de acuerdo con
          esta Pol&iacute;tica de privacidad y no se enviar&aacute; ninguna
          transferencia de sus Datos personales a una organizaci&oacute;n o
          pa&iacute;s a menos que existan controles adecuados, incluida la
          seguridad de su datos y otra informaci&oacute;n personal.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Divulgaci&oacute;n de datos</span>
      </p>
      <p className="c4">
        <span className="c3">
          No vendemos ni divulgamos ning&uacute;n dato personal a terceros.
          Ojiis no recopila de forma rutinaria datos que identifiquen
          personalmente a nadie. En el caso de que haya proporcionado Datos
          personales, podemos divulgar esos Datos personales de buena fe cuando
          creamos que dicha acci&oacute;n es necesaria:
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <ul className="c10 lst-kix_list_1-0 start">
        <li className="c0 li-bullet-0">
          <span className="c3">
            Para cumplir con una obligaci&oacute;n legal
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c3">
            Para proteger y defender los derechos o la propiedad de Ojiis
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c3">
            Para prevenir o investigar posibles irregularidades en
            relaci&oacute;n con el Servicio
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c3">
            Para proteger la seguridad personal de los usuarios del Servicio o
            del p&uacute;blico
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c3">
            Para protegerse contra la responsabilidad legal
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2 c7">Seguridad de datos</span>
      </p>
      <p className="c4">
        <span className="c3">
          No vendemos ning&uacute;n dato personal y si transferimos sus datos a
          nuestros proveedores de servicios para un prop&oacute;sito comercial
          leg&iacute;timo. La seguridad de todos nuestros datos es importante
          para nosotros y hacemos esfuerzos comercialmente razonables para
          mantener el Servicio seguro. Recuerde que ning&uacute;n m&eacute;todo
          de transmisi&oacute;n a trav&eacute;s de Internet o m&eacute;todo de
          almacenamiento electr&oacute;nico es 100% seguro y no podemos
          garantizar su seguridad absoluta.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Proveedores de servicio</span>
      </p>
      <p className="c4">
        <span className="c3">
          Podemos emplear empresas e individuos de terceros para facilitar
          nuestro Servicio (&quot;Proveedores de servicios&quot;), para
          proporcionar el Servicio en nuestro nombre, para realizar servicios
          relacionados con el Servicio o para ayudarnos a analizar c&oacute;mo
          se utiliza nuestro Servicio. Estos Proveedores de servicios han
          aceptado t&eacute;rminos consistentes con esta Pol&iacute;tica de
          privacidad y no venden ni utilizan sus Datos personales con fines de
          marketing. En la medida en que estos Proveedores de servicios tengan
          acceso a sus Datos personales, es solo para realizar una tarea
          solicitada en nuestro nombre y est&aacute;n obligados a no divulgarlos
          ni utilizarlos para ning&uacute;n otro prop&oacute;sito.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c7 c2">
          Eliminaci&oacute;n de la Aplicaci&oacute;n M&oacute;vil
        </span>
      </p>
      <p className="c4">
        <span className="c3">
          La eliminaci&oacute;n de la aplicaci&oacute;n m&oacute;vil
          eliminar&aacute; los Servicios.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c7 c2">Anal&iacute;tica</span>
      </p>
      <p className="c4">
        <span className="c3">
          Podemos utilizar Proveedores de Servicios de terceros para monitorear
          y analizar el uso de nuestro Servicio.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Enlaces a otros sitios</span>
      </p>
      <p className="c4">
        <span className="c3">
          No compartimos informaci&oacute;n con otras aplicaciones
          m&oacute;viles. Sin embargo, nuestro Servicio puede contener enlaces a
          otros sitios que no son operados por nosotros. Si hace clic en el
          enlace de un tercero, ser&aacute; dirigido al sitio de ese tercero. Le
          recomendamos encarecidamente que revise la Pol&iacute;tica de
          privacidad de cada sitio que visite. No tenemos control ni asumimos
          ninguna responsabilidad por el contenido, las pol&iacute;ticas de
          privacidad o las pr&aacute;cticas de los sitios o servicios de
          terceros.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Privacidad de los ni&ntilde;os</span>
      </p>
      <p className="c4">
        <span>
          La protecci&oacute;n de la privacidad de los menores es especialmente
          importante. Por ese motivo, Ojiis no recopila ni solicita a sabiendas
          informaci&oacute;n personal de ninguna persona menor de 13 a&ntilde;os
          ni permite a sabiendas que dichas personas se registren en nuestro
          Servicio. Si tiene menos de 13 a&ntilde;os, no nos env&iacute;e
          ninguna informaci&oacute;n sobre usted, incluidos su nombre o
          direcci&oacute;n de correo electr&oacute;nico. Nadie menor de 13
          a&ntilde;os puede proporcionar informaci&oacute;n personal a Ojiis o
          en Ojiis. En el caso de que sepamos que hemos recopilado
          informaci&oacute;n personal de un ni&ntilde;o menor de 13 a&ntilde;os
          sin verificaci&oacute;n del consentimiento de los padres, eliminaremos
          esa informaci&oacute;n lo m&aacute;s r&aacute;pido posible. Si cree
          que podr&iacute;amos tener informaci&oacute;n de un ni&ntilde;o menor
          de 13 a&ntilde;os o sobre &eacute;l,{" "}
        </span>
        <span className="c5">comun&iacute;quese con nosotros.</span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Cambios a esta pol&iacute;tica de privacidad</span>
      </p>
      <p className="c4">
        <span className="c3">
          A medida que mejoramos la funcionalidad de nuestro sitio, podemos
          agregar funciones, incluido el cambio en nuestras pol&iacute;ticas y
          procedimientos de privacidad, publicaremos esos cambios en nuestro
          sitio web para mantenerlo actualizado sobre la recopilaci&oacute;n de
          informaci&oacute;n, c&oacute;mo la usamos y bajo qu&eacute;
          circunstancias podemos divulgar &eacute;l. Los cambios a esta
          Pol&iacute;tica de privacidad son efectivos cuando se publican en esta
          p&aacute;gina.
        </span>
      </p>
      <p className="c1">
        <span className="c3"></span>
      </p>
      <p className="c4">
        <span className="c2">Cont&aacute;ctenos</span>
      </p>
      <p className="c4">
        <span>
          Si tiene alguna pregunta sobre esta Pol&iacute;tica de privacidad,
          cont&aacute;ctenos{" "}
        </span>
        <span className="c5">.</span>
      </p>
      <p className="c1">
        <span className="c6"></span>
      </p>
      <p className="c1">
        <span className="c3 c11"></span>
      </p>
    </div>
  );
};

import { ASSETS_DOMAIN, ASSETS_PATHS } from "../ojiis-assets";
import { isOnMobile } from "../utilities";

const Background = () => {



  return (
    <img
      alt=""
      className="background"
      src={isOnMobile()?`${ASSETS_DOMAIN}${ASSETS_PATHS.STATIC_BACKGROUND.MOBILE}`:`${ASSETS_DOMAIN}${ASSETS_PATHS.STATIC_BACKGROUND.DESKTOP}`}
    />
  );
};

export default Background;

export const contact_us = {

  support:"Support",
  information:"Information",
  jobs:"Jobs",
  
  supportTitle:"Submit a ticket",
  informationTitle:"General Inquiries",
  jobsTitle:"Submit a job application",

  email: "Email",
  position:"Position",
  subject:"Subject",
  message:"Message",
  attach:"Attach",
  submit:"Submit",
  cancel:"Cancel",
};

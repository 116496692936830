export const faq = {
  section1: {
    question: "¿Qué es un Ojii ?",
    answer:
      "Están en 3D, se mueven, hablan, son divertidos, ¡están vivos! Son la nueva generación de emojis.",
  },
  section2: {
    question: "¿Puedo compartir mis Ojiis?",
    answer:
      "¡Sí! La aplicación le brinda varias formas de guardar y compartir sus Ojiis y creaciones, incluidas animaciones 3D, GIF e incluso la posibilidad de seleccionar una imagen específica de una animación para enviar como una etiqueta.",
  },
  section3: {
    question: "¿Cómo me comunico con el soporte técnico para obtener ayuda?",
    answer:
      "Para obtener asistencia técnica, envíe un correo electrónico a support@ojiis.com y nuestro equipo estará encantado de ayudarle.",
  },
  section4: {
    question: "¿Qué sucede con mis creaciones si desinstalo la aplicación?",
    answer:
      "La desinstalación de la aplicación resultará en la pérdida de todas las creaciones que haya realizado.",
  },
  section5: {
    question:
      "¿Qué sucede con mis creaciones que usan funciones 'Premium' si cancelo mi suscripción?",
    answer:
      "Si su suscripción finaliza, las creatividades que utilizan funciones premium quedarán temporalmente inaccesibles. Sin embargo, puede recuperar el acceso volviendo a suscribirse a la opción Premium.",
  },
  section6: {
    question:
      "¿Qué sucede si cambio mi suscripción de un plan mensual a un plan anual?",
    answer:
      "Si actualiza a un plan anual, recibirá un crédito basado en la cantidad de días restantes en su suscripción actual. Su suscripción anual comenzará automáticamente cuando finalice su plan mensual.",
  },
  section7: {
    question: "¿La aplicación es gratuita?",
    answer:
      "La aplicación es gratuita para todos, pero el acceso al contenido y las funciones está restringido. Para tener acceso ilimitado, debe suscribirse al plan Premium.",
  },
  section8: {
    question: "¿Qué incluye una Membresía Premium?",
    answer:
      "La membresía Premium le brinda acceso ilimitado al contenido de Ojiis y desbloquea todas las funciones y opciones de personalización, además recibe contenido nuevo cada semana.",
  },
  section9: {
    question: "¿Cómo cancelo mi suscripción?",
    answer:
      "Puede cancelar su suscripción en la sección de configuración de su dispositivo móvil.",
  },
  section10: {
    question: "¿Cómo elimino mi cuenta?",
    answer:
      'Si desea eliminar su cuenta, haga clic <a href="mailto:support@ojiis.com">aquí</a>.',
  },
  section11: {
    question: "¿Qué debo esperar de Ojiis en el futuro?",
    answer:
      "¡Se agregarán nuevos Ojiis, características y opciones de personalización semanalmente para mantener su experiencia emocionante!",
  },
  section12: {
    question: "¿Puedo tener varias cuentas en el mismo dispositivo?",
    answer: "Esta función no está disponible actualmente.",
  },
  section13: {
    question:
      "¿Puedo usar la aplicación Ojiis sin tener una conexión a Internet?",
    answer:
      "Sí, pero necesitará una conexión a Internet para cargar sus Ojiis o acceder a su nuevo contenido.",
  },
  section14: {
    question: "¿Qué idiomas están disponibles?",
    answer:
      "Nuestra aplicación está disponible en inglés, francés, español y alemán. Puede cambiar de idioma en cualquier momento, seleccionándolos en la página de Configuración.",
  },
  section15: {
    question:
      "¿Puedo usar la barra de búsqueda para un idioma diferente al seleccionado?",
    answer:
      "La barra de búsqueda y las palabras clave solo están relacionadas con el idioma seleccionado en la aplicación.",
  },
  section16: {
    question: "¿Dónde se guardan mis creaciones?",
    answer:
      "Todas sus creaciones se guardan en la aplicación y en su dispositivo móvil. Sin embargo, sus fotos o imágenes importadas, el texto personalizado y la voz no se guardan en la aplicación, esto es para garantizar su privacidad.",
  },
  section17: {
    question: "¿Cómo elimino una creación?",
    answer:
      "Para eliminar una creación, vaya a la pestaña Creaciones (icono con una estrella) en la Biblioteca y seleccione la creación que desea eliminar. Una vez seleccionado, haga clic en el icono Eliminar.",
  },
  section18: {
    question:
      "¿Qué puedo importar desde mi dispositivo móvil para personalizar mis Ojiis?",
    answer:
      "Puede importar sus propias fotos/imágenes y usarlas para personalizar sus creaciones.",
  },
  section19: {
    question: "¿Existen controles parentales en la aplicación?",
    answer:
      "Sí, puede habilitar/deshabilitar los controles parentales en la sección Configuración.",
  },
  section20: {
    question:
      "¿Qué sucede con mis diseños si cierro la sesión de la aplicación?",
    answer:
      "Mientras no desinstales la aplicación, tus creaciones no se perderán y permanecerán en la aplicación, incluso si la cierras.",
  },
  section21: {
    question: "¿Cómo puedo obtener más Ojiis?",
    answer:
      "Cada semana se agregan nuevos Ojiis a la aplicación. ¡Suscríbete al plan Premium para disfrutar de acceso gratuito e ilimitado, además de recibir nuevo contenido cada semana!",
  },
  section22: {
    question: "¿Cómo guardo un Ojii como favorito?",
    answer:
      "Desde la página de inicio, mantenga presionado el Ojii, o en la sección Biblioteca, toque el ícono del corazón cuando su Ojii aparezca en pantalla completa.",
  },
  section23: {
    question: "¿Cómo accedo a mis favoritos?",
    answer:
      "En las páginas de Inicio y Biblioteca, toque el ícono del corazón del lado derecho de la barra de búsqueda.",
  },
  section24: {
    question: "¿Cómo puedo acceder a mis creaciones?",
    answer:
      "En la página de inicio encontrará sus creaciones deslizando el menú de Ojiis hacia la derecha. En la sección Biblioteca seleccione la pestaña Creaciones marcada con un icono de estrella.",
  },
  section25: {
    question: "¿Cómo puedo ver los nuevos Ojiis?",
    answer:
      "En la página de inicio puede encontrar las últimas noticias deslizando el menú de Ojiis hacia la derecha. En la sección Biblioteca seleccione la pestaña Nuevo.",
  },
  section26: {
    question: "¿Cómo elimino las marcas de agua?",
    answer:
      "Al suscribirte al plan Premium, tendrás la oportunidad de disfrutar de tu contenido ilimitado y sin filigranas.",
  },
};

import { useEffect, useState, useContext } from "react";
import { ASSETS_DOMAIN, ASSETS_PATHS } from "../../ojiis-assets";
import { translations } from "../../translations/texts";
import { isOnMobile } from "../../utilities";
import Header from "../Header/Header";
import "./Homepage.scss";
import { CombinedContext } from "../context/combinedContext";


export const Homepage = () => {
  const { language } = useContext(CombinedContext)
  let [mediaGalleryIndex, setMediaGalleryIndex] = useState(1);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const changeMediaGalleryIndex = (index) => {
    setMediaGalleryIndex(index);
  };

  return (
    <div>
      <Header />
      <div className="homepage">
        <section>
          <div className="section-presentation">
            <div>
              <p className="line_1 --section_1">
                <i>{translations[language].homepage.section_1_line_1}</i>
              </p>
              <p className="line_2 --section_1">
                <i>{translations[language].homepage.section_1_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_1_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_1_line_4}
              </p>
              <p className="line_5">
                {translations[language].homepage.section_1_line_5}
              </p>
            </div>
          </div>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --mindblown"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.MINDBLOWN}`}
            />
          </div>
        </section>
        <img
          alt=""
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.ZIGZAG_LINES_TOP}`}
        />
        <section className={isOnMobile() ? "--column" : ""}>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.TILES}`}
            />
          </div>
          <div className="section-presentation --inverse">
            <div>
              <p className="line_1">
                <i>{translations[language].homepage.section_2_line_1}</i>
              </p>
              <p className="line_2">
                <i>{translations[language].homepage.section_2_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_2_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_2_line_4}
              </p>
              <p className="line_5">
                {translations[language].homepage.section_2_line_5}
              </p>
            </div>
          </div>
        </section>
        <section style={{ position: "relative", display: "block", padding: 0 }}>
          <img
            alt=""
            className="ar-image"
            src={`${ASSETS_DOMAIN}${
              isOnMobile()
                ? ASSETS_PATHS.AR_SECTION.AR_FULL_MOBILE
                : ASSETS_PATHS.AR_SECTION.DESKTOP_AR_OUTSIDE
            }`}
          />
          <div className="section-presentation --ar-presentation">
            <div>
              <p className="line_1 --section_3">
                <i
                  dangerouslySetInnerHTML={{
                    __html: translations[language].homepage.section_3_line_1,
                  }}
                />
              </p>
              <p className="line_2 --section_3">
                <i>{translations[language].homepage.section_3_line_2}</i>
              </p>
              <p className={`line_3 ${isOnMobile() ? "--alt" : ""}`}>
                {translations[language].homepage.section_3_line_3}
              </p>
            </div>
          </div>
        </section>
        <section className={isOnMobile() ? "--column" : ""}>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --painter"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.PAINTER}`}
            />
          </div>
          <div className="section-presentation --inverse">
            <div>
              <p className="line_1 --section_4">
                <i>{translations[language].homepage.section_4_line_1}</i>
              </p>
              <p className="line_2 --section_4">
                <i>{translations[language].homepage.section_4_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_4_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_4_line_4}
              </p>
            </div>
          </div>
        </section>
        <img
          alt=""
          className="below-painter"
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.ZIGZAG_LINES_BOTTOM}`}
        />
        <section>
          <div className="section-presentation">
            <div>
              <p className="line_1">
                <i>{translations[language].homepage.section_5_line_1}</i>
              </p>
              <p className="line_2">
                <i>{translations[language].homepage.section_5_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_5_line_3}
              </p>
            </div>
          </div>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --picture"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.PICTURE}`}
            />
          </div>
        </section>
        <img
          alt=""
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.ZIGZAG_LINES_TOP}`}
        />
        <section className={isOnMobile() ? "--column" : ""}>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --fireworks"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.FIREWORKS}`}
            />
          </div>
          <div className="section-presentation --inverse">
            <div>
              <p className="line_1 --section_6">
                <i>{translations[language].homepage.section_6_line_1}</i>
              </p>
              <p className="line_2 --section_6">
                <i>{translations[language].homepage.section_6_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_6_line_3}
              </p>
            </div>
          </div>
        </section>
        <img
          alt=""
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.ZIGZAG_LINES_BOTTOM}`}
        />
        <section>
          <div className="section-presentation">
            <div>
              <p className="line_1 --section_7">
                <i>{translations[language].homepage.section_7_line_1}</i>
              </p>
              <p className="line_2 --section_7">
                <i>{translations[language].homepage.section_7_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_7_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_7_line_4}
              </p>
            </div>
          </div>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --voice"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.VOICE}`}
            />
          </div>
        </section>
        <img
          alt=""
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.ZIGZAG_LINES_TOP}`}
        />
        <section className={isOnMobile() ? "--column" : ""}>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.CAT}`}
            />
          </div>
          <div className="section-presentation --inverse">
            <div>
              <p className="line_1">
                <i>{translations[language].homepage.section_8_line_1}</i>
              </p>
              <p className="line_2">
                <i>{translations[language].homepage.section_8_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_8_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_8_line_4}
              </p>
            </div>
          </div>
        </section>
        <section style={{ position: "relative", display: "block", padding: 0 }}>
          <img
            alt=""
            className="desert-image"
            src={`${ASSETS_DOMAIN}${
              isOnMobile()
                ? ASSETS_PATHS.SCENES_SECTION.SCENES_BACKGROUND
                : ASSETS_PATHS.SCENES_SECTION.SCENES_BACKGROUND
            }`}
          />
          <div
            className={`section-presentation --${
              isOnMobile() ? "desert" : "ar"
            }-presentation`}
          >
            <div>
              <p className="line_1">
                <i>{translations[language].homepage.section_9_line_1}</i>
              </p>
              <p className="line_2">
                <i>{translations[language].homepage.section_9_line_2}</i>
              </p>
              <p className="line_3">
                {translations[language].homepage.section_9_line_3}
              </p>
              <p className="line_3">
                {translations[language].homepage.section_9_line_4}
              </p>
            </div>
          </div>
          <div className="section-wrap-img --thisrty">
            <img
              alt=""
              className="section-img --thisrty"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.SCENES_SECTION.THIRSTY}`}
            />
          </div>
        </section>
        <section className={isOnMobile() ? "--column" : ""}>
          <div className="section-wrap-img">
            <img
              alt=""
              className="section-img --selfie"
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.OJIIS_IMAGES.SELFIE}`}
            />
          </div>
          <div className="section-presentation --inverse">
            <div>
              <p className="line_1">
                <i>{translations[language].homepage.section_10_line_1}</i>
              </p>
              <p className="line_2">
                <i
                  dangerouslySetInnerHTML={{
                    __html: translations[language].homepage.section_10_line_2,
                  }}
                />
              </p>
              <p className="line_3">
                {translations[language].homepage.section_10_line_3}
              </p>
              <p className="line_4">
                {translations[language].homepage.section_10_line_4}
              </p>
            </div>
          </div>
        </section>
        <img
          alt=""
          className="horizontal-line"
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.LANDING_PAGE_LINES.HORIZONTAL_LINE}`}
        />
        <div className="media-gallery">
          <h1>
            <i>{translations[language].homepage.media_gallery}</i>
          </h1>
          <div className="media-gallery-imgs-wrap">
            <div className="media-gallery-main">
              <img
                alt=""
                src={`${ASSETS_DOMAIN}${
                  isOnMobile()
                    ? ASSETS_PATHS[`VIDEO_${mediaGalleryIndex}`].Portrait
                    : ASSETS_PATHS[`VIDEO_${mediaGalleryIndex}`].Landscape
                }`}
              />
            </div>
            <div className="media-gallery-other">
              <div>
                <img
                  onClick={() => changeMediaGalleryIndex(1)}
                  alt=""
                  src={`${ASSETS_DOMAIN}${
                    isOnMobile()
                      ? ASSETS_PATHS.VIDEO_1.Portrait
                      : ASSETS_PATHS.VIDEO_1.Landscape
                  }`}
                />
              </div>
              <div>
                <img
                  alt=""
                  onClick={() => changeMediaGalleryIndex(2)}
                  src={`${ASSETS_DOMAIN}${
                    isOnMobile()
                      ? ASSETS_PATHS.VIDEO_2.Portrait
                      : ASSETS_PATHS.VIDEO_2.Landscape
                  }`}
                />
              </div>
              <div>
                <img
                  alt=""
                  onClick={() => changeMediaGalleryIndex(3)}
                  src={`${ASSETS_DOMAIN}${
                    isOnMobile()
                      ? ASSETS_PATHS.VIDEO_3.Portrait
                      : ASSETS_PATHS.VIDEO_3.Landscape
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

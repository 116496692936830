export const faq = {
  section1: {
    question: "What is an Ojii?",
    answer:
      "They are in 3D, they move, they talk, there are funny, they are alive! They are the next generation of Emojis.",
  },
  section2: {
    question: "Can I share my Ojiis?",
    answer:
      "Yes! The App provides multiple ways to save and share your Ojiis and creations, including 3D animation, GIFs, and you even have the option to select an animation frame to send a sticker.",
  },
  section3: {
    question: "How do I contact support for help?",
    answer:
      "For technical support, kindly send an email to support@ojiis.com and our team will be happy to assist you.",
  },
  section4: {
    question: "What happens to my creations if I uninstall the app?",
    answer:
      "Uninstalling the app will result in the loss of any creations you've made.",
  },
  section5: {
    question:
      "What happens to my creation with “Premium” props if I end my subscription?",
    answer:
      "If your subscription ends, creations that includes Premium props will become temporarily inaccessible. You can regain access to them by resubscribing to the Premium plan.",
  },
  section6: {
    question:
      "What happens if I change my subscription from a monthly plan to a yearly plan?",
    answer:
      "If you upgrade to a yearly plan, you will receive a credit based on the number of days left on your current subscription. Your yearly subscription will automatically start when your monthly plan ends.",
  },
  section7: {
    question: "Is the app free?",
    answer:
      "The app is free for everyone with limited content and features. To have full access to the content and features, you will need to subscribe to the Premium plan.",
  },
  section8: {
    question: "What does a Premium subscription include?",
    answer:
      "The Premium subscription gives you access to unlimited content and unlocks all features, and customization options, plus new releases every week.",
  },
  section9: {
    question: "How do I cancel my subscription?",
    answer: "You can cancel your subscription in your device's settings.",
  },
  section10: {
    question: "How do I delete my account?",
    answer:
      'If you wish to delete your account, please click <a href="mailto:support@ojiis.com">here</a>.',
  },
  section11: {
    question: "What can I expect from Ojiis in the future?",
    answer:
      "Every week, we'll be adding new Ojiis, features, and customization options to keep your content fresh and exciting!",
  },
  section12: {
    question: "Can I have multiple accounts on the same device?",
    answer: "This feature is not supported at this time.",
  },
  section13: {
    question: "Can I use the Ojiis app without internet connection?",
    answer:
      "Yes, but you will need an internet connection to send your Ojiis or to access your new content.",
  },
  section14: {
    question: "What languages are supported?",
    answer:
      "Our app supports English, French, Spanish, and German. You can switch between these languages at any time by clicking on the relevant icon below",
  },
  section15: {
    question: "Can I use the search bar outside my current language?",
    answer:
      "The search bar and keywords are linked to the language currently selected in the app.",
  },
  section16: {
    question: "Where are my creations saved?",
    answer:
      "All of your in-app creations are saved within the app and on your device. However, imported photos, images, custom text, and your voice are not saved in the app to ensure customer privacy.",
  },
  section17: {
    question: "How do I delete a creation?",
    answer:
      'To delete a creation, go to your Creations tab (indicated with a "star" icon) in the library and select the creation you want to delete, then click on the delete icon.',
  },
  section18: {
    question: "What can I import from my device to customize my Ojiis?",
    answer:
      "You can import your own photos/images to customize your Ojiis backgrounds.",
  },
  section19: {
    question: "Are there parental controls in the app?",
    answer:
      "Yes, you can enable/disable parental controls in the Settings page.",
  },
  section20: {
    question: "What happens to my creations if I log out?",
    answer:
      "You can access your creations anytime as long as the app is installed on your device even after logging out. No need to worry about losing your progress!",
  },
  section21: {
    question: "How do I get more Ojiis?",
    answer:
      "New Ojiis are added every week. Subscribe to Premium plan to enjoy unlimited access and free new content weekly!",
  },
  section22: {
    question: "How can I save an Ojii as my favorite?",
    answer:
      "From the Home page, long press on the Ojii, or from the Library page, tap on the heart icon when viewing your Ojii in full screen.",
  },
  section23: {
    question: "How can I access my favorites?",
    answer:
      "In both the Home and Library page, tap on the heart icon right of the search bar.",
  },
  section24: {
    question: "How do I access my creations?",
    answer:
      "From the Home page, you'll be able to find your creations by swiping the Ojiis menu. In the Library page, go to the Creations tab marked with a star icon.",
  },
  section25: {
    question: "How can I see the latest Ojiis?",
    answer:
      "From the Home page, you'll be able to find the latest release by swiping the Ojiis menu. In the Library page, go to the NEW icon tab.",
  },
  section26: {
    question: "How do I remove the watermarks?",
    answer: "You can remove all watermarks by subscribing to Premium plan.",
  },
};

import { useEffect, useContext } from "react";
import { LANG_ABREVIATION } from "../../../constants/language";
import { translations } from "../../../translations/texts";
import Header from "../../Header/Header";
import "./PrivacyPolicy.scss";
import { PrivacyPolicyDE } from "./PrivacyPolicyDE";
import { PrivacyPolicyEN } from "./PrivacyPolicyEN";
import { PrivacyPolicyFR } from "./PrivacyPolicyFR";
import { PrivacyPolicySP } from "./PrivacyPolicySP";
import { CombinedContext } from "../../context/combinedContext";

export const PrivacyPolicy = () => {
  const { language } = useContext(CombinedContext)


  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getContent = () => {
    switch (language.toUpperCase()) {
      case LANG_ABREVIATION.EN: {
        return <PrivacyPolicyEN />;
      }

      case LANG_ABREVIATION.FR: {
        return <PrivacyPolicyFR />;
      }

      case LANG_ABREVIATION.DE: {
        return <PrivacyPolicyDE />;
      }

      case LANG_ABREVIATION.SP: {
        return <PrivacyPolicySP />;
      }

      default: {
        return <PrivacyPolicyEN />;
      }
    }
  };

  return (
    <div className="privacy-policy">
      <Header nonHome />
      <section>
        <h1>
          <i>{translations[language].footer.privacy_policy}</i>
        </h1>
        {getContent()}
      </section>
    </div>
  );
};

export default PrivacyPolicy;

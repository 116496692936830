import { homepage } from "../components/Homepage/Homepage.lang.de";
import { footer } from "../components/Footer/Footer.lang.de";
import { faq } from "../components/Pages/FAQ/FAQ.lang.de";
import { contact_us } from "../components/Pages/ContactUs/ContactUs.lang.de";

export const de = {
  homepage,
  footer,
  faq,
  contact_us,
  see_more: "Mehr Sehen",
};

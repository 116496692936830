export const faq = {
  section1: {
    question: "Was ist ein Ojii ?",
    answer:
      "Sie sind in 3D, sie bewegen sich, sie sprechen, sie sind lustig, sie leben! Sie sind die nächste Generation von Emojis.",
  },
  section2: {
    question: "Kann ich meine Ojiis teilen?",
    answer:
      "Ja! Die App bietet mehrere Möglichkeiten zum Speichern und Teilen Ihrer Ojiis und Kreationen, einschließlich 3D-Animation, GIFs, und Sie haben sogar die Möglichkeit, einen Animationsrahmen auszuwählen, um einen Aufkleber zu senden.",
  },
  section3: {
    question: "Wie kontaktiere ich den Support, um Hilfe zu erhalten?",
    answer:
      "Für technischen Support senden Sie bitte eine E-Mail an support@ojiis.com und unser Team hilft Ihnen gerne weiter.",
  },
  section4: {
    question:
      "Was passiert mit meinen Kreationen, wenn ich die App deinstalliere?",
    answer:
      "Das Deinstallieren der App führt zum Verlust aller von Ihnen erstellten Kreationen.",
  },
  section5: {
    question:
      "Was passiert mit meiner Kreation mit „Premium“-Requisiten, wenn ich mein Abonnement beende?",
    answer:
      "Wenn Ihr Abonnement endet, werden Kreationen, die Premium-Requisiten enthalten, vorübergehend unzugänglich. Sie können wieder darauf zugreifen, indem Sie den Premium-Plan erneut abonnieren.",
  },
  section6: {
    question:
      "Was passiert, wenn ich mein Abonnement von einem Monatsplan zu einem Jahresplan ändere?",
    answer:
      "Wenn Sie auf einen Jahresplan upgraden, erhalten Sie eine Gutschrift basierend auf der Anzahl der verbleibenden Tage Ihres aktuellen Abonnements. Ihr Jahresabonnement beginnt automatisch, wenn Ihr Monatsplan endet.",
  },
  section7: {
    question: "Ist die App kostenlos?",
    answer:
      "Die App ist für alle kostenlos mit eingeschränkten Inhalten und Funktionen. Um vollen Zugriff auf die Inhalte und Funktionen zu haben, müssen Sie den Premium-Plan abonnieren.",
  },
  section8: {
    question: "Was beinhaltet ein Premium-Abonnement?",
    answer:
      "Das Premium-Abonnement bietet Ihnen Zugriff auf unbegrenzte Inhalte und schaltet alle Funktionen und Anpassungsoptionen sowie jede Woche neue Versionen frei.",
  },
  section9: {
    question: "Wie kündige ich mein Abonnement?",
    answer:
      "Sie können Ihr Abonnement in den Einstellungen Ihres Geräts kündigen.",
  },
  section10: {
    question: "Wie lösche ich mein Konto?",
    answer:
      'Wenn Sie Ihr Konto löschen möchten, klicken Sie bitte <a href="mailto:support@ojiis.com">hier</a>.',
  },
  section11: {
    question: "Was kann ich in Zukunft von Ojiis erwarten?",
    answer:
      "Jede Woche fügen wir neue Ojiis, Funktionen und Anpassungsoptionen hinzu, um Ihre Inhalte frisch und spannend zu halten!",
  },
  section12: {
    question: "Kann ich mehrere Konten auf demselben Gerät haben?",
    answer: "Diese Funktion wird derzeit nicht unterstützt.",
  },
  section13: {
    question: "Kann ich die Ojiis-App ohne Internetverbindung verwenden?",
    answer:
      "Ja, aber Sie benötigen eine Internetverbindung, um Ihre Ojiis zu senden oder auf Ihre neuen Inhalte zuzugreifen.",
  },
  section14: {
    question: "Welche Sprachen werden unterstützt?",
    answer:
      "Unsere App unterstützt Englisch, Französisch, Spanisch und Deutsch. Auf der Seite Einstellungen können Sie jederzeit zwischen diesen Sprachen wechseln.",
  },
  section15: {
    question:
      "Kann ich die Suchleiste außerhalb meiner aktuellen Sprache verwenden?",
    answer:
      "Die Suchleiste und Schlüsselwörter sind mit der aktuell in der App ausgewählten Sprache verknüpft.",
  },
  section16: {
    question: "Wo werden meine Kreationen gespeichert?",
    answer:
      "Alle Ihre In-App-Kreationen werden in der App und auf Ihrem Gerät gespeichert. Importierte Fotos, Bilder, benutzerdefinierter Text und Ihre Stimme werden jedoch nicht in der App gespeichert, um die Privatsphäre der Kunden zu gewährleisten.",
  },
  section17: {
    question: "Wie lösche ich eine Kreation?",
    answer:
      "Um eine Kreation zu löschen, gehen Sie zu Ihrem Kreationen-Tab (mit einem „Stern“-Symbol gekennzeichnet) in der Bibliothek und wählen Sie die Kreation aus, die Sie löschen möchten, und klicken Sie dann auf das Löschsymbol.",
  },
  section18: {
    question:
      "Was kann ich von meinem Gerät importieren, um meine Ojiis anzupassen?",
    answer:
      "Sie können Ihre eigenen Fotos/Bilder importieren, um Ihre Ojiis-Hintergründe anzupassen.",
  },
  section19: {
    question: "Gibt es eine Kindersicherung in der App?",
    answer:
      "Ja, Sie können die Kindersicherung auf der Seite „Einstellungen“ aktivieren/deaktivieren.",
  },
  section20: {
    question: "Was passiert mit meinen Kreationen, wenn ich mich abmelde?",
    answer:
      "Sie können jederzeit auf Ihre Kreationen zugreifen, solange die App auf Ihrem Gerät installiert ist, auch nach dem Abmelden. Sie müssen sich keine Sorgen machen, dass Sie Ihren Fortschritt verlieren!",
  },
  section21: {
    question: "Wie bekomme ich mehr Ojiis?",
    answer:
      "Neue Ojiis werden jede Woche hinzugefügt. Abonnieren Sie den Premium-Plan, um unbegrenzten Zugriff und wöchentlich kostenlose neue Inhalte zu genießen!",
  },
  section22: {
    question: "Wie kann ich einen Ojii als meinen Favoriten speichern?",
    answer:
      "Drücken Sie auf der Startseite lange auf Ojii oder tippen Sie auf der Bibliotheksseite auf das Herzsymbol, wenn Sie Ihr Ojii im Vollbildmodus anzeigen.",
  },
  section23: {
    question: "Wie kann ich auf meine Favoriten zugreifen?",
    answer:
      "Tippen Sie sowohl auf der Startseite als auch auf der Bibliotheksseite auf das Herzsymbol rechts neben der Suchleiste.",
  },
  section24: {
    question: "Wie greife ich auf meine Kreationen zu?",
    answer:
      "Auf der Startseite können Sie Ihre Kreationen finden, indem Sie über das Ojiis-Menü wischen. Wechseln Sie auf der Seite „Bibliothek“ zur Registerkarte „Kreationen“, die mit einem Sternsymbol gekennzeichnet ist.",
  },
  section25: {
    question: "Wie kann ich die neusten Ojiis sehen?",
    answer:
      "Auf der Startseite können Sie die neueste Version finden, indem Sie über das Ojiis-Menü wischen. Wechseln Sie auf der Bibliotheksseite zur Registerkarte NEU.",
  },
  section26: {
    question: "Wie entferne ich die Wasserzeichen?",
    answer:
      "Sie können alle Wasserzeichen entfernen, indem Sie den Premium-Plan abonnieren.",
  },
};

import "./SocialMedia.scss";
import { ASSETS_DOMAIN, ASSETS_PATHS } from "../../ojiis-assets";
import { useContext } from "react";
import { CombinedContext } from "../context/combinedContext";

const SocialMedia = () => {

  return (
    <div className="social-media">
      <img
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            "https://m.facebook.com/people/Ojiis-App/100069042302442/",
            "_blank"
          )
        }
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.SOCIAL_ICONS.FACEBOOK_ICON}`}
      />
      <img
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            "https://www.youtube.com/channel/UCtT-F8a2EQT_eL_4k9AnB4g?view_as=subscriber",
            "_blank"
          )
        }
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.SOCIAL_ICONS.YOUTUBE_ICON}`}
      />
      <img
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open("https://www.instagram.com/ojiis.app", "_blank")
        }
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.SOCIAL_ICONS.INSTAGRAM_ICON}`}
      />
      <img
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open("https://www.tiktok.com/@ojiis.app", "_blank")
        }
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.SOCIAL_ICONS.TIKTOK_ICON}`}
      />
      <img
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            "https://www.snapchat.com/add/ojiis.app?share_id=dxvHM03SQ9qAIoJsRpvbkQ&locale=es_PE&sid=fc3f25a831bb4bc1bbf7be67c6cf5181",
            "_blank"
          )
        }
        alt=""
        src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.SOCIAL_ICONS.SNAPCHAT_ICON}`}
      />
    </div>
  );
};

export default SocialMedia;

import React, { createContext, useContext, useState } from "react";
import { languageOnLoad } from "./allStates/language";

export const CombinedContext = createContext();

export const CombinedContextProvider = ({ children }) => {
	const [language, setLanguage] = useState(languageOnLoad());

	return ( 
        <CombinedContext.Provider value={{ language, setLanguage }}>{children}</CombinedContext.Provider>
    );
};

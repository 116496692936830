export const TOS_DE = () => {
  return (
    <div>
      <p className="c5">
        <span className="c1">Effective Date: 12/01/2019</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The terms of this agreement (&quot;Terms of Use&quot;) govern the
          relationship between You and Ojiis, (which, together with its
          subsidiaries and affiliates, is herein in referred to as
          &ldquo;Ojiis&rdquo; or &ldquo;Us&rdquo;) --regarding Your use of the
          application distributed by Ojiis that You installed, as well as any
          and all related updates and upgrades that replace or supplement the
          application in any respect (the &ldquo;Application&rdquo; or
          &ldquo;App&rdquo;), license shall govern.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5" id="h.gjdgxs">
        <span>
          The collection, use, unless any such update or upgrade is accompanied
          by a separate license in which case the separate and disclosure of
          Your information (including personal information) is governed by
          Ojiis&rsquo; Privacy Policy which is located online at{" "}
        </span>
        <span className="c6">https://ojiis.com/privacy-policy</span>
        <span className="c1">&nbsp;(&ldquo;Privacy Policy&rdquo;).</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span>
          The use of the Application is also governed by Ojiis&rsquo; Video
          Policy, located online at{" "}
        </span>
        <span className="c6">https://ojiis.com/video-policy</span>
        <span className="c1">
          &nbsp;(&ldquo;Video Policy&rdquo;), which is incorporated in the
          Agreement by this reference.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          If You download the Application from any online market for software
          applications that operate on devices powered by the operating system
          (e.g., Apple App Store and Google Play, or if You play the Application
          online (when this is available), You acknowledge that You have
          reviewed and accepted the terms of service applicable to such online
          market, platform, or operating system (&ldquo;Special Terms&rdquo;).{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO
          THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE,
          PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. USE OF THE
          SERVICE IS VOID WHERE PROHIBITED.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The main characteristics of each Application can be found in the app
          stores&rsquo; descriptions.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">1. LICENSE</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          1.1 Grant of a Limited License to Use the Service
        </span>
      </p>
      <p className="c5">
        <span className="c1">
          Through the use of the Application, You are acquiring and Ojiis grants
          You a personal, revocable and limited, non-exclusive,
          non-transferable, non-sublicensable license to access and use the
          Service for your own non-commercial entertainment purposes. You agree
          not to use the Service for any other purpose.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">1.2 LICENSE LIMITATIONS</span>
      </p>
      <p className="c5">
        <span className="c1">
          Any use of the Application in violation of these License Limitations
          is strictly prohibited and can result in the immediate revocation of
          Your Limited License and may trigger Your liability for violations of
          law.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Unless You have received prior written authorization from Ojiis, You
          agree not to:
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <ul className="c3 lst-kix_list_3-0 start">
        <li className="c4 li-bullet-0">
          <span className="c1">
            use cheats, automation software, bots, hacks or any other
            unauthorized software designed to modify or interfere with the
            Application and/or any files that are part of the Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            commercially exploit the Ojiis Application, its Characters, Design,
            2D or 3D models, Artwork or any other part of the Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            create Derivative Works of the Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            access or use the Application with any technology or means other
            than those provided in the Application, or through other explicitly
            authorized means that Ojiis may designate;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            make a copy of the Application available on a network where it could
            be used by multiple users;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            register domain names, social media accounts or related addresses
            that include Ojiis trademarks;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use the Application or related assets and/or Ojiis trademarks on or
            in connection with content that (i) promotes cheats, hacks,
            violence, discrimination, inappropriate themes, illegal activities,
            or sexually explicit content; (ii) makes untrue, dishonest,
            disparaging, or libellous statements about Ojiis and/or its
            products, employees, and agents; and/or (iii) contains other
            objectionable content;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            resell, copy, transfer, distribute, display, translate, or modify
            the Application or make derivative works of the Application or any
            part thereof;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            re-use, copy, or distribute text, pictures, music, barcodes, video,
            data, hyperlinks, displays or any other content provided by the
            Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            redistribute, sublicense, rent, publish, perform, sell, assign,
            lease, market, transfer, or otherwise make the Application available
            to third parties;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            seek to disable, reverse engineer, decompile or otherwise attempt to
            extract the source code of the Application or any part thereof,
            unless this is expressly permitted or required by applicable law or
            by the licensing terms governing the use of any open-sourced
            components included within the Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            delete, obscure, or in any manner alter any warning, notice
            (including but not limited to any copyright or other proprietary
            rights notice), or link that appears in the Application;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use the Application when operating vehicles;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            remove or alter Ojiis trademarks or logos or legal notices included
            in the Application or related assets;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use the service to try to gain unauthorized access to any service,
            data, account or network by any means;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            post any information that contains nudity, violence, or offensive
            subject matter or that contains a link to such content;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            make an attempt to or harass, abuse, threaten, defame or otherwise
            infringe or violate the rights of any other party;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use the Application in any way that is unlawful, fraudulent, or
            deceptive;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use technology or any other means to access Ojiis proprietary
            information not authorized by Ojiis;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            use or launch any automated system to access Ojiis&rsquo; website or
            computer systems;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            attempt to introduce viruses or any other malicious computer code
            that interrupts, destroys, or limits the functionality of any
            computer software, hardware, or telecommunications equipment;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            attempt to gain unauthorized access to Ojiis&rsquo; computer network
            or user accounts;
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            encourage conduct that would constitute a criminal offense, or would
            give rise to civil liability
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Ojiis reserves the right to determine in its sole discretion what kind
          of conduct is considered to be in violation of this Terms of Service.
          Furthermore, Ojiis reserves the right to take appropriate action as a
          result of Your violation of the terms of this Terms of Service,
          including but not limited to prohibiting You from using the Ojiis
          Application in whole or in part.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">1.3 ALLOWABLE USES OF THE APPLICATION</span>
      </p>
      <p className="c5">
        <span className="c1">
          USE OF THE APPLICATION AND ANY OF YOUR INFORMATION TRANSMITTED IN
          CONNECTION WITH THE APPLICATION IS LIMITED TO THE FUNCTIONALITY OF THE
          APPLICATION AND TO THE LIMITED LICENSE GRANT AS OUTLINED ABOVE. YOU
          MAY NOT OTHERWISE USE THE APPLICATION OR ANY COMPONENT OF IT, EXCEPT
          AS EXPRESSLY AUTHORIZED BY OJIIS IN WRITING IN ADVANCE.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">
          2. ACCESSING AND DOWNLOADING THE APPLICATION FROM THE APPLE APP STORE
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The following applies to any Application accessed through or
          downloaded from the Apple App Store (&ldquo;App Store Sourced
          Application&rdquo;):
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <ul className="c3 lst-kix_list_4-0 start">
        <li className="c4 li-bullet-0">
          <span className="c1">
            You acknowledge and agree that (i) this Terms of Service is
            concluded between You and Ojiis only, and not Apple; and (ii) Ojiis,
            not Apple, is solely responsible for the App Store Sourced
            Application and content thereof. Your use of the App Store Sourced
            Application must comply with the Apple App Store Terms of Service.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You will use the App Store Sourced Application only (i) on an
            Apple-branded product that runs iOS (Apple&rsquo;s proprietary
            operating system software); and (ii) as permitted by the
            &ldquo;Usage Rules&rdquo; set forth in the Apple App Store Terms of
            Service.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to the App Store
            Sourced Application.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            In the event of any failure of any App Store Sourced Application to
            conform to any applicable warranty, You may notify Apple, and Apple
            will refund the purchase price for the App Store Sourced Application
            to You and to the maximum extent permitted by applicable law, Apple
            will have no other warranty obligation whatsoever with respect to
            the App Store Sourced Application. As between Ojiis and Apple, any
            other claims, losses, liabilities, damages, costs, or expenses
            attributable to any failure to conform to any warranty will be the
            sole responsibility of Ojiis.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You and Ojiis acknowledge that, as between Ojiis and Apple, Apple is
            not responsible for addressing any claims You have or any claims of
            any third party relating to the App Store Sourced Application in
            Your possession and use of the App Store Sourced Application,
            including but not limited to (i) product liability claims; (ii) any
            claim that an App Store Sourced Application fails to conform to any
            applicable legal or regulatory requirement; and (iii) claims arising
            under consumer protection or similar legislation.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You and Ojiis acknowledge that, in the event of any third-party
            claim that an App Store Sourced Application or Your possession and
            use of that App Store Source Application infringes that third
            party&rsquo;s intellectual property rights, as between Ojiis and
            Apple, Ojiis, not Apple, will be solely responsible for the
            investigation, defence, settlement and discharge of any such
            intellectual property infringement claim to the extent required by
            this Terms of Service.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            By using the App Store Sourced Application, You represent and
            warrant that (i) You are not located in a country that is subject to
            a U.S. Government embargo, or that has been designated by the U.S.
            Government as a &ldquo;terrorist supporting&rdquo; country; and (ii)
            You are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            Without limiting any other terms of this Terms of Service, You must
            comply with all applicable third-party terms of agreement when using
            the App Store Sourced Application.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">3. USER CONTENT</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Notwithstanding anything to the contrary stated in this Terms of
          Service, You are allowed to create and use &ldquo;User Content&rdquo;
          (as defined below) subject to the License Limitations and Specific
          Rules for User Content defined herein.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Any modification of User Content created by the Application or
          incorporation of such User Content into other works requires explicit
          written approval from Ojiis. You are expressly prohibited from
          sub-licensing, renting, leasing, transferring, or otherwise
          distributing the Application or rights to use the Application. This
          Terms of Service does not entitle You to receive, and does not
          obligate Ojiis to provide, hard-copy documentation, support, telephone
          assistance, or enhancements or updates to the Application.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">3.1 SUBMISSIONS OF USER CONTENT</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Application may allow You to create content such as videos, data,
          photographs, messages, graphics, text, and other information
          (&ldquo;User Content&rdquo;), and to share such User Content with
          Ojiis or with other sites, including social networking sites, as You
          may designate. You are solely responsible for Your User Content that
          you upload, publish, display, link to, or otherwise make available
          (hereinafter, &ldquo;share&rdquo;) on or through the Application, and
          You agree that Ojiis is only acting as a passive conduit for Your
          online distribution and publication of Your User Content.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Application may enable sharing of User Content in two ways: (i)
          one-to-one (e.g., through SMS, email, WeChat, Facebook, and other
          messaging platforms); and (ii) one-to-many (e.g., Tik Tok, Facebook,
          YouTube, Twitter, Everyplay, email). Unless explicitly stated
          otherwise herein, no User Content is stored by Ojiis and Ojiis only
          acts as a passive conduit, offering You a platform to create User
          Content and (solely to help You and for the sake of improving user
          experience) referring You to a third-party service (e.g., email
          client, Facebook, Twitter, or YouTube application) where You can
          decide what User Content to share, how, when, and with whom.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          You understand that unless explicitly stated otherwise herein Ojiis
          has no control over the access to Your User Content, the sharing of
          Your User Content, the privacy or confidentiality of Your User Content
          and that You, together with the third-party service providers (e.g.,
          Facebook, YouTube, Twitter), are subject to the terms of service of
          said third-party service providers and have the sole control over such
          User Content.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          You agree not to share User Content that: (i) may create a risk of
          harm, loss, physical or mental injury, emotional distress, death,
          disability, disfigurement, or physical or mental illness to You, to
          any other person, or to any animal; (ii) may create a risk of any
          other loss or damage to any person or property; (iii) seeks to harm or
          exploit children by exposing them to inappropriate content, asking
          them for personally identifiable details, or otherwise; (iv) may
          constitute or contribute to a crime or tort; (v) contains any
          information or content that Ojiis deems to be unlawful, harmful,
          abusive, racially or ethnically offensive, defamatory, infringing,
          invasive of personal privacy or publicity rights, harassing,
          humiliating to other people (publicly or otherwise), libellous,
          threatening, profane, or otherwise objectionable; (vi) contains any
          information or content that is illegal (including, without limitation,
          the disclosure of insider information under securities law or of
          another party&rsquo;s trade secrets); (vii) contains any information
          or content that You do not have a right to make available under any
          law or under contractual or fiduciary relationships; or (viii)
          contains any information or content that You know is not correct and
          current.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          If You believe that certain User Content shared through the
          Application, but stored on third-party services (e.g., Facebook,
          YouTube, etc.), is infringing Your rights, please contact the
          respective third-party service provider where such User Content is
          stored.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          You agree that any User Content that You share does not and will not
          violate third-party rights of any kind, including and without
          limitation any &ldquo;Intellectual Property Rights&rdquo; (as defined
          in Section 7 herein) or rights of publicity and privacy. To the extent
          that Your User Content contains music, You hereby represent that You
          are the owner of all the copyright rights, including and without
          limitation, the performance and the mechanical and sound recording
          rights, with respect to each and every musical composition (including
          lyrics) and sound recording contained in such User Content and that
          You have the power to grant the license granted below.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span>
          CREATING AND SHARING OF USER CONTENT AS DESCRIBED IN THIS SECTION IS
          NOT ENABLED FOR USERS WHO IDENTIFY THEMSELVES AS UNDER THE AGE OF 13.
          MORE INFORMATION ABOUT THE COLLECTION OF INFORMATION RELATING TO
          CHILDREN UNDER THE AGE OF 13 IS DESCRIBED IN OUR PRIVACY POLICY,
          AVAILABLE AT{" "}
        </span>
        <span className="c7 c6">https://ojiis.com/privacy-policy</span>
        <span className="c6">&nbsp;.</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">3.2 LICENSE GRANT FOR THE USER CONTENT</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          By creating User Content through Ojiis Applications, You expressly
          grant, and You represent and warrant that You have a right to grant to
          Ojiis a royalty-free, sub-licensable, transferable, perpetual,
          irrevocable, non-exclusive, worldwide license to use, reproduce,
          modify, publish, monetize, list information regarding, edit,
          translate, distribute, publicly perform, publicly display, and make
          derivative works of, and otherwise exploit, all such User Content, as
          well as Your name, user name, voice, and/or likeness as contained in
          Your User Content, in whole or in part, and in any form, media or
          technology, whether now known or hereafter developed, for use in
          connection with the Application and Ojiis&rsquo; (and its
          successors&rsquo; and affiliates&rsquo;) business, including and
          without limitation for promoting and redistributing part or all of the
          Application (and any derivative works thereof) or any Ojiis
          Application in any media formats and through any media collections and
          in connection with various advertisements, such as banner ads, mobile
          ads, and video ads. You also hereby grant each user of the Application
          a non-exclusive license to access Your User Content through the
          Application, and to use, reproduce, distribute, display, and perform
          such User Content as permitted through the functionality of the
          Application or the Internet and under this Agreement.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">3.3 SPECIFIC RULES FOR USER CONTENT</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c1">
          By creating User Content through Ojiis Applications you agree to these
          Specific Rules that outline Your use of any Ojiis&rsquo; Intellectual
          Property Rights:
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <ul className="c3 lst-kix_list_1-0 start">
        <li className="c4 li-bullet-0">
          <span className="c1">
            User Content will be used for the sole purpose of non-commercial
            displaying, identifying, and discussing Ojiis Applications in
            accordance with these Specific Rules. You are not allowed to create
            new applications, products, and/or content based on Ojiis
            Intellectual Property Rights, even if such content would be free.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You are not permitted to charge any kind of fees from customers or
            visitors of Your User Content, unless You obtained express prior
            written approval from Ojiis.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            Ojiis, in its sole discretion, reserves the right to monetize or use
            User Content or its parts created through Ojiis Applications. To the
            extent that Ojiis does not use its right to monetize the User
            Content, You may monetize Your User Content by using advertisements,
            however You are solely responsible for ensuring that such
            advertisement is compliant with any and all applicable laws, rules,
            regulations, and developer policies.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            You must not create and use the User Content under the impression
            that Ojiis is a creator or sponsor of Your User Content.
            Furthermore, You are also not allowed to create any materials from
            Ojiis&rsquo; Intellectual Property Rights that are similar to
            Ojiis&rsquo; logos, trademarks, or any other elements of
            Ojiis&rsquo; Intellectual Property Rights. You are not permitted to
            modify in any way Ojiis&rsquo; Intellectual Property Rights without
            the express prior written approval of Ojiis.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            Unless You obtained prior written approval from Ojiis, You are not
            allowed to manufacture or distribute (for free or otherwise) any
            physical items that bear any of Ojiis&rsquo; Intellectual Property
            Rights.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Ojiis reserves the right to determine, in its sole discretion, which
          User Content is in accordance with the above-mentioned Specific Rules
          and may revoke the Limited License in this Terms of Service at any
          time and for any reason.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">3.4 LIMITATION OF LIABILITY FOR USER CONTENT</span>
      </p>
      <p className="c5">
        <span className="c1">
          Ojiis takes no responsibility and assumes no liability for any User
          Content that You or any other user or third party creates with the
          Application or shares through the Application. You shall be solely
          responsible for Your own User Content and the consequences of sharing
          or publishing it. You understand and agree that any loss or damage of
          any kind that occurs as a result of the use of any User Content that
          You send, upload, download, stream, post, transmit, display, share, or
          otherwise make available or access through Your use of the Application
          is solely Your responsibility. In connection with Your User Content
          and in addition to any other representations and warranties, You
          affirm, represent, and warrant the following:
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <ol className="c3 lst-kix_list_2-0 start" start="1">
        <li className="c4 li-bullet-0">
          <span className="c1">
            Your User Content and Ojiis&rsquo; use thereof and the Application
            will not infringe any rights of any third party, including but not
            limited to any Intellectual Property Rights, privacy rights, and
            rights of publicity.{" "}
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            Ojiis may exercise the rights to Your User Content granted under
            this Agreement without liability for payment of any guild fees,
            residuals, payments, fees, or royalties payable under any collective
            bargaining agreement or otherwise.
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c1">
            Ojiis is not responsible for any public display or misuse of Your
            User Content. You understand and acknowledge that You may be exposed
            to User Content that is inaccurate, offensive, indecent,
            objectionable, or inappropriate for children, and You agree that
            Ojiis shall not be liable for any damages you allege to incur as a
            result of such User Content.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">4. ACCESS</span>
      </p>
      <p className="c5">
        <span className="c1">
          You must provide, at Your own expense, the equipment, Internet
          connections, or mobile devices and/or service plans to access and use
          the Application. Ojiis does not guarantee that the Application is
          available in all geographic locations. You acknowledge that when You
          use the Application, Your wireless carrier may charge You fees for
          data, messaging, and/or other wireless access. Check with Your carrier
          to see if there are any such fees that apply to You. You are solely
          responsible for any costs You incur to access the Application from
          Your mobile device and/or PC device. Your right to use the Application
          is also predicated on Your compliance with any applicable terms of
          agreements You have with third parties when using the Application.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">5. IN-APP PURCHASES</span>
      </p>
      <p className="c5">
        <span className="c1">Subscription Details and Renewal Process</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          1. Subscription Terms: By accessing and using our Ojiis application
          ("the App"), you acknowledge and agree to the following subscription
          terms:
        </span>
      </p>
      <p className="c5">
        <span>
          1.1. Free Trial: The App offers a 3-day free trial for new users ("the
          Trial Period"). During the Trial Period, you will have access to the
          full range of features available within the App. At the end of the
          Trial Period, your subscription will automatically convert to a paid
          subscription, as described in section 2 below, unless you cancel your
          subscription before the Trial Period expires.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          1.2. Paid Subscription: Upon the expiration of the Trial Period, you
          may choose to subscribe to the App on a monthly or annual basis ("the
          Subscription"). The Subscription grants you continued access to the
          App's features and content for the selected duration. The subscription
          fee for the App is $4.99 per month or $49.99 per year, as applicable,
          and is subject to change at our discretion.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          1.3. All rates for Subscription are in USD and are subject to change
          and localization to the local currency, which shall be done by the app
          store from which You downloaded the App, meaning that the prices may
          vary.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">2. Subscription Renewal:</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          2.1. Automatic Renewal: Subscriptions will automatically renew unless
          cancelled within 24-hours before the end of the current period. The
          renewal will occur at the current subscription rate, unless otherwise
          notified.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          2.2. Billing Cycle: The billing cycle for your subscription will
          depend on the duration you have chosen. For monthly subscriptions, the
          billing cycle will be every 30 days from the date of your initial
          subscription. For annual subscriptions, the billing cycle will be
          every 365 days from the date of your initial subscription.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          2.3. Payment Authorization: By subscribing to the App, you authorize
          us to charge the applicable subscription fee to the payment method
          associated with your App ID or other authorized payment methods linked
          to your App Store account.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          2.4. Price Changes: We reserve the right to change the subscription
          fees at any time, upon providing reasonable notice to you. Such notice
          may be sent via email or displayed within the App. Your continued use
          of the App after the effective date of the price change constitutes
          your acceptance of the new subscription fee.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          2.3. Payment Authorization: By subscribing to the App, you authorize
          us to charge the applicable subscription fee to the payment method
          associated with your App ID or other authorized payment methods linked
          to your App Store account.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">3. Cancellation and Refunds:</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          3.1. Cancelling Subscription: You may cancel your subscription at any
          time by managing your subscriptions through your App ID settings or by
          following the instructions provided by the App Store. Please note that
          cancelling your subscription does not entitle you to a refund for any
          unused portion of the subscription.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          3.2. Refunds: Except as required by applicable law, all subscription
          fees are non-refundable. In the event of termination or cancellation
          of your subscription, you will not be refunded for any unused portion
          of the subscription period.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">4. Contact Information:</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          If you have any questions or concerns regarding your subscription,
          renewal, or any other related matters, please contact our support team
          at support@ojiis.com .
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c1">
          For more detailed information on how to manage In-App Purchases,
          please see our Frequently Asked Question available at 
        </span>
        <span className="c7 c6"> https://ojiis.com/faq</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>

      <p className="c5">
        <span className="c0">6. TERM AND TERMINATION</span>
      </p>
      <p className="c5">
        <span className="c1">
          The term of this Terms of Service shall commence on the date You
          install or otherwise use the Application and shall end on the earlier
          of the date of Your disposal of the Application or Ojiis&rsquo;
          termination of this Terms of Service. You may terminate this Terms of
          Service by uninstalling the Application.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Termination will not limit any of Ojiis&rsquo; other rights or
          remedies at law or in equity. If any of the platforms disable the
          ability to use the Application on Your device pursuant to Your
          agreement with such platform, any associated license rights with Ojiis
          will terminate as well.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">7. RESERVATION OF RIGHTS</span>
      </p>
      <p className="c5">
        <span className="c1">
          You have obtained a license to the Application and Your rights are
          subject to this Terms of Service. Except as expressly licensed to You
          herein, Ojiis reserves all rights, titles, and interests in the
          Application. This license is limited to the intellectual property
          rights of Ojiis and does not include any rights to other patents or
          intellectual property.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Ojiis retains all rights, titles, and interests in and to the Ojiis
          Intellectual Property Rights, as defined below, whether registered or
          not, and all Applications thereof, except of the copyright of the
          third-party technology. The Ojiis software is protected by applicable
          laws and treaties throughout the world.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          For the purposes of this Terms of Service, &ldquo;Intellectual
          Property Rights&rdquo; means all patent rights, trade names,
          proprietary rights, copyright rights, titles, computer codes,
          audio-visual effects, themes, characters, character names, stories,
          dialog, settings, artwork, sound effects, musical works, mask work
          rights, moral rights, rights of publicity, trademark, trade dress and
          service mark rights, goodwill, trade secret rights and other
          intellectual property rights as may now exist or hereafter come into
          existence, and all applications therefore and registrations, renewals
          and extensions thereof, under the laws of any state, country,
          territory or other jurisdiction.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">8. DISCLAIMER OF WARRANTIES</span>
      </p>
      <p className="c5">
        <span className="c1">
          TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE
          APPLICATION IS PROVIDED TO YOU &ldquo;AS IS&rdquo;, WITH ALL FAULTS,
          WITHOUT WARRANTY OF ANY KIND, WITHOUT PERFORMANCE ASSURANCES OR
          GUARANTEES OF ANY KIND, AND YOUR USE IS AT YOUR SOLE RISK. THE ENTIRE
          RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES WITH YOU. OJIIS
          AND OJIIS&rsquo; LICENSORS (COLLECTIVELY &ldquo;OJIIS&rdquo; FOR
          PURPOSES OF THIS SECTION AND SECTION 10) DO NOT MAKE, AND HEREBY
          DISCLAIM, ANY AND ALL EXPRESS, IMPLIED, OR STATUTORY WARRANTIES,
          INCLUDING IMPLIED WARRANTIES OF CONDITION, UNINTERRUPTED USE,
          MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
          PURPOSE, NONINFRINGEMENT OF THIRD-PARTY RIGHTS, AND WARRANTIES (IF
          ANY) ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          OJIIS DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE
          APPLICATION; THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS; THAT
          OPERATION OF THE APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE; THAT
          THE APPLICATION WILL INTEROPERATE OR BE COMPATIBLE WITH ANY OTHER
          APPLICATION; THAT ANY ERRORS IN THE APPLICATION WILL BE CORRECTED; OR
          THAT THE APPLICATION WILL BE AVAILABLE FOR REINSTALLS TO THE SAME OR
          MULTIPLE DEVICES.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          NO ORAL OR WRITTEN ADVICE PROVIDED BY OJIIS OR ANY AUTHORIZED
          REPRESENTATIVE OR THIRD PARTY SHALL CREATE A WARRANTY. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF, OR LIMITATIONS ON,
          IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
          RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
          LIMITATIONS MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">9. LIMITATION OF LIABILITY</span>
      </p>
      <p className="c5">
        <span className="c1">
          TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL
          OJIIS, ITS SUBSIDIARIES, OR ITS AFFILIATES BE LIABLE TO YOU FOR ANY
          PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE,
          COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER FORM OF DIRECT OR
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES FROM
          ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO THIS TERM OF
          SERVICES OR THE APPLICATION, WHETHER ARISING IN TORT (INCLUDING
          NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE, WHETHER OR NOT
          OJIIS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE AND WHETHER
          OR NOT REPAIR, REPLACEMENT, OR A REFUND (IF AWARDED AT OUR SOLE
          DISCRETION) FOR THE APPLICATION DOES NOT FULLY COMPENSATE YOU FOR ANY
          LOSSES.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          SOME JURISDICTIONS DO NOT ALLOW A LIMITATION OF LIABILITY FOR DEATH,
          PERSONAL INJURY, FRAUDULENT MISREPRESENTATIONS, OR CERTAIN INTENTIONAL
          OR NEGLIGENT ACTS, OR VIOLATION OF SPECIFIC STATUTES, OR THE
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OR ALL OF
          THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. In no event
          shall Ojiis&rsquo; total liability to You for all damages (except as
          required by applicable law) exceed the amount actually paid by You for
          the Application. THIS LIMITATION APPLIES, BUT IS NOT LIMITED TO
          ANYTHING RELATED TO THE APPLICATION, SERVICES, OR CONTENT MADE
          AVAILABLE THROUGH THE APPLICATION.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">10. INDEMNIFICATION</span>
      </p>
      <p className="c5">
        <span className="c1">
          You agree to defend, indemnify, and hold harmless Ojiis and its
          employees, contractors, officers, and directors from any and all
          claims, suits, damages, costs, lawsuits, fines, penalties,
          liabilities, expenses (including attorney&rsquo;s fees) that arise
          from Your use or misuse of the Application, violation of the Terms of
          Service, or violation of any rights of a third party. Ojiis reserves
          the right to assume the exclusive defence and control of any matter
          otherwise subject to indemnification by You, in which event You will
          cooperate in asserting any available defences.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">11. INJUNCTIVE RELIEF</span>
      </p>
      <p className="c5">
        <span className="c1">
          You agree that a breach of this Terms of Service will cause
          irreparable injury to Ojiis for which monetary damages would not be an
          adequate remedy and Ojiis shall be entitled to seek equitable relief
          in addition to any remedies it may have hereunder or at law without a
          bond, other security, or proof of damages.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">13. GOVERNING LAW</span>
      </p>
      <p className="c5">
        <span className="c1 c9">
          This Agreement and all disputes or controversies arising out of or
          relating to this Agreement shall be governed by, and construed in
          accordance, with the laws of the State of Qu&eacute;bec and Canada,
          excluding its conflict of laws rules.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">14. ARBITRATION</span>
      </p>
      <p className="c5">
        <span className="c1">
          We will make every reasonable effort to resolve any disagreements that
          You have with Ojiis. If those efforts fail, by using the Application
          You agree that any claim, dispute, or controversy You may have against
          Ojiis arising out of, relating to, or connected in any way with this
          Agreement and/or the Application, shall be resolved exclusively by
          final and binding arbitration.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15. FINAL PROVISIONS</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">15.1 ELIGIBILITY</span>
      </p>
      <p className="c5">
        <span className="c1">
          Any person who uses the Application represents to Ojiis that they are
          at least the age of majority under applicable law, or if under the age
          of majority, that they are either an emancipated minor or possess the
          legal consent of a parent or legal guardian, and are fully able and
          competent to enter into the terms, conditions, obligations,
          affirmations, representations, and warranties set forth in this
          Agreement, and to abide by and comply with this Agreement.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.2 SEVERABILITY AND SURVIVAL</span>
      </p>
      <p className="c5">
        <span className="c1">
          If any provision of this Terms of Service is illegal or unenforceable
          under applicable law, the remainder of the provision shall be amended
          to achieve as closely as possible the effect of the original term and
          all other provisions shall continue in full force and effect.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.3 ENTIRE AGREEMENT</span>
      </p>
      <p className="c5">
        <span className="c1">
          This Agreement constitutes the entire agreement between You and Ojiis
          with respect to the Application and supersedes all prior or
          contemporaneous understandings regarding such subject matter. No
          amendment to or modification of this Terms of Service will be binding
          unless made in writing and signed by Ojiis. No failure to exercise,
          and no delay in exercising, on the part of either party, any right or
          any power hereunder preclude further exercise of any other right
          hereunder. In the event of a conflict between this Terms of Service,
          any applicable purchase, or other terms, the terms of this Terms of
          Service shall govern.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.4 LANGUAGE</span>
      </p>
      <p className="c5">
        <span className="c1">
          The original version of this Terms of Service is written in English.
          Any translation of it into any other language is done for local
          requirements and in the event of a dispute, inconsistency, or
          discrepancy between the English and any non-English version, the
          English version of this Terms of Service shall govern, to the extent
          not prohibited by local law in Your jurisdiction.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.5 EXPORT</span>
      </p>
      <p className="c5">
        <span className="c1">
          You agree to abide by U.S. and other applicable export control laws
          and agree not to transfer the Application to a foreign national, or
          national destination, which is prohibited by such laws, without first
          obtaining, and then complying with, any requisite government
          authorization. You certify that You are not a person with whom Ojiis
          is prohibited from transacting business under applicable law.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.6 CHANGES</span>
      </p>
      <p className="c5">
        <span className="c1">
          We will occasionally update this Terms of Service as necessary to
          protect our users, furnish current information, and respond to legal
          and technical changes. The most current version of the Terms of
          Service will govern. Please check this webpage regularly for any
          changes. We reserve the right to change this Terms of Service at any
          time by posting the amended version at the above-mentioned address.
          Your usage of the Application after the changes are integrated will
          constitute Your acceptance of the changes.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c0">15.7 CONTACT INFORMATION</span>
      </p>
      <p className="c5">
        <span>
          Should You wish to contact Ojiis with any questions, complaints, or
          claims with respect to the Application, You should visit the Ojiis
          website at{" "}
        </span>
        <span className="c7">www.ojiis.com</span>
        <span>&nbsp;or contact </span>
        <span className="c7">support@ojiis.com</span>
      </p>
      <p className="c2">
        <span className="c1 c8"></span>
      </p>
      <p className="c5">
        <span className="c0">
          15.8 TRANSFER OF TITLE FOR THE SALE OF AN APPLICATION
        </span>
      </p>
      <p className="c5">
        <span>
          To the extent that applicable legislation allows the parties to a
          transaction to agree on where title passes for sales of property, You
          agree that title for the sale of the Application (software sold by
          electronic download) passes where Ojiis is domiciled.
        </span>
      </p>
    </div>
  );
};

export const PrivacyPolicyFR = () => {
  return (
    <div>
      <p className="c1">
        <span className="c2">
          Ojiis (&quot;nous&quot;, &quot;nous&quot; ou &quot;notre&quot;)
          exploite le site Web et l&#39;application mobile Ojiis (le
          &quot;Service&quot;).
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Cette page vous informe de nos politiques concernant la collecte,
          l&#39;utilisation et la divulgation des donn&eacute;es personnelles
          lorsque vous utilisez notre Service et les choix que vous avez
          associ&eacute;s &agrave; ces donn&eacute;es. Nous utilisons vos
          donn&eacute;es pour fournir et am&eacute;liorer le Service. En
          utilisant le Service, vous acceptez la collecte et l&#39;utilisation
          des informations conform&eacute;ment &agrave; cette politique. Sauf
          indication contraire dans la pr&eacute;sente politique de
          confidentialit&eacute;, les termes utilis&eacute;s dans la
          pr&eacute;sente politique de confidentialit&eacute; ont la m&ecirc;me
          signification que dans nos conditions d&#39;utilisation. En
          t&eacute;l&eacute;chargeant l&#39;application mobile Ojiis, vous
          acceptez ces conditions.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7 c3">Collecte et utilisation des informations</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous recueillons automatiquement plusieurs types d&#39;informations
          diff&eacute;rents &agrave; des fins diverses pour vous fournir et
          am&eacute;liorer notre service.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7 c3">Types de donn&eacute;es collect&eacute;es</span>
      </p>
      <p className="c1 c5">
        <span className="c7 c3"></span>
      </p>
      <p className="c1">
        <span className="c7 c3">Donn&eacute;es personnelles</span>
      </p>
      <p className="c1">
        <span>
          Actuellement, nous ne vous demandons pas de nous fournir des
          informations personnellement identifiables telles que des informations
          pouvant &ecirc;tre utilis&eacute;es pour vous contacter ou vous
          identifier (&quot;Donn&eacute;es personnelles&quot;). Vous pouvez
          choisir de nous fournir des informations personnelles si, par exemple,
          vous contactez notre service client{" "}
        </span>
        <span className="c2 c4">.</span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2 c4">
          Le service vous permet d&#39;enregistrer et de stocker des
          vid&eacute;os &agrave; l&#39;aide de votre appareil vid&eacute;o
          natif. Si vous choisissez d&#39;enregistrer des vid&eacute;os et de
          les conserver, ces vid&eacute;os seront stock&eacute;es localement, ce
          qui signifie qu&#39;elles resteront sur votre appareil. Nous ne
          conservons pas de copie. Lorsque vous supprimez la vid&eacute;o, elle
          est d&eacute;finitivement supprim&eacute;e.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Des donn&eacute;es d&#39;utilisation</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous pouvons collecter des informations lorsque vous acc&eacute;dez
          &agrave; notre Service (&quot;Donn&eacute;es d&#39;utilisation&quot;)
          telles qu&#39;un identifiant unique d&#39;appareil mobile,
          l&#39;adresse IP de votre appareil mobile, votre syst&egrave;me
          d&#39;exploitation mobile, et nous pouvons agr&eacute;ger ces
          donn&eacute;es pour les utiliser dans l&#39;analyse des
          donn&eacute;es. Nous n&#39;utilisons pas ces informations pour suivre
          votre utilisation individuellement ou pour vous envoyer de la
          publicit&eacute;. Par exemple, nous pouvons agr&eacute;ger les
          informations que nous recevons indiquant qu&#39;une
          fonctionnalit&eacute; particuli&egrave;re du Service est largement
          utilis&eacute;e, et nous pouvons utiliser ces informations pour
          am&eacute;liorer l&#39;exp&eacute;rience globale de l&#39;Application.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Utilisation des donn&eacute;es</span>
      </p>
      <p className="c1">
        <span className="c2">
          Ojiis utilise les donn&eacute;es utilisateur collect&eacute;es pour
          maintenir et am&eacute;liorer le service, y compris la
          pr&eacute;vention des probl&egrave;mes techniques et la
          r&eacute;solution des probl&egrave;mes de service client. Il
          n&#39;utilise pas les informations pour vous faire de la
          publicit&eacute;.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Stockage des donn&eacute;es</span>
      </p>
      <p className="c1">
        <span className="c2">
          Toutes les donn&eacute;es sont stock&eacute;es sur des serveurs au
          Canada, et toute information est stock&eacute;e &agrave; des fins
          commerciales l&eacute;gitimes. Les informations relatives &agrave; une
          transaction individuelle, telles qu&#39;un e-mail envoy&eacute; via le
          Service, qui peuvent vous identifier personnellement ne sont
          conserv&eacute;es que tant qu&#39;il existe un int&eacute;r&ecirc;t
          commercial l&eacute;gitime &agrave; le faire, puis peuvent &ecirc;tre
          d&eacute;truites ou hach&eacute;es afin qu&#39;elles ne puissent pas
          vous identifier. . Par exemple, si vous nous envoyez une communication
          contenant votre nom, nous pouvons conserver ces informations aussi
          longtemps que nous en avons besoin pour r&eacute;soudre votre
          probl&egrave;me, et pendant une p&eacute;riode de temps
          l&eacute;galement mandat&eacute;e . Une fois votre probl&egrave;me
          r&eacute;solu, nous pouvons conserver un enregistrement de
          l&rsquo;intervention, mais nous supprimerons toute information
          susceptible de vous identifier personnellement si cela n&#39;est plus
          n&eacute;cessaire. Nous nous r&eacute;servons le droit de conserver
          les donn&eacute;es personnelles ou toute donn&eacute;e
          ind&eacute;finiment si nous pensons raisonnablement que cela est
          n&eacute;cessaire.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c2">
          Ojiis prendra les mesures raisonnablement n&eacute;cessaires pour
          s&#39;assurer que vos donn&eacute;es sont trait&eacute;es en toute
          s&eacute;curit&eacute; et conform&eacute;ment &agrave; la
          pr&eacute;sente politique de confidentialit&eacute; et aucun transfert
          de vos donn&eacute;es personnelles ne sera envoy&eacute; &agrave; une
          organisation ou &agrave; un pays &agrave; moins que des
          contr&ocirc;les ad&eacute;quats ne soient en place, y compris la
          s&eacute;curit&eacute; de votre donn&eacute;es et autres informations
          personnelles.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Divulgation des donn&eacute;es</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous ne vendons ni ne divulguons aucune donn&eacute;e personnelle
          &agrave; des tiers. Ojiis ne collecte pas syst&eacute;matiquement de
          donn&eacute;es permettant d&#39;identifier personnellement qui que ce
          soit. Dans le cas o&ugrave; vous avez fourni des donn&eacute;es
          personnelles, nous pouvons divulguer ces donn&eacute;es personnelles
          en croyant de bonne foi qu&#39;une telle action est
          n&eacute;cessaire&nbsp;:
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <ul className="c10 lst-kix_list_1-0 start">
        <li className="c0 li-bullet-0">
          <span className="c2">
            Pour se conformer &agrave; une obligation l&eacute;gale
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c2">
            Pour prot&eacute;ger et d&eacute;fendre les droits ou la
            propri&eacute;t&eacute; des Ojiis
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c2">
            Pour pr&eacute;venir ou enqu&ecirc;ter sur d&#39;&eacute;ventuels
            actes r&eacute;pr&eacute;hensibles en rapport avec le Service
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c2">
            Pour prot&eacute;ger la s&eacute;curit&eacute; personnelle des
            utilisateurs du Service ou du public
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c2">
            Pour se prot&eacute;ger contre la responsabilit&eacute;
            l&eacute;gale
          </span>
        </li>
      </ul>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3 c7">S&eacute;curit&eacute; des donn&eacute;es</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous ne vendons aucune donn&eacute;e personnelle et si nous
          transf&eacute;rons vos donn&eacute;es &agrave; nos fournisseurs de
          services &agrave; des fins commerciales l&eacute;gitimes. La
          s&eacute;curit&eacute; de toutes nos donn&eacute;es est importante
          pour nous, et nous faisons des efforts commercialement raisonnables
          pour assurer la s&eacute;curit&eacute; du Service. N&#39;oubliez pas
          qu&#39;aucune m&eacute;thode de transmission sur Internet ou
          m&eacute;thode de stockage &eacute;lectronique n&#39;est
          s&eacute;curis&eacute;e &agrave; 100 % et nous ne pouvons garantir sa
          s&eacute;curit&eacute; absolue.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Les fournisseurs de services</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous pouvons faire appel &agrave; des soci&eacute;t&eacute;s tierces
          et &agrave; des particuliers pour faciliter notre service
          (&laquo;&nbsp;fournisseurs de services&nbsp;&raquo;), pour fournir le
          service en notre nom, pour fournir des services li&eacute;s au service
          ou pour nous aider &agrave; analyser la mani&egrave;re dont notre
          service est utilis&eacute;. Ces fournisseurs de services ont
          accept&eacute; des conditions conformes &agrave; la pr&eacute;sente
          politique de confidentialit&eacute; et ne vendent ni n&#39;utilisent
          autrement vos donn&eacute;es personnelles &agrave; des fins de
          marketing. Dans la mesure o&ugrave; ces fournisseurs de services ont
          acc&egrave;s &agrave; vos donn&eacute;es personnelles, c&#39;est
          uniquement pour effectuer une t&acirc;che demand&eacute;e en notre nom
          et ils sont tenus de ne pas les divulguer ou les utiliser &agrave;
          d&#39;autres fins.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7 c3">Suppression de l&#39;Application Mobile</span>
      </p>
      <p className="c1">
        <span className="c2">
          La suppression de l&#39;application mobile supprimera vos Services.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c7 c3">Analytique</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous pouvons utiliser des fournisseurs de services tiers pour
          surveiller et analyser l&#39;utilisation de notre service.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Liens vers d&#39;autres sites</span>
      </p>
      <p className="c1">
        <span className="c2">
          Nous ne partageons pas d&#39;informations avec d&#39;autres
          applications mobiles. Notre service peut toutefois contenir des liens
          vers d&#39;autres sites que nous n&#39;exploitons pas. Si vous cliquez
          sur un lien tiers, vous serez dirig&eacute; vers le site de ce tiers.
          Nous vous conseillons vivement de consulter la politique de
          confidentialit&eacute; de chaque site que vous visitez. Nous
          n&#39;avons aucun contr&ocirc;le et n&#39;assumons aucune
          responsabilit&eacute; quant au contenu, aux politiques de
          confidentialit&eacute; ou aux pratiques des sites ou services tiers.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Confidentialit&eacute; des enfants</span>
      </p>
      <p className="c1">
        <span>
          La protection de la vie priv&eacute;e des mineurs est
          particuli&egrave;rement importante. Pour cette raison, Ojiis ne
          collecte ni ne sollicite sciemment des informations personnelles de
          toute personne de moins de 13 ans et ne permet pas sciemment &agrave;
          ces personnes de s&#39;inscrire aupr&egrave;s de notre service. Si
          vous avez moins de 13 ans, veuillez ne pas nous envoyer
          d&#39;informations vous concernant, y compris votre nom ou votre
          adresse e-mail. Aucune personne de moins de 13 ans n&#39;est
          autoris&eacute;e &agrave; fournir des informations personnelles
          &agrave; ou sur Ojiis. Dans le cas o&ugrave; nous apprenons que nous
          avons collect&eacute; des informations personnelles d&#39;un enfant de
          moins de 13 ans sans v&eacute;rification du consentement parental,
          nous supprimerons ces informations le plus rapidement possible. Si
          vous pensez que nous pourrions avoir des informations provenant de ou
          concernant un enfant de moins de 13 ans, veuillez{" "}
        </span>
        <span className="c9">nous contacter.</span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">
          Modifications de cette politique de confidentialit&eacute;
        </span>
      </p>
      <p className="c1">
        <span className="c2">
          Au fur et &agrave; mesure que nous am&eacute;liorons les
          fonctionnalit&eacute;s de notre site, nous pouvons ajouter des
          fonctionnalit&eacute;s, y compris la modification de nos politiques et
          proc&eacute;dures de confidentialit&eacute;, nous publierons ces
          modifications sur notre site Web pour vous tenir au courant de la
          collecte d&#39;informations, de la mani&egrave;re dont nous les
          utilisons et dans quelles circonstances nous pouvons divulguer il. Les
          modifications apport&eacute;es &agrave; cette politique de
          confidentialit&eacute; entrent en vigueur lorsqu&#39;elles sont
          publi&eacute;es sur cette page.
        </span>
      </p>
      <p className="c1 c5">
        <span className="c2"></span>
      </p>
      <p className="c1">
        <span className="c3">Contactez-nous</span>
      </p>
      <p className="c1">
        <span>
          Si vous avez des questions concernant cette politique de
          confidentialit&eacute;, veuillez{" "}
        </span>
        <span className="c9">nous contacter.</span>
      </p>
      <p className="c1 c5">
        <span className="c11"></span>
      </p>
      <p className="c1 c5">
        <span className="c2 c6"></span>
      </p>
    </div>
  );
};

export const contact_us = {

  support:"Atencion al cliente",
  information:"Información",
  jobs:"Trabajos",
  
  supportTitle:"Envía un ticket",
  informationTitle:"Consultas generales",
  jobsTitle:"Presentar una solicitud de empleo",

  email: "Correo electrónico",
  position:"Posición",
  subject:"Sujeto",
  message:"Mensaje",
  attach:"Adjuntar",
  submit:"Entregar",
  cancel:"Cancelar",
};

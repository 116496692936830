export const faq = {
  section1: {
    question: "Qu'est-ce qu'un Ojii ?",
    answer:
      "Ils sont en 3D, ils bougent, ils parlent, ils sont drôles, ils sont vivants ! Ils sont la nouvelle génération d'emojis.",
  },
  section2: {
    question: "Puis-je partager mes Ojiis ?",
    answer:
      "Oui ! L’application vous propose plusieurs façons d’enregistrer et de partager vos Ojiis et créations, y compris des animations 3D, des GIF et même de pouvoir sélectionner une image spécifique d'une animation pour envoyer un Sticker.",
  },
  section3: {
    question:
      "Comment puis-je contacter le support technique pour obtenir de l'aide ?",
    answer:
      "Pour le support technique, veuillez envoyer un e-mail à support@ojiis.com et notre équipe se fera un plaisir de vous aider.",
  },
  section4: {
    question:
      "Qu'advient-il de mes créations si je désinstalle l'application ?",
    answer:
      "La désinstallation de l'application entraînera la perte de toutes les créations que vous avez faites.",
  },
  section5: {
    question:
      "Qu'advient-il de mes créations qui utilisent des fonctionnalités 'Premium' si je mets fin à mon abonnement ?",
    answer:
      "Si votre abonnement prend fin, les créations utilisant des fonctionnalités Premium deviendront temporairement inaccessibles. Vous pouvez toutefois en retrouver l'accès en vous réabonnant à l’option Premium.",
  },
  section6: {
    question:
      "Que se passe-t-il si je change mon abonnement d'un forfait mensuel à un forfait annuel ?",
    answer:
      "Si vous passez à un forfait annuel, vous recevrez un crédit basé sur le nombre de jours restants de votre abonnement actuel. Votre abonnement annuel commencera automatiquement à la fin de votre forfait mensuel.",
  },
  section7: {
    question: "L'application est-elle gratuite ?",
    answer:
      "L'application est gratuite pour tout le monde mais avec un accès restreint au contenu et aux fonctionnalités. Pour avoir un accès illimité, vous devez souscrire au plan Premium.",
  },
  section8: {
    question: "Que comprend un abonnement Premium ?",
    answer:
      "L'abonnement Premium vous donne un accès illimité au contenu Ojiis et déverrouille toutes les fonctionnalités et options de personnalisation, en plus de recevoir du nouveau contenu à chaque semaine.",
  },
  section9: {
    question: "Comment résilier mon abonnement ?",
    answer:
      "Vous pouvez annuler votre abonnement dans la section des réglages de votre appareil mobile.",
  },
  section10: {
    question: "Comment supprimer mon compte ?",
    answer:
      'Si vous souhaitez supprimer votre compte, veuillez cliquer <a href="mailto:support@ojiis.com">ici</a>.',
  },
  section11: {
    question: "Que dois-je m’attendre de Ojiis dans le futur ?",
    answer:
      "Des nouveaux Ojiis, fonctionnalités et options de personnalisation seront ajoutés à chaque semaine afin de garder votre expérience excitante !",
  },
  section12: {
    question: "Puis-je avoir plusieurs comptes sur le même appareil ?",
    answer: "Cette fonctionnalité n'est présentement pas disponible.",
  },
  section13: {
    question:
      "Puis-je utiliser l'application Ojiis sans avoir une connexion Internet ?",
    answer:
      "Oui, mais vous aurez besoin d'une connexion Internet pour envoyer vos Ojiis ou accéder à votre nouveau contenu.",
  },
  section14: {
    question: "Quels sont les langages disponibles ?",
    answer:
      "Notre application est offerte en anglais, français, espagnol et allemand. Vous pouvez passer d'un langage à l'autre à tout moment en les sélectionnant dans la page Paramètres.",
  },
  section15: {
    question:
      "Puis-je utiliser la barre de recherche pour une autre langue que celle sélectionnée ?",
    answer:
      "La barre de recherche et les mots-clés sont liés seulement à la langue sélectionnée dans l'application.",
  },
  section16: {
    question: "Où sont sauvegardées mes créations ?",
    answer:
      "Toutes vos créations sont sauvegardées dans l’application et sur votre appareil mobile. Cependant, vos photos ou images importées, votre texte personnalisé et votre voix ne sont pas enregistrés dans l'application afin d’en assurer la confidentialité.",
  },
  section17: {
    question: "Comment supprimer une création ?",
    answer:
      "Pour supprimer une création, rendez-vous sur l'onglet Créations (icône avec une étoile) dans la Librairie et sélectionnez la création que vous voulez supprimer. Une fois sélectionné, cliquez sur l'icône Supprimer.",
  },
  section18: {
    question:
      "Que puis-je importer de mon appareil mobile pour la personnalisation de mes Ojiis ?",
    answer:
      "Vous pouvez importer vos propres photos/images et les utiliser pour personnaliser vos créations.",
  },
  section19: {
    question: "Y a-t-il des contrôles parentaux dans l'application ?",
    answer:
      "Oui, vous pouvez activer/désactiver le contrôle parental dans la section Paramètres.",
  },
  section20: {
    question:
      "Qu'advient-il de mes créations si je me déconnecte de l’application ?",
    answer:
      "Tant et aussi longtemps que vous ne désinstallez pas l’application, vos créations ne seront pas perdues et demeureront dans l’application et ce même si vous la quittez. ",
  },
  section21: {
    question: "Comment puis-je obtenir plus d'Ojiis ?",
    answer:
      "De nouveaux Ojiis sont ajoutés à chaque semaine sur l’application. Abonnez-vous au plan Premium pour profiter gratuitement d'un accès illimité en plus de recevoir du nouveau contenu à chaque semaine !",
  },
  section22: {
    question: "Comment puis-je enregistrer un Ojii comme favori ?",
    answer:
      "À partir de la page Accueil, appuyez longuement sur l'Ojii ou bien dans la section Librairie appuyez sur l'icône en forme cœur lorsque votre Ojii apparait en plein écran.",
  },
  section23: {
    question: "Comment puis-je accéder à mes favoris ?",
    answer:
      "Dans les pages Accueil et Librairie, appuyez sur l'icône en forme de cœur, à droite de la barre de recherche.",
  },
  section24: {
    question: "Comment puis-je accéder à mes créations ?",
    answer:
      "À partir de la page Accueil, vous trouverez vos créations en glissant le menu Ojiis vers la droite. Dans la section Librairie, sélectionnez l'onglet Créations marqué d'une icône en forme d’étoile.",
  },
  section25: {
    question: "Comment puis-je voir les nouveaux Ojiis ?",
    answer:
      "À partir de la page Accueil, vous trouverez les dernières nouveautés en glissant le menu Ojiis vers la droite. Dans la section Librairie, sélectionnez l'onglet Nouveau.",
  },
  section26: {
    question: "Comment puis-je supprimer les filigranes ?",
    answer:
      "En vous abonnant au plan Premium, vous aurez l'opportunité de profiter de votre contenu illimité sans filigranes.",
  },
};

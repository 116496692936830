export const ASSETS_DOMAIN =
  "https://d22eehnqnq4xqv.cloudfront.net/website-assets/";

export const ASSETS_PATHS = {
  FONTS: {
    COMFORTAA_VARIABLE_FONT_WGHT: "fonts/Comfortaa-VariableFont_wght.ttf",
    COMFORTAA_BOLD: "fonts/Comfortaa-Bold.ttf",
    COMFORTAA_LIGHT: "fonts/Comfortaa-Light.ttf",
    COMFORTAA_MEDIUM: "fonts/Comfortaa-Medium.ttf",
    COMFORTAA_REGULAR: "fonts/Comfortaa-Regular.ttf",
    COMFORTAA_SEMI_BOLD: "fonts/Comfortaa-SemiBold.ttf",
  },
  APP_STORE_ICONS: {
    APP_STORE:
      "images/appstore_playstore_icons/ojiis_appstore_button_v001_20230404.svg",
    APP_STORE_HOVER: "images/appstore_playstore_icons/hover/AppStore.svg",

    PLAY_STORE:
      "images/appstore_playstore_icons/ojiis_playstore_button_v001_20230404.svg",
    PLAY_STORE_HOVER: "images/appstore_playstore_icons/hover/GooglePlay.svg",
  },
  AR_SECTION: {
    PHONE_MOCKUP_AR:
      "images/ar_section/ojiis_phone_mockup_ar_v001_20230404.svg",
    AR_FULL_MOBILE: "images/ar_section/ojiis_ar_full_mobile_v002_20230501.svg",
    DESKTOP_AR_OUTSIDE: "images/ar_section/ojiis_ar_full_desktop_updated_2.svg",
  },
  BACKGROUND_GRADIENTS: {
    BOTTOM_GRADIENT:
      "images/background_gradients/ojiis_bottom_gradient_v001_20230404.svg",
    TOP_GRADIENT:
      "images/background_gradients/ojiis_top_gradient_v001_20230404.svg",
  },
  CONTACT_US_PAGE: {
    POPUP: {
      ATTACH_ICON:
        "images/contact_us_page/popup/ojiis_attach_icon_v001_20230404.svg",
      CAPTCHA: "images/contact_us_page/popup/ojiis_captcha_v001_20230404.svg",
      MAIN_POPUP:
        "images/contact_us_page/popup/ojiis_main_popup_v001_20230404.svg",
      SUBMIT_CANCEL_BUTTON:
        "images/contact_us_page/popup/ojiis_submit_cancel_button_v001_20230404.svg",
      HOVER: {
        SUBMIT_CANCEL_HOVER_BUTTON:
          "images/contact_us_page/popup/hover/ojiis_submit_cancel_hover_button_v001_20230404.svg",
      },
    },
  },
  FAQ_PAGE: {
    COLLAPSED_ARROW: "images/faq_page/ojiis_collapsed_arrow_v001_20230404.svg",
    DIVIDERLINES: "images/faq_page/ojiis_dividerlines_v001_20230404.svg",
    EXPANDED_ARROW: "images/faq_page/ojiis_expanded_arrow_v001_20230404.svg",
    SEEMORE_BUTTON_FILLED_HOVER:
      "images/faq_page/ojiis_seemore_button_filled_hover_v001_20230404.svg",
    SEEMORE_BUTTON: "images/faq_page/ojiis_seemore_button_v001_20230404.svg",
  },

  FOOTER: {
    BACK_TO_TOP: {
      ARROW:
        "images/footer/back_to_top/ojiis_backtotop_arrow_v001_20230404.svg",
      HOVER: {
        ARROW_HOVER:
          "images/footer/back_to_top/hover/ojiis_backtotop_arrow_hover_v001_20230404.svg",
      },
    },
    LANGUAGE_ICONS: {
      ENGLISH:
        "images/footer/language_icons/ojiis_language_english_v001_20230404.svg",
      FRENCH:
        "images/footer/language_icons/ojiis_language_french_v001_20230404.svg",
      GERMAN:
        "images/footer/language_icons/ojiis_language_german_v001_20230404.svg",
      SPANISH:
        "images/footer/language_icons/ojiis_language_spanish_v001_20230404.svg",
      HOVER: {
        ENGLISH_HOVER:
          "images/footer/language_icons/hover/ojiis_language_english_hover_v001_20230404.svg        ",
        FRENCH_HOVER:
          "images/footer/language_icons/hover/ojiis_language_french_hover_v001_20230404.svg",
        GERMAN_HOVER:
          "images/footer/language_icons/hover/ojiis_language_german_hover_v001_20230404.svg",
        SPANISH_HOVER:
          "images/footer/language_icons/hover/ojiis_language_spanish_hover_v001_20230404.svg",
      },
    },
    LOGO: {
      WHITE_FOOTER:
        "images/footer/logo/ojiis_logo_white_footer_v001_20230404.svg",
      HOVER: {
        WHITE_HOVER_FOOTER:
          "images/footer/logo/hover/ojiis_logo_white_hover_footer_v001_20230404.svg",
      },
    },
    SOCIAL_ICONS: {
      FACEBOOK_ICON:
        "images/footer/social_icons/ojiis_facebook_icon_v001_20230404.svg",
      INSTAGRAM_ICON:
        "images/footer/social_icons/ojiis_instagram_icon_v001_20230404.svg",
      SNAPCHAT_ICON:
        "images/footer/social_icons/ojiis_snapchat_icon_v001_20230404.svg",
      TIKTOK_ICON:
        "images/footer/social_icons/ojiis_tiktok_icon_v001_20230404.svg",
      YOUTUBE_ICON:
        "images/footer/social_icons/ojiis_youtube_icon_v001_20230404.svg",
      HOVER: {
        FACEBOOK_ICON_HOVER:
          "images/footer/social_icons/hover/ojiis_facebook_icon_hover_v001_20230404.svg",
        INSTAGRAM_ICON_HOVER:
          "images/footer/social_icons/hover/ojiis_instagram_icon_hover_v001_20230404.svg",
        SNAPCHAT_ICON_HOVER:
          "images/footer/social_icons/hover/ojiis_snapchat_icon_hover_v001_20230404.svg",
        TIKTOK_ICON_HOVER:
          "images/footer/social_icons/hover/ojiis_tiktok_icon_hover_v001_20230404.svg",
        YOUTUBE_ICON_HOVER:
          "images/footer/social_icons/hover/ojiis_youtube_icon_hover_v001_20230404.svg",
      },
    },
  },
  LANDING_PAGE_LINES: {
    HORIZONTAL_LINE:
      "images/landing_page_lines/ojiis_horizontal_line_v002_20230501.svg",
    ZIGZAG_LINES_TOP:
      "images/landing_page_lines/ojiis_zigzag_line_top_opacity_v001_20230512-01.svg",
    ZIGZAG_LINES_BOTTOM:
      "images/landing_page_lines/ojiis_zigzag_line_bottom_opacity_v001_20230512-01.svg",
  },
  LOGOS: {
    SMALL_LOGO_ALL_PAGES:
      "images/logos/ojiis_small_logo_all_pages_v001_20230404.svg",
    SMALL_LOGO_HOVER_ALL_PAGES:
      "images/logos/ojiis_small_logo_hover_all_pages_v001_20230404.svg",
    LANDING_PAGE_MAIN_LOGO:
      "images/logos/landing_page_logo/ojiis_landing_page_main_logo_v001_20230404.svg",
  },
  OJIIS_IMAGES: {
    CAT: "images/ojiis_images/ojiis_cat_v001_20230404.svg",
    FIREWORKS: "images/ojiis_images/ojiis_fireworks_v002_2023_0501.svg",
    MINDBLOWN: "images/ojiis_images/ojiis_mindblown_v001_20230404.svg",
    PAINTER: "images/ojiis_images/ojiis_painter_v001_20230404.svg",
    PICTURE: "images/ojiis_images/ojiis_picture_v001_20230404.svg",
    SELFIE: "images/ojiis_images/ojiis_selfie_v001_20230404.svg",
    TILES: "images/ojiis_images/ojiis_tiles_v001_20230404.svg",
    VOICE: "images/ojiis_images/ojiis_voice_v001_20230404.svg",
  },
  SCENES_SECTION: {
    SCENES_BACKGROUND:
      "images/scenes_section/ojiis_scenes_background_v002_20230501.svg",
    THIRSTY: "images/scenes_section/ojiis_thirsty_v001_20230404.svg",
    SCENES_FULL_MOBILE:
      "images/scenes_section/ojiis_scenes_full_mobile_v002_20230501.svg",
  },
  SOCIAL_MEDIA_ICONS_TOP_LANDING_PAGE: {
    FACEBOOK_ICON_TOP_LANDINGPAGE:
      "images/social_media_icons_top_landingpage/ojiis_facebook_icon_top_landingpage_v001_20230404.svg",
    INSTAGRAM_ICON_TOP_LANDINGPAGE:
      "images/social_media_icons_top_landingpage/ojiis_instagram_icon_top_landingpage_v001_20230404.svg",
    SNAPCHAT_ICON_TOP_LANDINGPAGE:
      "images/social_media_icons_top_landingpage/ojiis_snapchat_icon_top_landingpage_v001_20230404.svg",
    TIKTOK_ICON_TOP_LANDINGPAGEV001:
      "images/social_media_icons_top_landingpage/ojiis_tiktok_icon_top_landingpagev001_20230404.svg",
    YOUTUBE_LOGO_TOP_LANDINGPAGE:
      "images/social_media_icons_top_landingpage/ojiis_youtube_logo_top_landingpage_v001_20230404.svg",
  },

  VIDEO_THUMBNAILS: {
    PLAY_BUTTON: "images/video_thumbnails/ojiis_play_button_v001_20230505.svg",
    VIDEO_THUMBNAIL_CAROUSEL:
      "images/video_thumbnails/ojiis_video_thumbnail_carousel_v001_20230404.svg",
    VIDEO_THUMBNAIL_MAIN:
      "images/video_thumbnails/ojiis_video_thumbnail_main_v001_20230404.svg",
  },
  STATIC_BACKGROUND: {
    FULL: "images/static_background_ojii/ojiis_static_background_ojii_v001_20230404.svg",
    DESKTOP:
      "images/static_background_ojii/ojiis_static_background_ojii_v001_20230404.svg",
    MOBILE: "images/static_background_ojii/ojiis_background_static_v002_20230526.svg",
  },
  VIDEO_1: {
    Portrait:
      "images/media_gallery/thumbnails_portrait/ojiis_vid_1_thumb_portrait_v001.png",
    Landscape:
      "images/media_gallery/thumbnails_landscape/ojiis_vid_1_thumb_landscape_v001.png",
  },
  VIDEO_2: {
    Portrait:
      "images/media_gallery/thumbnails_portrait/ojiis_vid_2_thumb_portrait_v001.png",
    Landscape:
      "images/media_gallery/thumbnails_landscape/ojiis_vid_2_thumb_landscape_v001.png",
  },
  VIDEO_3: {
    Portrait:
      "images/media_gallery/thumbnails_portrait/ojiis_vid_3_thumb_portrait_v001.png",
    Landscape:
      "images/media_gallery/thumbnails_landscape/ojiis_vid_3_thumb_landscape_v001.png",
  },
};

import { ASSETS_DOMAIN, ASSETS_PATHS } from "../../ojiis-assets";
import SocialMedia from "../SocialMedia/SocialMedia";
import { FaArrowUp } from "react-icons/fa";
import "./Footer.scss";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { translations } from "../../translations/texts";
import { isOnMobile } from "../../utilities";
import { Languages } from "./Languages/Languages";
import { LANG_ABREVIATION } from "../../constants/language";
import { useContext } from "react";
import { CombinedContext } from "../context/combinedContext";


const Footer = () => {

  const { language, setLanguage} = useContext(CombinedContext)

  const navigate = useNavigate();

  const backToTop = () => {
    window.scroll(0, 0);
  };

  const goToHome = () => {
    navigate(ROUTES.HOME);
  };

  const getFooterFontSize=()=>{
    switch(language.toUpperCase()){
      case LANG_ABREVIATION.EN: {
        return "1.6vw"
      }
      case LANG_ABREVIATION.FR:{
        return "1vw"
      }
      case LANG_ABREVIATION.SP:{
        return "1vw"
      }
      case LANG_ABREVIATION.DE:{
        return "1vw"
      }
    }
  }

  return (
    <footer>
      <div className="logo">
        <img
          alt=""
          onClick={goToHome}
          src={`${ASSETS_DOMAIN}${ASSETS_PATHS.FOOTER.LOGO.WHITE_FOOTER}`}
        />
        <p>
          © {translations[language].footer.copyright}{" "}
          {new Date().getFullYear()}
        </p>
      </div>
      <div className="content">
        <div className="content-line-1">
          <div className="app-icons">
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                (window.location.href =
                  "https://apps.apple.com/ca/app/ojiis/id1491394934?platform=iphone")
              }
              alt=""
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.APP_STORE_ICONS.APP_STORE}`}
            />
            <img
              alt=""
              style={{ cursor: "pointer" }}
              className="play-store"
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=com.bmad.OjiisApp")
              }
              src={`${ASSETS_DOMAIN}${ASSETS_PATHS.APP_STORE_ICONS.PLAY_STORE}`}
            />
          </div>
          <SocialMedia />
          {!isOnMobile() ? <Languages /> : ""}
        </div>
        <div className="content-line-2">
          <div className="links" style={{fontSize:getFooterFontSize()}}>
            <Link onClick={backToTop} to={ROUTES.FAQ}>
              {translations[language].footer.faq}
            </Link>{" "}
            <span>|</span>{" "}
            <Link onClick={backToTop} to={ROUTES.CONTACT_US}>
              {translations[language].footer.contact_us}
            </Link>{" "}
            <span>|</span>{" "}
            <Link onClick={backToTop} to={ROUTES.TOS}>
              {translations[language].footer.tos}
            </Link>{" "}
            <span>|</span>{" "}
            <Link onClick={backToTop} to={ROUTES.VIDEO_POLICY}>
              {translations[language].footer.video_policy}
            </Link>{" "}
            <span>|</span>{" "}
            <Link onClick={backToTop} to={ROUTES.PRIVACY_POLICY}>
              {translations[language].footer.privacy_policy}
            </Link>
          </div>
          {isOnMobile() ? <Languages  setLanguage={setLanguage}/> : ""}
          <div className="back-to-top">
            <p onClick={backToTop}>
              <FaArrowUp className="fa-arrow-up" />
              {translations[language].footer.back_to_top}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
